export const delay = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const roundToNearestHundred = (num) => {
  return Math.ceil(num / 100) * 100;
}

export const roundToNearestTen = (num) => {
  return Math.ceil(num / 10) * 10;
};

export const formatCurrency = (value) => {
  return `₦${ parseFloat(value).toLocaleString() }`
};

export const namesMatch = (a, b) => {
  if (typeof a !== "string" || typeof b !== "string") return false;
  const count = a.toLowerCase().replace(/[-_]/gi, ' ').split(' ').reduce((acc, curr) => {
    const match = b.toLowerCase().replace(/[-_]/gi, ' ').split(' ').includes(curr);
    if (match) return acc + 1;
    return acc;
  }, 0);
  return count > 0;
}

export const decodeToken = (token) => {
  const str = token.split('.').length > 1 ? token.split('.')[1] : token;
  try {
    return JSON.parse(decodeURIComponent(atob(str)));
  } catch (e) {
    return JSON.parse(atob(str));
  }
}
