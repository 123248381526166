<template>
  <div class="h-full flex flex-col">
    <div class="px-6 lg:px-12 pb-8">
      <template v-if="view === 'accounts'">
        <SectionHeader
          :title="`Add at least ${ count } active bank accounts to continue`"
          subtitle="We only support customers in any of the banks below, click on a bank to add"
        />
        <div class="grid grid-cols-3 md:grid-cols-4 gap-4">
          <div
            v-for="b in banks" :key="b.bank_id" @click="handleClick(b)"
            class="border rounded-lg px-4 py-6 relative overflow-hidden"
            :class="{ 'cursor-pointer hover:bg-gray-100': !b.added }"
          >
            <img :src="b.logo" :alt="b.name" class="h-20 object-contain"/>
            <div v-if="b.added" class="absolute inset-0 bg-white bg-opacity-60 flex items-center justify-center">
              <i class="fa fa-check text-green-600 text-4xl"></i>
            </div>
          </div>
        </div>
        <div v-if="accounts.length >= count" class="flex items-center space-x-3">
          <AppButton @click="next()" class="mt-8">Continue</AppButton>
        </div>
      </template>
      <template v-if="view === 'add'">
        <SectionHeader title="Provide account details below"/>
        <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
        <form>
          <img :src="selected.logo" alt="bank" class="w-40 object-contain mt-16 mb-16"/>
          <ValidationObserver ref="observer">
            <div class="flex flex-col space-y-4">
              <ValidationProvider name="account_number" rules="required" v-slot="{ errors }">
                <AppInput
                  v-model="values.account_number" type="number" placeholder="Account number" :errors="errors"
                  class="w-full" :disabled="loading || !!values.account_name"
                />
              </ValidationProvider>
            </div>
            <Message v-if="values.account_name" variant="success" class="mt-6">
              Account name: {{ values.account_name }}
            </Message>
            <div class="flex mt-10 space-x-3">
              <template v-if="!values.account_name" class="mt-8">
                <AppButton @click="view = 'accounts'" variant="light" class="py-3">
                  <i class="fa fa-chevron-left"></i>
                </AppButton>
                <AppButton @click="submit()" :loading="loading">Validate account</AppButton>
              </template>
              <template v-else>
                <AppButton @click="addAccount()" :loading="loading">Add account</AppButton>
                <button @click="change()" class="py-1 px-4 ml-2" :disabled="loading">Change</button>
              </template>
            </div>
          </ValidationObserver>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppInput from "@/components/global/Input.vue";
import AppButton from "@/components/global/Button.vue";
import Message from "@/components/global/Message.vue";
import { namesMatch } from "@/lib/utils.js";
import banks from "@/lib/banks.js";

export default {
  name: "MultipleAccounts",
  components: { Message, AppButton, AppInput, SectionHeader },
  data() {
    return {
      values: {
        account_number: '',
        account_name: '',
        bank_id: '',
        bank_code: '',
        bank_name: '',
      },
      loading: false,
      error: '',
      view: 'accounts',
      selected: null,
      count: 2
    }
  },
  created() {
    const { config: { show_multiple_accounts }, stage } = this.$store.state.eligibility;
    if (!show_multiple_accounts) return this.$emit('skip');
    const { recently, twoWeeks, oneMonth } = this.$store.getters['eligibility/lastApplied'];
    if (recently || twoWeeks || oneMonth) return this.$emit('skip');
    if (stage !== 'accounts') this.$store.dispatch('eligibility/updateStage', { stage: 'accounts' });
  },
  methods: {
    async submit() {
      this.error = '';
      this.values.account_name = null;
      if (!(await this.$refs.observer.validate())) return;
      this.loading = true;
      await this.validateAccount();
      this.loading = false;
    },
    setError(error) {
      this.loading = false;
      this.error = error;
    },
    handleClick(bank) {
      this.selected = bank;
      this.view = 'add';
    },
    async validateAccount() {
      if (this.accounts.some(a => a.account_number === this.values.account_number)) {
        return this.setError('Account has already been added')
      }
      try {
        const payload = {
          bank_code: this.selected.bank_code,
          account_number: this.values.account_number,
        };
        const { data } = await this.$http.post('https://mobile.creditclan.com/webapi/v1/account/resolve', payload);
        if (data.status !== "success") return this.setError('Could not validate account, Check your details and try again');
        const { account_name } = data.data;
        const { profile: { full_name }, config: { account_name_match } } = this.$store.state.eligibility;
        if (account_name_match && !namesMatch(full_name, account_name)) {
          return this.setError('Sorry, this account belongs to someone else');
        }
        this.values.bank_id = this.selected.bank_id;
        this.values.bank_name = this.selected.name;
        this.values.account_name = account_name;
      } catch (error) {
        console.log({ error });
      }
    },
    async addAccount() {
      const saved = await this.saveAccount();
      if (saved) {
        this.$store.commit('eligibility/patch', {
          accounts: [...this.$store.state.eligibility.accounts, this.values]
        });
        this.view = 'accounts';
        this.reset();
      }
    },
    async saveAccount() {
      this.loading = true;
      try {
        const { token } = this.$store.state.eligibility;
        const { account_number, bank_id, account_name, bank_name, bank_code } = this.values;
        await this.$http.post('https://mobile.creditclan.com/api/v3/user/account/save', {
          token, account_number, bank_id, account_name, bank_name, bank_code
        });
        this.loading = false;
        return true;
      } catch (e) {
        this.setError('Something went wrong while trying to save your account, please try again');
        return false;
      }
    },
    change() {
      this.view = 'add';
      this.reset();
    },
    reset() {
      this.values = {
        account_number: '',
        account_name: '',
        bank_id: '',
        bank_code: '',
        bank_name: '',
      };
    },
    next() {
      if (this.accounts.length < this.count) return;
      this.$emit('done');
    },
    async getBanks() {
      if (this.banks) return;
      const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
      this.banks = res.data.data;
    },
    validated() {
      return !!this.values.account_name;
    },
  },
  computed: {
    accounts() {
      return this.$store.state.eligibility.accounts;
    },
    account() {
      return this.$store.state.eligibility.account;
    },
    banks() {
      return [
        { bank_id: '29', logo: '/assets/images/logos/zenith-bank.svg', },
        { bank_id: '21', logo: '/assets/images/logos/gt-bank.svg', },
        { bank_id: '17', logo: '/assets/images/logos/ecobank.svg', },
        { bank_id: '20', logo: '/assets/images/logos/first-bank.svg', },
        { bank_id: '27', logo: '/assets/images/logos/uba.svg', },
        { bank_id: '4', logo: '/assets/images/logos/sterling.svg', },
        { bank_id: '1', logo: '/assets/images/logos/fcmb.svg', },
        { bank_id: '14', logo: '/assets/images/logos/access-bank.svg', },
        { bank_id: '46', logo: '/assets/images/logos/polaris.svg', },
        { bank_id: '23', logo: '/assets/images/logos/keystone.svg', },
        { bank_id: '19', logo: '/assets/images/logos/fidelity.svg', },
        { bank_id: '3', logo: '/assets/images/logos/stanbic.svg', },
        { bank_id: '31', logo: '/assets/images/logos/providus.png', },
        { bank_id: '25', logo: '/assets/images/logos/standard-chattered.png', },
        { bank_id: '26', logo: '/assets/images/logos/union.png', },
        { bank_id: '28', logo: '/assets/images/logos/wema.svg', },
        { bank_id: '22', logo: '/assets/images/logos/heritage.png', },
        { bank_id: '35', logo: '/assets/images/logos/unity.jpg', },
        { bank_id: '34', logo: '/assets/images/logos/suntrust.png', },
        { bank_id: '32', logo: '/assets/images/logos/citibank.png', },
      ].map(b => {
        const _b = banks.find(__b => __b.id === b.bank_id);
        return ({
          ...b,
          name: _b.name,
          bank_code: _b.bank_code,
          added: this.accounts.some(_b => _b.bank_id === b.bank_id),
        });
      });
    }
  }
}
</script>
