<template>
  <div class="my-auto">
    <template v-if="offers.length">
      <SectionHeader
        title="Choose plan"
        subtitle="Based on your cash-flow analysis, you are only eligible for the following plans, tap on one to select"
      />
      <swiper ref="mySwiper" :options="swiperOptions" class="w-full">
        <template v-for="(plan, i) in offers">
          <swiper-slide :key="i">
            <div
              @click="select(plan)" style="min-height: 300px"
              class="bg-gray-800 text-white rounded-lg p-6 cursor-pointer flex flex-col border shadow-md h-full relative"
            >
              <p class="text-orange-400 mb-1 text-sm font-semibold uppercase">{{ plan.package }}</p>
              <div class="text-2xl font-semibold">
                {{ plan.plan_amount | currency }} <small class="opacity-50 text-md">per month</small>
              </div>
              <p class="">{{ plan.plan }}</p>
              <i class="fa fa-car absolute -bottom-10 right-0 opacity-50 text-9xl"></i>
            </div>
          </swiper-slide>
        </template>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </template>
    <template v-else>
      <div class="text-center max-w-xs mx-auto flex flex-col items-center justify-center my-auto">
        <i class="fa fa-frown fa-5x text-gray-300"></i>
        <p class="mt-10 text-gray-600 font-semibold text-lg">We could not get any offer</p>
        <p class="text-gray-500 mt-3">
          Sorry, our partners believe your data is insufficient to offer you credit
        </p>
      </div>
    </template>

    <Modal v-if="modal === 'summary' && selected">
      <div class="my-auto">
        <SectionHeader
          :title="formatCurrency(selected.plan_amount)"
          :subtitle="selected.package"
        />
        <p class="-mt-4">Includes:</p>
        <ul class="border border-gray-300 divide-y divide-gray-300 rounded-lg px-3 text-sm mt-4">
          <li class="py-1">Enjoy {{ selected.plan_amount | currency }} worth of rides monthly</li>
          <li class="py-1">You can pay now or pay later.</li>
          <li class="py-1">Pay later comes with a {{ selected.monthly_subscription_fee }} monthly fee</li>
        </ul>
        <div class="flex mt-8">
          <AppButton @click="accept()" :loading="accepting">Accept</AppButton>
          <button @click="selected = null; modal = '';" class="ml-2 text-red-500 py-1 px-3">Cancel</button>
        </div>
      </div>
      <div class="flex justify-end mt-auto md:hidden">
        <img src="@/assets/images/approve.svg" alt="" class="w-56"/>
      </div>
    </Modal>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppButton from "@/components/global/Button.vue";
import Modal from "@/components/global/Modal.vue";
import { formatCurrency } from "@/lib/utils.js";

const plans = [
  {
    id: 1,
    plan_amount: 20000,
    monthly_subscription_fee: 1000,
    discount: 2.5,
    plan: '₦20k worth of rides in a month',
    package: "Standard plan",
  },
  {
    id: 2,
    plan_amount: 30000,
    monthly_subscription_fee: 1000,
    discount: 2.5,
    plan: '₦30k worth of rides in a month',
    package: "Classic plan",
  },
  {
    id: 3,
    plan_amount: 50000,
    monthly_subscription_fee: 2000,
    discount: 5,
    plan: '₦50k worth of rides in a month',
    package: "Premium plan",
  },
  {
    id: 4,
    plan_amount: 75000,
    monthly_subscription_fee: 3000,
    discount: 5,
    plan: '₦75k worth of rides in a month',
    package: "Deluxe plan",
  },
  {
    id: 5,
    plan_amount: 100000,
    monthly_subscription_fee: 5000,
    discount: 5,
    plan: '₦100k worth of rides in a month',
    package: "Executive plan",
  },
];

export default {
  name: "RideOffers",
  components: { Modal, AppButton, SectionHeader },
  props: {
    offer: {
      type: Object,
      required: true
    },
    accepting: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      offers: [],
      swiperOptions: {
        slidesPerView: 1.2,
        spaceBetween: 20,
      },
      selected: null,
      modal: '',
      formatCurrency,
    }
  },
  created() {
    this.generateOffers();
  },
  methods: {
    generateOffers() {
      this.offers = plans.filter(p => p.plan_amount <= this.offer.effective_income);
    },
    select(plan) {
      this.selected = plan;
      this.modal = 'summary';
    },
    accept() {
      const offer = {
        loan_amount: this.selected.plan_amount,
        repayment_amount: this.selected.plan_amount,
        duration: 1,
        monthly_repayment: this.selected.plan_amount,
        upfront: 0,
        tenor_type: 2,
        plan: this.selected,
      };
      this.$emit('accept', offer);
    }
  }
}
</script>
