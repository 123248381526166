<template>
  <div class="my-auto px-6 lg:px-12">
    <template v-if="offers.length">
      <p class="mb-6 text-lg text-gray-700">Select an offer below</p>
      <swiper ref="mySwiper" :options="swiperOptions">
        <template v-for="(offer, i) in offers">
          <swiper-slide :key="i">
            <div @click="selectOffer(offer)"
              class="bg-gray-50 shadow-md border rounded-lg p-6 cursor-pointer h-80 flex flex-col">
              <p class="text-gray-600">Pay</p>
              <div
                class="text-xl font-semibold">{{ offer.monthly_repayment | currency }}
                <small class="text-gray-600">monthly</small>
              </div>
              <div v-if="offer.duration === 1" class="text-gray-700">
                and {{ offer.monthly_repayment | currency }} next month
              </div>
              <div v-else class="text-gray-700">
                plus {{ mgt_fee | currency }} Management fee & <br>
                and {{ offer.upfront | currency }} security deposit
              </div>
              <div class="flex justify-end items-center mt-auto text-gray-600">
                <i class="fa fa-user mr-2"></i> {{ lender? lender.name : offer.lender }}
              </div>
            </div>
          </swiper-slide>
        </template>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <Modal v-if="selectedOffer">
        <div class="my-auto">
          <SectionHeader
            title="Confirm offer"
            :subtitle="`I agree that I will pay security deposit of ${formatCurrency(selectedOffer.upfront)} now and pay ${formatCurrency(selectedOffer.monthly_repayment)} monthly`"
          />
          <ul class="border border-gray-300 divide-y divide-gray-300 rounded-lg px-3 text-sm mt-4">
            <li class="py-2">Monthly repayment: {{ selectedOffer.monthly_repayment | currency }}</li>
            <li class="py-2">Management fee: {{ mgt_fee | currency }}</li>
            <li class="py-2">Security deposit: {{ selectedOffer.upfront | currency }}</li>
          </ul>
          <div class="flex mt-10">
            <AppButton @click="$emit('accept', selectedOffer)" :loading="accepting">Accept</AppButton>
            <button @click="selectedOffer = null" class="ml-2 text-red-500 py-1 px-3">Cancel</button>
          </div>
        </div>
      </Modal>
    </template>
    <template v-else>
      <div class="text-center max-w-xs mx-auto flex flex-col items-center justify-center my-auto">
        <i class="fa fa-frown fa-5x text-gray-300"></i>
        <p class="mt-10 text-gray-600 font-semibold text-lg">We could not get any offer</p>
        <p class="text-gray-500 mt-3">
          Sorry, our partners believe your data is insufficient to offer you credit. Kindly contact our support on
          <a href="tel:01 229 7897">01 229 7897</a> if you have any other issues
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import Modal from "@/components/global/Modal.vue";
import AppButton from "@/components/global/Button.vue";
import { formatCurrency, roundToNearestTen } from "@/lib/utils.js";
import SectionHeader from "@/components/global/SectionHeader.vue";

export default {
  name: "RentOffers",
  components: { SectionHeader, AppButton, Modal },
  props: {
    offer: {
      type: Object,
      required: true
    },
    accepting: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      offers: [],
      selectedOffer: null,
      swiperOptions: {
        slidesPerView: 1.5,
        spaceBetween: 30,
      },
      frequencies: [
        { id: 2, text: 'month(s)', adverb: 'Monthly' },
        { id: 4, text: 'week(s)', adverb: 'Weekly' },
      ],
      payment: { amount: 0, upfront: false },
      formatCurrency,
      lender: this.$store.state.eligibility.lender,
      mgt_fee: 5 / 100 * this.$store.state.eligibility.request.amount,
      cancelling: false,
    }
  },
  async created() {
    this.offers = this.generateOffers(this.offer);
  },
  methods: {
    generateOffers(offer) {
      let plans = [];
      const { duration, upfront, total_repayment, amount } = offer;
      if (!total_repayment) return [];
      const monthly_repayment = roundToNearestTen(amount / duration);
      plans.push({
        loan_amount: offer.amount,
        repayment_amount: monthly_repayment * duration,
        duration,
        monthly_repayment,
        upfront: roundToNearestTen(upfront),
        tenor_type: 2
      });
      return plans.map((plan, i) => ({ id: i, ...plan }));
    },
    selectOffer(offer) {
      this.selectedOffer = { ...offer };
      this.changeTenorType(offer.tenor_type);
    },
    changeTenorType(v) {
      this.selectedOffer.tenor_type = v;
      const offer = this.offers.find(offer => offer.id === this.selectedOffer.id);
      if (v === 4) {
        this.selectedOffer.duration = offer.duration * 4
        this.selectedOffer.monthly_repayment = roundToNearestTen(offer.monthly_repayment / 4)
      }
      if (v === 1) {
        this.selectedOffer.duration = offer.duration * 30
        this.selectedOffer.monthly_repayment = roundToNearestTen(offer.monthly_repayment / 30)
      }
      if (v === 2) {
        this.selectedOffer.duration = offer.duration
        this.selectedOffer.monthly_repayment = offer.monthly_repayment
      }
    },
    async handleClose() {
      this.cancelling = true;
      await this.$store.dispatch('eligibility/cancelRequest');
      parent.postMessage({ id: 'cclan-close' }, '*');
      this.cancelling = false;
    },
  },
  computed: {
    tenor_type() {
      return this.frequencies.find(f => this.selectedOffer.tenor_type === f.id) || {}
    }
  }
}
</script>
