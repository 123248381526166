<template>
  <div class="h-full flex flex-col">
    <Loader v-if="loading" :text="loading" class="my-auto px-6 lg:px-12 pb-8"/>
    <div v-else class="px-6 lg:px-12 pb-8">
      <SectionHeader title="Provide your Bank Verification Number (BVN)"/>
      <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
      <ValidationObserver ref="observer">
        <div class="flex flex-col space-y-4">
          <ValidationProvider name="bvn" rules="required|length:11" v-slot="{ errors }">
            <AppInput
              label="BVN number"
              v-model="values.bvn" type="number" placeholder="BVN" :errors="errors" class="w-full"
              :disabled="!!loading"
            />
            <small class="text-gray-500 mt-2">Dial *565*0# to get your BVN number</small>
          </ValidationProvider>
          <div>
            <label class="flex opacity-80 mb-3">Date of birth</label>
            <div class="grid grid-cols-3 gap-4">
              <ValidationProvider name="Day" rules="required" v-slot="{ errors }">
                <AppInput
                  v-model="values.date" type="number" :errors="errors" class="w-full" :disabled="!!loading"
                  label="Day"
                />
              </ValidationProvider>
              <ValidationProvider name="Month" rules="required" v-slot="{ errors }">
                <AppSelect
                  v-model="values.month" type="text" :errors="errors" class="w-full" :disabled="!!loading"
                  label="Month"
                >
                  <option
                    v-for="(m, i) in ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dev']"
                    :key="m" :value="`${i}`"
                  >
                    {{ m }}
                  </option>
                </AppSelect>
              </ValidationProvider>
              <ValidationProvider name="Year" rules="required" v-slot="{ errors }">
                <AppInput
                  v-model="values.year" type="number" :errors="errors" class="w-full" :disabled="!!loading"
                  label="Year"
                />
              </ValidationProvider>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <div class="text-sm text-gray-500 mt-3">Must match date of birth registered with BVN</div>
      <AppButton @click="handleSubmit()" class="mt-10" :loading="!!loading">Continue</AppButton>
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/global/Input.vue";
import SectionHeader from "@/components/global/SectionHeader.vue";
import Message from "@/components/global/Message.vue";
import ajax from "@/mixins/ajax.js";
import AppButton from "@/components/global/Button.vue";
import { format } from "date-fns";
import { namesMatch } from "@/lib/utils.js";
import Loader from "@/components/global/Loader.vue";
import AppSelect from "@/components/global/Select.vue";

export default {
  name: "BVN",
  components: { AppSelect, Loader, AppButton, Message, SectionHeader, AppInput },
  mixins: [ajax],
  data() {
    return {
      values: {
        bvn: '',
        date: '',
        month: '',
        year: '',
      },
    }
  },
  created() {
    const { bvn, profile } = this.$store.state.eligibility;
    if (bvn) return this.$emit('skip');
    this.values.bvn = bvn;
    if (profile.date_of_birth) {
      const dob = new Date(profile.date_of_birth);
      this.values.date = dob.getDate().toString();
      this.values.month = dob.getMonth().toString();
      this.values.year = dob.getFullYear().toString();
    }
  },
  methods: {
    async handleSubmit() {
      if (!(await this.$refs.observer.validate())) return;
      this.reset();
      if (this.values.bvn.length !== 11) return this.setError('Please enter a valid BVN number');
      if (!this.$store.state.eligibility.config.validate_bvn) return this.next({});
      this.setLoading('Validating your BVN');
      try {
        const res = await this.$http.post('https://lendnode.creditclan.com/myidentitypass/api/verify/bvn ', {
          number: this.values.bvn
        });
        if (!res.data.status) {
          return this.setError(res.data.message || 'We could not verify your BVN number, please try again', true)
        }
        const { year, month, date } = this.values;
        const userDob = new Date(year, month, date);
        const { dateOfBirth, firstName, middleName, lastName } = res.data.bvn_data;
        const bvnDob = new Date(dateOfBirth);
        if (format(bvnDob, 'dd-MM-yyyy') !== format(userDob, 'dd-MM-yyyy')) {
          return this.setError('Sorry, we cannot proceed with this bvn. The date of birth found in this bvn is different from the date of birth provided for this application', true);
        }
        const { profile, config: { bvn_name_match } } = this.$store.state.eligibility;
        if (!bvn_name_match) return this.next(res.data.bvn_data);
        if (!namesMatch(profile.full_name, `${ firstName } ${ middleName } ${ lastName }`)) {
          return this.setError('Sorry, we cannot proceed with this bvn. The name found in this bvn is different from the name provided for this application', true);
        }
        return this.next(res.data.bvn_data);
      } catch (e) {
        this.setError(e.response ? e.response.data.message : e.message || 'We could not verify your BVN number, please try again', true)
      }
      this.setLoading('');
    },
    next(bvn_data) {
      const { year, month, date } = this.values;
      const userDob = new Date(year, month, date);
      this.$store.commit('eligibility/patch', {
        bvn_data,
        bvn: this.values.bvn,
        profile: {
          ...this.$store.state.eligibility.profile,
          date_of_birth: userDob.toLocaleDateString()
        }
      });
      return this.$emit('done');
    }
  }
}
</script>
