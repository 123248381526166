<template>
  <div v-if="overlay" class="my-auto w-full">
    <RandomQuestions v-if="overlay === 'random-questions'" @done="overlay = ''"/>
    <div v-if="overlay === 'contact'" class="px-6 lg:px-12 pb-8 flex flex-col justify-center items-center text-center">
      <i class="fa fa-exclamation-circle fa-4x text-red-400 mb-10"></i>
      <h4 class="text-lg font-semibold">We could not analyze your bank statement</h4>
      <p class="mt-4">
        Kindly reach out to our support team at <a href="tel:+2349155577731">+2349155577731</a> to provide further
        assistance. Your reference is {{ reference }}
      </p>
      <AppButton @click="tryAgain()" variant="outline-danger" class="mt-8">Try again</AppButton>
    </div>
  </div>
  <div v-else class="h-full flex flex-col">
    <!-- Loading -->
    <Loader v-if="loading" :text="loading" class="px-6 lg:px-12 pb-8"/>
    <div v-else class="h-full flex flex-col">
      <div class="px-6 lg:px-12 pb-8">
        <!-- Upload -->
        <SectionHeader :title="title"/>
        <Message v-if="error" class="mb-6" variant="error">{{ error }}</Message>
        <label
          class="w-full py-6 px-6 border bg-gray-50 text-gray-600 rounded-lg flex flex-col justify-center cursor-pointer"
        >
          <span class="flex items-center">
            <input accept=".pdf" hidden name="file" type="file" @change="handleFileChange($event)">
            <i class="fa fa-file-pdf fa-2x text-red-500 mr-3"></i>
            <template v-if="!file">
              Click to select pdf file
            </template>
            <template v-else>
              <span class="truncate mr-2">{{ file.name }}</span>
              <i class="fa fa-check-circle fa-2x text-green-500 ml-auto"></i>
            </template>
          </span>
        </label>
        <div v-if="file" class="mt-7">
          <AppInput v-model="values.password" placeholder="Password"/>
          <small
            class="flex items-center leading-tight bg-yellow-50 rounded-lg py-3 px-4 text-yellow-600 mt-4 border border-yellow-100"
          >
            <i class="fa fa-exclamation-circle mr-2"></i>
            Enter password above if pdf file is secured, otherwise, leave password field empty
          </small>
          <div class="flex mt-6">
            <AppButton :disabled="!file || !values.type" @click="upload()">Analyse</AppButton>
          </div>
        </div>
        <div v-if="hasOptions" class="mt-6">
          <button class="text-gray-600 text-base" type="button" @click="$emit('options')">
            <span class="text-blue-600">I don't have </span>my bank statement file
          </button>
        </div>
      </div>
      <div class="flex justify-end mt-auto md:hidden">
        <img src="@/assets/images/savings.svg" alt="" class="w-60"/>
      </div>
    </div>
  </div>
</template>

<script>
import banks from "@/lib/banks.js";
import Loader from "@/components/global/Loader.vue";
import AppInput from "@/components/global/Input.vue";
import AppButton from "@/components/global/Button.vue";
import SectionHeader from "@/components/global/SectionHeader.vue";
import view from '@/mixins/view.js';
import RandomQuestions from "@/components/core/home/sections/RandomQuestions.vue";
import Message from "@/components/global/Message.vue";
import ajax from "@/mixins/ajax.js";
import axios from "axios";
import { delay } from "@/lib/utils.js";

export default {
  name: "BSLocal",
  components: { Message, RandomQuestions, SectionHeader, AppButton, AppInput, Loader },
  mixins: [view, ajax],
  props: {
    title: {
      type: String,
      default: 'Upload your bank statement'
    },
    hasOptions: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      banks: banks,
      file: null,
      values: {
        password: '',
        type: '1',
      },
      file_url: null,
      reference: null,
    };
  },
  created() {
    this.$mixpanel.track('Bs switched to pdf upload', {
      platform: this.$store.state.eligibility.config.platform,
      bank_code: this.$store.state.eligibility.account.bank_code,
      account_number: this.$store.state.eligibility.account.account_number,
      bank_name: this.$store.getters['eligibility/bankName'],
    });
  },
  methods: {
    handleFileChange(e) {
      this.values.password = '';
      this.file = e.target.files[0];
      this.reference = null;
      this.file_url = null;
    },
    async upload() {
      try {
        if (!this.file_url || !this.reference) {
          this.setLoading('Uploading..')
          const fd = new FormData();
          fd.append('file', this.file)
          fd.append('creditclan_request_id', this.$store.state.eligibility.request_id)
          fd.append('phone', this.$store.state.eligibility.profile.phone)
          const { bank_code } = this.$store.state.eligibility.account;
          const bank = this.banks.find(b => b.bank_code === bank_code)?.name
          fd.append('bank', bank)
          const res = await axios.post('https://sellbackend.creditclan.com/merchantclan/public/index.php/api/statement/upload', fd);
          const { link, reference } = res.data.data;
          this.reference = reference;
          this.file_url = link;
        }
        await this.analyze();
      } catch (e) {
        this.updateAnalysisStatus('failed')
        this.setError('We encountered an error while uploading your pdf, please try again', true);
        this.setBack(() => this.reset())
      }
      this.setLoading('')
    },
    async analyze() {
      this.setLoading('Processing bank statement..', true);
      try {
        const { request, profile, config: { platform } } = this.$store.state.eligibility;
        let repayment = +request.amount / 3;
        if (platform === 'rent-tenant') {
          repayment = +request.amount / 12;
        } else if (platform === 'ride') {
          repayment = +request.amount;
        }
        const { bank_code } = this.$store.state.eligibility.account;
        let bank = '';
        if (bank_code === '100004') bank = 'opay';
        if (bank_code === '50211') bank = 'kuda';
        const payload = {
          file_url: this.file_url,
          password: this.values.password,
          start_date: '',
          end_date: '',
          full_name: profile.full_name,
          repayment,
          type: this.type,
          bank
        };
        const { data } = await this.$http.post('https://lendnode.creditclan.com/bs/api/extraction/upload/pdf_url', payload);
        if (!data.status) return this.analyzeLegacy();
        if (data.transaction_id) {
          this.$mixpanel.track('Bs local successful', {
            platform: this.$store.state.eligibility.config.platform,
            bank_code: this.$store.state.eligibility.account.bank_code,
            account_number: this.$store.state.eligibility.account.account_number,
            bank_name: this.$store.getters['eligibility/bankName'],
          });
          const name_check = data.name_check;
          const { config } = this.$store.state.eligibility;
          if (config.bs_name_match && !name_check.status) {
            return this.setError(`Sorry, we cannot proceed with this statement. The name found in this statement is different from the name provided for this application. Please use a different statement or contact support with ref no. ${ this.reference }`, true);
          }
          this.updateAnalysisStatus('successful')
          return this.$emit('done', data.transaction_id);
        } else {
          this.updateAnalysisStatus('failed')
          this.setError(`Something went wrong, please check pdf file and try again or contact support with ref no. ${ this.reference }`, true);
        }
      } catch (e) {
        if (Object.hasOwn(e?.response?.data ?? {}, 'status')) {
          const status = e?.response?.data?.status;
          if (status === false || (+status >= 400 && +status < 500)) return this.analyzeLegacy();
        }
        this.updateAnalysisStatus('failed')
        const message = e?.response?.data?.message || e.message || 'Something went wrong';
        this.setError(`${ message }. Contact support with ref no. ${ this.reference } if you have any issues`, true);
        this.$mixpanel.track('Error from bs local', {
          platform: this.$store.state.eligibility.config.platform,
          bank_code: this.$store.state.eligibility.account.bank_code,
          account_number: this.$store.state.eligibility.account.account_number,
          bank_name: this.$store.getters['eligibility/bankName'],
          error: message,
        });
        if (!message.toLowerCase().includes('password')) return this.setOverlay('contact');
      }
    },
    async analyzeLegacy() {
      this.overlay = 'random-questions';
      this.setLoading('Processing bank statement..');
      const payload = {
        statement: this.file, ...this.values,
        full_name: this.$store.state.eligibility.profile.full_name
      };
      const fd = new FormData();
      Object.keys(payload).forEach(key => fd.append(key, payload[key]));
      try {
        const { data } = await this.$http.post('https://app.statementanalysis.co/analytics/widget', fd);
        if (data.transaction_id) {
          this.$mixpanel.track('Bs upload initialized', {
            platform: this.$store.state.eligibility.config.platform,
            bank_code: this.$store.state.eligibility.account.bank_code,
            account_number: this.$store.state.eligibility.account.account_number,
            bank_name: this.$store.getters['eligibility/bankName'],
            transaction_id: data.transaction_id,
            type: this.type,
          });
          this.setLoading('Analyzing..');
          await this.checkStatus(data.transaction_id);
        } else {
          this.setError(`Something went wrong, please check pdf file or try again or contact support with ref no. ${ this.reference }`, true);
        }
      } catch (err) {
        const message = err.response ? err.response.data.message : 'Something went wrong';
        this.setError(`${ message }. Contact support with ref no. ${ this.reference } if you have any issues`, true);
        this.setOverlay('contact');
      }
    },
    async checkStatus(transaction_id, n = 0) {
      if (n === 16) {
        this.$mixpanel.track('Bs local status check timed out', {
          platform: this.$store.state.eligibility.config.platform,
          bank_code: this.$store.state.eligibility.account.bank_code,
          account_number: this.$store.state.eligibility.account.account_number,
          bank_name: this.$store.getters['eligibility/bankName'],
        });
        return this.setError(`Something went wrong, please check pdf file or try again or contact support with ref no. ${ this.reference }`, true);
      }
      try {
        const { data } = await this.$http.get(`https://app.statementanalysis.co/analytics/status/${ transaction_id }`);
        const { analytics_pdf, extraction_error, name_check } = data;
        if (extraction_error) return this.setError(`${ extraction_error }. Contact support with ref no. ${ this.reference } if you have any issues`, true);
        if (analytics_pdf) {
          const { config } = this.$store.state.eligibility;
          if (config.bs_name_match && !name_check.status) {
            return this.setError(`Sorry, we cannot proceed with this statement. The name found in this statement is different from the name provided for this application. Please use a different statement or contact support with ref no. ${ this.reference }`, true);
          }
          this.$mixpanel.track('Bs local successful', {
            platform: this.$store.state.eligibility.config.platform,
            bank_code: this.$store.state.eligibility.account.bank_code,
            account_number: this.$store.state.eligibility.account.account_number,
            bank_name: this.$store.getters['eligibility/bankName'],
          });
          return this.$emit('done', transaction_id);
        }
        await delay(3000);
        return this.checkStatus(transaction_id, ++n);
      } catch (e) {
        const message = e?.response?.data?.error || e.message || 'Something went wrong';
        this.$mixpanel.track('Bs local status check failed', {
          platform: this.$store.state.eligibility.config.platform,
          bank_code: this.$store.state.eligibility.account.bank_code,
          account_number: this.$store.state.eligibility.account.account_number,
          bank_name: this.$store.getters['eligibility/bankName'],
          error: message,
        });
        this.setError(`${ message }. Contact support with ref no. ${ this.reference } if you have any issues`, true);
        this.setOverlay('contact');
      }
    },
    tryAgain() {
      this.setOverlay('');
      this.file = null;
      this.setError('');
    },
    async updateAnalysisStatus(analysis_status) {
      await axios.post('https://sellbackend.creditclan.com/merchantclan/public/index.php/api/statement/analysis', {
        reference: this.reference,
        analysis_status,
      });
    }
  },
}
</script>
