<template>
  <div v-if="overlay" class="my-auto w-full">
    <RandomQuestions @done="overlay = ''"/>
  </div>
  <div v-else class="h-full flex flex-col">
    <div class="pt-6 lg:py-10 h-full flex flex-col">
      <div class="flex justify-between mb-8 px-6 lg:px-12">
        <button
          @click="popView()"
          class="w-8 h-8 rounded-full text-gray-500 bg-gray-50 border text-sm flex items-center justify-center"
          :class="{ 'pointer-events-none opacity-0': history.length <= 1 || loading }"
        >
          <i class="fa fa-chevron-left"></i>
        </button>
        <button
          @click="cancel()" v-if="inIframe"
          class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 hover:bg-red-50"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="h-full flex flex-col md:h-auto md:my-auto">
        <div v-if="loading" class="px-6 lg:px-12 my-auto">
          <Loader :text="loading"/>
        </div>
        <Error v-else-if="error" :text="error" :show-retry="!!retryCb" @retry="retry()"/>
        <template v-else>
          <VerifyEmail
            v-if="view === 'verify-email'"
            @done="pushView('questions-alert')"
            @skip="replaceView('questions-alert')"
          />
          <QuestionsAlert
            v-if="view === 'questions-alert'"
            @done="replaceView('initial-questions')"
            @skip="replaceView('initial-questions')"
          />
          <RandomQuestions
            v-if="view === 'initial-questions'"
            :max="1"
            @done="replaceView('picture')"
            @skip="replaceView('picture')"
          />
          <Picture
            v-if="view === 'picture'"
            @done="pushView('profile')"
            @skip="replaceView('profile')"
          />
          <Profile
            v-if="view === 'profile'"
            @done="pushView('confirm-address')"
            @skip="replaceView('confirm-address')"
          />
          <ConfirmAddress
            v-if="view === 'confirm-address'"
            @done="pushView('work')"
            @skip="replaceView('work')"
          />
          <WorkInformation
            v-if="view === 'work'"
            @done="pushView('income')"
            @skip="replaceView('income')"
          />
          <Income
            v-if="view === 'income'"
            @done="pushView('verify-work-email')"
            @skip="replaceView('verify-work-email')"
          />
          <VerifyWorkEmail
            v-if="view === 'verify-work-email'"
            @done="pushView('work-identity')"
            @skip="replaceView('work-identity')"
          />
          <WorkIdentity
            v-if="view === 'work-identity'"
            @done="pushView('next-of-kin')"
            @skip="replaceView('next-of-kin')"
          />
          <NextOfKin
            v-if="view === 'next-of-kin'"
            @done="pushView('attachments')"
            @skip="replaceView('attachments')"
          />
          <Attachments
            v-if="view === 'attachments'"
            @done="pushView('social-handles')"
            @skip="replaceView('social-handles')"
          />
          <SocialHandles
            v-if="view === 'social-handles'"
            @done="pushView('fcn')"
            @skip="replaceView('fcn')"
          />
          <FrequentlyCalledNumbers
            v-if="view === 'fcn'"
            @done="pushView('nin')"
            @skip="replaceView('nin')"
          />
          <NationalIdentityNumber
            v-if="view === 'nin'"
            @view="pushView($event)"
            @done="runFaceMatch(); pushView('account')"
            @skip="runFaceMatch(); replaceView('account')"
          />
          <BankAccount
            v-if="view === 'account'"
            @done="pushView('bvn')"
            @skip="replaceView('bvn')"
          />
          <BVN
            v-if="view === 'bvn'"
            @done="createRequest(); pushView('bank-statement')"
            @skip="createRequest(); replaceView('bank-statement')"
          />
          <BankStatement
            v-if="view === 'bank-statement'"
            @back="popView()"
            @done="finish()"
            @skip="finish()"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Picture from "@/components/core/home/sections/Picture.vue";
import Loader from "@/components/global/Loader.vue";
import view from "@/mixins/view.js";
import ajax from "@/mixins/ajax.js";
import Error from "@/components/global/Error.vue";
import RandomQuestions from "@/components/core/home/sections/RandomQuestions.vue";
import ConfirmAddress from "@/components/core/home/sections/ConfirmAddress.vue";
import WorkInformation from "@/components/core/home/sections/WorkInformation.vue";
import SocialHandles from "@/components/core/home/sections/SocialHandles.vue";
import FrequentlyCalledNumbers from "@/components/core/home/sections/FrequentlyCalledNumbers.vue";
import NationalIdentityNumber from "@/components/core/home/sections/NationalIdentityNumber.vue";
import Income from "@/components/core/home/sections/Income.vue";
import BankAccount from "@/components/core/home/sections/BankAccount.vue";
import BankStatement from "@/components/core/home/sections/BankStatement.vue";
import QuestionsAlert from "@/components/core/home/sections/QuestionsAlert.vue";
import VerifyEmail from "@/components/core/home/sections/VerifyEmail.vue";
import Profile from "@/components/core/home/sections/Profile.vue";
import VerifyWorkEmail from "@/components/core/home/sections/VerifyWorkEmail.vue";
import NextOfKin from "@/components/core/home/sections/NextOfKin.vue";
import WorkIdentity from "@/components/core/home/sections/WorkIdentity.vue";
import BVN from "@/components/core/home/sections/BVN.vue";
import Attachments from "./sections/Attachments.vue";

export default {
  name: "PreAnalysis",
  components: {
    Attachments,
    BVN,
    WorkIdentity,
    NextOfKin,
    VerifyWorkEmail,
    Profile,
    VerifyEmail,
    QuestionsAlert,
    BankStatement,
    BankAccount,
    Income,
    NationalIdentityNumber,
    FrequentlyCalledNumbers,
    SocialHandles,
    WorkInformation,
    ConfirmAddress,
    RandomQuestions,
    Error,
    Loader,
    Picture,
  },
  mixins: [view, ajax],
  data: () => ({
    inIframe: window.self !== window.top
  }),
  async created() {
    await this.getBanks();
    const { stage } = this.$store.state.eligibility;
    if (!stage || stage === 'created') this.pushView('verify-email');
    else if (stage === 'bank-statement') this.pushView('account');
  },
  methods: {
    async runFaceMatch(retries = 1) {
      const { has_data } = this.$store.state.eligibility;
      if (!has_data) return;
      if (retries >= 3) return;
      try {
        const res = await this.$http.post('/customer/facematch', {
          token: this.$store.state.eligibility.token
        });
        if (!res?.data?.people_id) return await this.runFaceMatch(++retries);
      } catch (e) {
        console.log(e);
        return await this.runFaceMatch(++retries);
      }
    },
    async finish() {
      this.$emit('done');
    },
    async createRequest() {
      try {
        this.setLoading('Saving..');
        if (!this.$store.state.eligibility.request_id) {
          await this.$store.dispatch('eligibility/createRequest');
        }
        await this.$store.dispatch('eligibility/saveUserData');
        this.setLoading('');
      } catch (e) {
        const message = e.response ? e.response.data.message : e.message || 'An error occurred, please try again';
        this.setError(message, true);
        this.setRetry(() => this.createRequest());
        this.$mixpanel.track('Error while creating request', {
          platform: this.$store.state.eligibility.config.platform,
          request_id: this.$store.state.eligibility.request_id,
          error: message,
        });
      }
    },
    cancel() {
      this.$bus.$emit('cancel');
    },
    async getBanks() {
      if (this.$store.state.eligibility.banks_mono) return;
      this.setLoading('Please wait..')
      const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
      const banks_mono = res.data.data;
      this.$store.commit('eligibility/patch', { banks_mono })
      this.setLoading('');
    }
  }
};
</script>
