const state = {
  payload: {
    ACCOUNT_CARD_ID: null,
    ATTACHMENT_ID: null,
    LENDER_ID: null,
    REQUEST_ID: null,
    account_name: null,
    account_number: null,
    api_key: null,
    bank_code: null,
    clientid: null,
    clientsecret: null,
    logo: null,
    name: null,
    statement_source: null,
  },
  banks_mono: null,
  bs_token: null,
};

const getters = {
  bankName: state => {
    return state.banks_mono?.find(bank => bank.bank_code === state.payload.bank_code)?.name || ''
  },
};

const mutations = {
  patch: (state, data) => {
    Object.keys(data).forEach(key => {
      state[key] = data[key];
    });
  },
};

const actions = {};

const statement = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default statement;
