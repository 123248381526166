<template>
  <div class="my-auto">
    <div v-if="offer.amount > 0" class="flex flex-col items-stretch my-auto text-left px-6 lg:px-12 mt-10">
      <SectionHeader
        title="Congratulations"
        subtitle="Based on your cashflow analysis, you have received the following offers"
      />
      <div v-if="loading" class="grid grid-cols-6 gap-4">
        <div class="col-span-5 bg-gray-200 rounded-lg h-72"></div>
        <div class="col-span-1 bg-gray-200 rounded-lg h-72"></div>
      </div>
      <swiper v-else ref="mySwiper" :options="swiperOptions" class="w-full">
        <swiper-slide v-if="modified && !plans.length">
          <div
            @click="selectOffer(modified)"
            class="rounded-lg p-6 cursor-pointer flex flex-col border shadow-md h-full"
            :class="colors[0] || 'bg-gray-50 border-gray-100'"
          >
            <p class="text-gray-600">Pay</p>
            <div class="text-xl font-semibold">{{ modified.upfront | currency }} <small
              class="text-gray-600">now</small></div>
            <div v-if="modified.duration === 1" class="text-gray-700">
              and {{ modified.monthly_repayment | currency }} next month
            </div>
            <div v-else class="text-gray-700">
              and {{ modified.monthly_repayment | currency }}/mo for {{ modified.duration }} months
            </div>
            <div class="flex justify-end items-center mt-40 text-gray-600">
              <i class="fa fa-user mr-2"></i> {{ lender ? lender.name : modified.lender }}
            </div>
          </div>
        </swiper-slide>
        <template v-if="modified && plans.length">
          <template v-for="(plan, i) in plans">
            <swiper-slide :key="i">
              <div
                @click="selectPlan(plan)"
                class="rounded-lg p-6 cursor-pointer flex flex-col border shadow-md h-full"
                :class="colors[i] || 'bg-gray-50 border-gray-100'"
              >
                <p class="text-gray-600">Pay</p>
                <div class="text-xl font-semibold">{{ modified.upfront | currency }} <small
                  class="text-gray-600">now</small></div>
                <div v-if="plan.duration === 1" class="text-gray-700">
                  and {{ plan.monthly | currency }} next month
                </div>
                <div v-else class="text-gray-700">
                  and {{ plan.monthly | currency }}/mo for {{ plan.duration }} months
                </div>
                <ul class="border border-gray-300 divide-y divide-gray-300 rounded-lg px-3 text-sm mt-4">
                  <li v-for="(item, i) in plan.items" :key="i" class="py-1">{{ item.desc }}</li>
                </ul>
              </div>
            </swiper-slide>
          </template>
          <swiper-slide>
            <div
              @click="handleEdit()"
              class="rounded-lg p-6 cursor-pointer flex flex-col items-center justify-center border shadow-md h-full bg-gray-50 border-gray-200"
            >
              <i class="fa fa-pen fa-3x opacity-60 text-orange-400"></i>
              <div class="text-gray-700 mt-6">
                Modify offer
              </div>
            </div>
          </swiper-slide>
        </template>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <template v-else>
      <div class="text-center max-w-xs mx-auto flex flex-col items-center justify-center my-auto">
        <i class="fa fa-frown fa-5x text-gray-300"></i>
        <p class="mt-10 text-gray-600 font-semibold text-lg">We could not get any offer</p>
        <p class="text-gray-500 mt-3">
          Sorry, our partners believe your data is insufficient to offer you credit. Kindly contact our support on
          <a href="tel:01 229 7897">01 229 7897</a> if you have any other issues
        </p>
        <div class="mt-7 flex">
          <AppButton :loading="accepting" @click="$emit('pay-full')">
            Pay full amount {{ offer.upfront | currency }}
          </AppButton>
        </div>
      </div>
    </template>

    <Modal v-if="modal === 'modify'">
      <div class="my-auto">
        <SectionHeader
          title="Modify offer"
          subtitle="Adjust upfront payment and repayment duration"
        />
        <div class="border bg-orange-50 bg-opacity-50 px-5 py-4 rounded-lg">
          <label for="large-range" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Upfront amount - <span class="font-semibold">{{ values.upfront | currency }}</span>
          </label>
          <div class="flex justify-between items-center text-sm">
            <span>{{ values.min_upfront | currency }}</span>
            <span>{{ values.max_upfront | currency }}</span>
          </div>
          <input
            id="large-range" v-model="values.upfront" :max="values.max_upfront" :min="values.min_upfront"
            class="w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg dark:bg-gray-700"
            step="2000" type="range" value="50"
          >
        </div>
        <div class="border bg-orange-50 bg-opacity-50 px-5 py-4 rounded-lg mt-4">
          <label for="large-range" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Duration - <span class="font-semibold">{{ values.duration }} month(s)</span>
          </label>
          <div class="flex justify-between items-center text-sm">
            <span>{{ values.min_duration }} month(s)</span>
            <span>{{ values.max_duration }} month(s)</span>
          </div>
          <input
            type="range" value="50" id="large-range"
            v-model="values.duration" :min="values.min_duration" :max="values.max_duration"
            class="w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg dark:bg-gray-700"
          />
        </div>
        <div class="flex mt-8">
          <AppButton @click="generateOffers()" :loading="accepting">Update</AppButton>
          <AppButton variant="outline-danger" @click="modal = ''" class="ml-3">Cancel</AppButton>
        </div>
      </div>
    </Modal>

    <Modal v-if="modal === 'summary' && selectedOffer">
      <div class="my-auto">
        <SectionHeader
          title="Confirm offer"
          :subtitle="`I agree that I will pay ${formatCurrency(selectedOffer.upfront)} now and pay ${formatCurrency(selectedOffer.monthly_repayment)} in ${selectedOffer.duration} month(s)`"
        />
        <p class="-mt-4">Includes:</p>
        <ul class="border border-gray-300 divide-y divide-gray-300 rounded-lg px-3 text-sm mt-4">
          <li v-for="(item, i) in selectedOffer.plan.items" :key="i" class="py-2">{{ item.desc }}</li>
        </ul>
        <div class="flex mt-8">
          <AppButton @click="$emit('accept', selectedOffer)" :loading="accepting">Accept</AppButton>
          <button @click="selectedOffer = null" class="ml-2 text-red-500 py-1 px-3">Cancel</button>
        </div>
      </div>
      <div class="flex justify-end mt-auto md:hidden">
        <img src="@/assets/images/approve.svg" alt="" class="w-56"/>
      </div>
    </Modal>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppButton from "@/components/global/Button.vue";
import { formatCurrency } from "@/lib/utils.js";
import Modal from "@/components/global/Modal.vue";
import { addHours } from "date-fns";

export default {
  name: "PlanOffers",
  components: { Modal, AppButton, SectionHeader },
  props: {
    offer: { type: Object, required: true },
    accepting: { type: Boolean, required: true },
  },
  data() {
    return {
      modified: null,
      offers: [],
      selectedOffer: null,
      modal: '',
      loading: false,
      duration: 0,
      swiperOptions: {
        slidesPerView: 1.2,
        spaceBetween: 20,
      },
      colors: ['bg-orange-50 border-orange-100', 'bg-teal-50 border-teal-100', 'bg-red-50 border-red-100', 'bg-blue-50 border-blue-100'],
      lender: this.$store.state.eligibility.lender,
      formatCurrency,
      plans: [],
      time: addHours(new Date(), 1),
      refreshing: false,
      values: {
        upfront: 0,
        duration: 0,
        amount: 0,
        min_duration: 0,
        max_duration: 0,
        min_upfront: 0,
        max_upfront: 0,
        interest: 0,
        total_repayment: 0,
      },
    }
  },
  computed: {
    offer_reviewed() {
      return this.$store.state.eligibility.offer_reviewed;
    }
  },
  async created() {
    this.resetValues();
    await this.generateOffers();
  },
  methods: {
    handleEdit() {
      this.resetValues();
      this.modal = 'modify';
    },
    resetValues() {
      const { request } = this.$store.state.eligibility;
      const { upfront, duration } = this.offer;
      this.values = {
        upfront,
        min_upfront: upfront,
        max_upfront: (80 / 100) * request.amount,
        duration,
        min_duration: duration,
        max_duration: Math.max(6, duration),
      };
    },
    async generateOffers() {
      this.modal = '';
      const { request } = this.$store.state.eligibility;
      const { duration } = this.offer;
      this.modified = {
        loan_amount: request.amount - this.values.upfront,
        duration: +this.values.duration,
        upfront: this.values.upfront,
      };
      await this.getPlans({
        amount: this.modified.loan_amount,
        duration: this.modified.duration,
        interest: this.modified.duration > duration ? 10 : null
      });
    },
    async getPlans({ amount, duration, interest }) {
      this.loading = true;
      try {
        const res = await this.$http.get('https://cc-eligibility-staging.herokuapp.com/misc/plans', {
          params: { vertical: 'bnpl', amount, duration, interest }
        });
        this.plans = res.data.plans;
      } catch (e) {
        console.log({ e });
      }
      this.loading = false;
    },
    selectOffer(offer) {
      this.selectedOffer = { ...offer };
      this.modal = 'summary';
    },
    selectPlan(plan) {
      this.selectedOffer = {
        loan_amount: plan.total,
        repayment_amount: plan.total,
        duration: plan.duration,
        monthly_repayment: plan.monthly,
        upfront: this.modified.upfront,
        tenor_type: 2,
        plan
      };
      this.modal = 'summary';
    },
    async refresh() {
      this.refreshing = true;
      await this.$store.dispatch('eligibility/getRequestDetails');
      this.refreshing = false;
      this.resetValues();
      await this.generateOffers();
    },
    copyLink() {
      navigator.clipboard.writeText(location.href).then(() => {
        console.log('Link copied');
      }, err => {
        console.log({ err });
      });
    }
  }
}
</script>
