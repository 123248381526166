<template>
  <div class="h-full flex flex-col">
    <PreAnalysis v-if="view === 'pre-analysis'" @done="preAnalysisDone()"/>
    <PostAnalysis v-if="view === 'post-analysis'" @done="postAnalysisDone()"/>
    <Success v-if="view === 'success'"/>
  </div>
</template>

<script>
import PreAnalysis from "@/components/core/instant/PreAnalysis.vue";
import view from '@/mixins/view.js';
import PostAnalysis from "@/components/core/instant/PostAnalysis.vue";
import Success from "@/components/core/home/sections/Success.vue";

export default {
  name: "Analysis",
  components: { Success, PostAnalysis, PreAnalysis },
  mixins: [view],
  created() {
    const { stage } = this.$store.state.eligibility;
    if (stage && stage !== 'created') return this.pushView('post-analysis');
    this.pushView('pre-analysis');
  },
  methods: {
    preAnalysisDone() {
      this.pushView('post-analysis');
    },
    postAnalysisDone() {
      this.pushView('success');
    },
  }
}
</script>
