<template>
  <div class="h-full flex flex-col">
    <div class="px-6 lg:px-12 pb-8">
      <SectionHeader title="Enter your home address"/>
      <ValidationObserver ref="observer">
        <form class="flex flex-col space-y-3">
          <ValidationProvider name="address" rules="required" v-slot="{ errors }">
            <AppTextArea label="Home/Street address" v-model="values.address" :errors="errors"/>
          </ValidationProvider>
          <ValidationProvider name="state" rules="required" v-slot="{ errors }">
            <AppSelect label="State" v-model="values.state_id" :errors="errors" @change="handleStateChange($event)">
              <option value="">State</option>
              <option v-if="!states.length" value="" disabled>Loading</option>
              <option v-for="state in states" :key="state.value" :value="state.value">
                {{ state.text }}
              </option>
            </AppSelect>
          </ValidationProvider>
          <ValidationProvider name="lga" rules="required" v-slot="{ errors }">
            <AppSelect label="LGA" v-model="values.lga_id" :errors="errors">
              <option value="">LGA</option>
              <option v-if="values.state_id && !lgas.length" value="" disabled>Loading</option>
              <option v-for="lga in lgas" :key="lga.value" :value="lga.value">{{ lga.text }}</option>
            </AppSelect>
          </ValidationProvider>
          <ValidationProvider name="home_landmark" rules="required" v-slot="{ errors }">
            <AppInput label="Nearest bus-stop" v-model="values.home_landmark" :errors="errors"/>
          </ValidationProvider>
        </form>
        <div class="mt-10 flex items-center">
          <AppButton @click="save()">Continue</AppButton>
        </div>
      </ValidationObserver>
    </div>
    <div class="flex justify-end mt-auto md:hidden">
      <img src="@/assets/images/whatsapp-phone-half.png" alt="" class="w-52"/>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppTextArea from "@/components/global/TextArea.vue";
import AppSelect from "@/components/global/Select.vue";
import AppButton from "@/components/global/Button.vue";
import states from "@/lib/states.js";
import AppInput from "@/components/global/Input.vue";

export default {
  name: "ConfirmAddress",
  components: { AppInput, AppButton, AppSelect, AppTextArea, SectionHeader },
  data() {
    return {
      home_address: { ...this.$store.state.eligibility.home_address },
      values: {
        address: '',
        state_id: '',
        lga_id: '',
        home_landmark: ''
      },
      states,
      lgas: []
    }
  },
  created() {
    const { config: { show_address }, home_address } = this.$store.state.eligibility;
    const { oneMonth } = this.$store.getters["eligibility/lastApplied"];
    if (!show_address || (oneMonth && home_address.address)) return this.$emit("skip");
    const { address, state_id, lga_id, home_landmark } = home_address;
    this.values = { address, state_id, lga_id, home_landmark };
    if (this.values.state_id) this.getStateLgas();
    Object.keys(this.values).forEach(key => {
      if (this.values[key] === '0') {
        this.values[key] = null;
      }
    })
  },
  methods: {
    next() {
      this.$emit("done");
    },
    async save() {
      if (!(await this.$refs.observer.validate())) return;
      this.$store.commit("eligibility/patch", {
        home_address: {
          ...this.$store.state.eligibility.home_address, ...this.values
        }
      });
      this.next();
    },
    handleStateChange() {
      this.values.lga_id = null;
      this.lgas = [];
      this.getStateLgas();
    },
    async getStateLgas() {
      const res = await this.$http.get(`https://mobile.creditclan.com/webapi/v1/states/${ this.values.state_id }/lgas`);
      this.lgas = res.data.data.map(lga => ({ text: lga.name, value: lga.id }));
    },
  },
};
</script>
