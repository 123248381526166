<template>
  <div class="h-full flex flex-col">
    <div v-if="loading" class="px-6 lg:px-12 my-auto">
      <div v-if="loading === 'countdown'" class="h-full flex flex-col">
        <p class="py-32 md:py-20 text-center text-xl font-semibold my-auto">
          <Countdown :from="60" v-slot="{ current }">
            <span v-if="current">Finishing analysis.. {{ current }}</span>
            <span v-else>Almost done..</span>
          </Countdown>
        </p>
        <div class="flex justify-center mt-auto mx-auto">
          <img src="@/assets/images/skateboard.gif" alt="" class="w-3/4 md:w-2/4"/>
        </div>
      </div>
      <Loader v-else :text="loading"/>
    </div>
    <Error v-else-if="error" :text="error" :show-retry="!!retryCb" @retry="retry()" class="my-auto"/>
    <div v-else class="h-full flex flex-col">
      <div v-if="response" class="px-6 lg:px-12 my-auto">
        <SectionHeader
          subtitle="Click the button below to continue"
          title="Thank you for providing your bank statement"
        />
        <div class="flex mt-10">
          <AppButton @click="handleSubmit()">Continue</AppButton>
        </div>
      </div>
      <template v-else>
        <div v-if="view === 'options'" class="px-6 lg:px-12 md:my-auto">
          <SectionHeader
            title="Digitally share your bank statement with us"
            subtitle="We will analyze your bank statement in 30 secs to ascertain eligibility. Choose any of options below"
          />
          <div
            v-if="$store.state.eligibility.config.specify_account_type"
            class="mb-6 flex flex-col md:flex-row md:items-center"
          >
            <p class="mb-3 md:mb-0 mr-4">Select account type:</p>
            <div
              class="flex-1 border rounded-lg max-w-2xl flex items-center cursor-pointer bg-white divide-x divide-gray-300"
            >
              <div
                @click="type = 'personal'"
                class="flex-1 flex items-center hover:bg-gray-100 px-6 py-2"
              >
                <i
                  class="fa fa-check text-green-500 transform transition-all duration-200"
                  :class="type === 'personal' ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'"
                />
                <p class="transition-all duration-200" :class="type !== 'personal' ? '-ml-4' : 'ml-3'">Personal</p>
              </div>
              <div
                @click="type = 'business'"
                class="flex-1 flex items-center hover:bg-gray-100 px-6 py-2"
              >
                <i
                  class="fa fa-check text-green-500 transform transition-all duration-200"
                  :class="type === 'business' ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'"
                />
                <p class="transition-all duration-200" :class="type !== 'business' ? '-ml-4' : 'ml-3'">Business</p>
              </div>
            </div>
          </div>
          <div class="flex flex-col space-y-4">
            <div
              @click="view = 'mbs'"
              class="border px-5 py-5 rounded-lg flex items-center cursor-pointer bg-white hover:bg-gray-50"
              :class="{ 'opacity-70 pointer-events-none': !supportsMbs}"
            >
              <div>
                <div class="w-12 text-blue-600"><i class="fa fa-sms fa-2x"/></div>
              </div>
              <div>
                <h6 class="font-medium">Receive PIN from your bank</h6>
                <p class="text-sm mt-1 opacity-70">
                  Provide a ticket id and password sent by your bank to authorize us to get your statement
                </p>
                <p v-if="!supportsMbs" class="text-sm text-red-600 mt-2">Not supported for your bank</p>
              </div>
            </div>
            <div
              @click="view = 'mono'"
              class="border px-5 py-5 rounded-lg flex items-center cursor-pointer bg-white hover:bg-gray-50"
              :class="{ 'opacity-70 pointer-events-none': !supportsMono}"
            >
              <div>
                <div class="w-12 text-teal-600">
                  <i class="fa fa-mobile-alt fa-2x"></i>
                </div>
              </div>
              <div>
                <h6 class="font-medium">Use internet banking</h6>
                <p class="text-sm mt-1 opacity-70">
                  Kindly use your internet or mobile banking credentials to get statement.
                </p>
                <p v-if="!supportsMono" class="text-sm text-red-600 mt-2">Not supported for your bank</p>
              </div>
            </div>
            <div
              @click="view = 'local'"
              class="border px-5 py-5 rounded-lg flex items-center cursor-pointer bg-white hover:bg-gray-50"
            >
              <div>
                <div class="w-12 text-red-600"><i class="fa fa-file-pdf fa-2x"/></div>
              </div>
              <div>
                <h6 class="font-medium">Upload bank statement file</h6>
                <p class="text-sm mt-1 opacity-70">
                  Kindly upload last 7months Pdf statement here.
                </p>
              </div>
            </div>
            <br><br>
          </div>
        </div>
        <template v-if="view === 'local'">
          <BSLocal
            :type="type"
            :has-options="hasOptions"
            @done="localDone($event)"
            @options="view = 'options'"
          />
        </template>
        <template v-if="view === 'mono'">
          <BSMono
            @done="monoDone($event)"
            @failed="handleMonoFailed()"
            @options="view = 'options'"
          />
        </template>
        <template v-if="view === 'mbs'">
          <BSMbs
            @done="mbsDone($event)"
            @options="view = 'options'"
            @failed="handleMbsFailed()"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppButton from "@/components/global/Button.vue";
import BSLocal from "@/components/core/home/sections/BSLocal.vue";
import BSMono from "@/components/core/home/sections/BSMono.vue";
import BSMbs from "@/components/core/home/sections/BSMbs.vue";
import mbsBanks from "@/lib/mbs-banks.js";
import ajax from "@/mixins/ajax.js";
import Loader from "@/components/global/Loader.vue";
import Error from "@/components/global/Error.vue";
import { delay } from "@/lib/utils.js";
import Countdown from "@/components/global/Countdown.vue";

export default {
  name: "BankStatement",
  components: { Countdown, Error, Loader, BSMbs, BSMono, BSLocal, AppButton, SectionHeader },
  mixins: [ajax],
  data() {
    return {
      view: 'options',
      has_online_banking: 1,
      fallback: false,
      modals: {
        options: false,
      },
      type: '',
    }
  },
  created() {
    const { config: { analyze_bank_statement, remember_last_offer }, last_offer } = this.$store.state.eligibility;
    if (!analyze_bank_statement) return this.$emit('skip');
    const { oneMonth } = this.$store.getters['eligibility/lastApplied'];
    if ((oneMonth && +last_offer?.[0]?.amount) && remember_last_offer) return this.$emit('skip');
    this.init();
  },
  methods: {
    init() {
      const { config, account, stage } = this.$store.state.eligibility;
      if (stage !== 'bank-statement') {
        this.$store.dispatch('eligibility/updateStage', { stage: 'bank-statement' });
      }
      if (config.specify_account_type) {
        this.type = 'personal';
      }
      this.$mixpanel.track('Started bank statement', {
        platform: config.platform,
        bank_code: account.bank_code,
        account_number: account.account_number,
        bank_name: this.$store.getters['eligibility/bankName'],
      });
    },
    showOptions() {
      if (this.hasOptions) this.modals.options = true;
    },
    handleMonoFailed() {
      this.view = 'local';
    },
    handleMbsFailed() {
      if (this.supportsMono) this.view = 'mono';
      else this.view = 'local';
    },
    localDone(transaction_id) {
      this.$store.commit('eligibility/patch', {
        bs: {
          ...this.$store.state.eligibility.bs,
          response: transaction_id ? {
            statement_source: 1,
            filename: { transaction_id },
            has_online_banking: false
          } : null
        }
      });
      this.handleSubmit();
    },
    monoDone(response) {
      this.$store.commit('eligibility/patch', {
        bs: {
          ...this.$store.state.eligibility.bs,
          response: {
            statement_source: 4,
            filename: response,
            has_online_banking: true
          }
        }
      });
      this.handleSubmit();
    },
    mbsDone({ statement_source, transaction_id, ...rest }) {
      this.$store.commit('eligibility/patch', {
        bs: {
          ...this.$store.state.eligibility.bs,
          response: {
            statement_source,
            filename: { transaction_id },
            has_online_banking: false,
            ...rest
          }
        }
      });
      this.handleSubmit();
    },
    async handleSubmit() {
      this.$mixpanel.track('Attempt to submit bank statement report', {
        platform: this.$store.state.eligibility.config.platform,
        request_id: this.$store.state.eligibility.request_id,
        bs_response: this.$store.state.eligibility.bs.response
      });
      this.setLoading('countdown');
      if (!this.$store.state.eligibility.request_id) return this.setError('No request found');
      let { bs } = this.$store.state.eligibility;
      const analyzed = await this.handleBsSubmit();
      if (analyzed) {
        const done = await this.submitBsReport();
        if (done) {
          this.$mixpanel.track('Successfully submitted bank statement report', {
            platform: this.$store.state.eligibility.config.platform,
            request_id: this.$store.state.eligibility.request_id,
            bs_response: this.$store.state.eligibility.bs.response
          });
          return this.$emit('done');
        }
      }
      if (!this.error) {
        this.$store.commit('eligibility/patch', { bs: { response: null } });
        let message = 'We could not analyze your bank statement, please try again';
        if (bs.response?.statement_source === 3) {
          message = 'We could not get your bank statement, please make sure you have funds in your account';
        }
        this.setError(message);
        this.$mixpanel.track('Could not analyze bank statement', {
          platform: this.$store.state.eligibility.config.platform,
          request_id: this.$store.state.eligibility.request_id,
          bank_code: this.$store.state.eligibility.account.bank_code,
          account_number: this.$store.state.eligibility.account.account_number,
          bank_name: this.$store.getters['eligibility/bankName'],
          error: this.error,
        });
        this.setRetry(() => this.showOptions());
      }
      this.setLoading('');
    },
    async handleBsSubmit(retries = 10) {
      if (retries === 0) return false;
      if (retries < 10) await delay(5000);
      try {
        const { bs: { response }, request_id, token } = this.$store.state.eligibility;
        if (response.statement_source === 1) return true;
        const res = await this.$http.post(`https://mobile.creditclan.com/api/v3/bankstatement/success`, {
          analyze: true, request_id, token, ...response, type: this.type
        });
        if (res.data.responsecode === '01') return false;
        if (!res.data.status) return await this.handleBsSubmit(--retries);
        return true;
      } catch (e) {
        const message = e.response ? e.response.data.message : e.message || 'An error occurred, please try again';
        this.setError(message);
        this.setRetry(() => this.handleSubmit());
        this.$mixpanel.track('Error while submitting bs report', {
          platform: this.$store.state.eligibility.config.platform,
          request_id: this.$store.state.eligibility.request_id,
          error: message
        });
        return false;
      }
    },
    async submitBsReport(prev_bs_token = null, retries = 10) {
      if (retries === 0) return false;
      if (retries < 10) await delay(5000);
      try {
        const {
          bs: { response }, token, request_id, account: { account_name, account_number, bank_id }
        } = this.$store.state.eligibility;
        let bs_token = prev_bs_token;
        if (!bs_token) {
          const { data: { token: new_bs_token } } = await this.$http.post('https://mobile.creditclan.com/api/v3/bankstatement/initiate', {
            request_id, token, account: { account_number, bank_id, account_name, },
            has_online_banking: response.has_online_banking ? '1' : '0'
          });
          bs_token = new_bs_token;
        }
        const res = await this.$http.post(`/bankstatement/update`, {
          token: bs_token, analyze: true, ...response, type: this.type
        });
        if (res.data.responsecode === '01') return false;
        if (!res.data.status) return await this.submitBsReport(bs_token, --retries);
        return true;
      } catch (e) {
        const message = e.response ? e.response.data.message : e.message || 'An error occurred, please try again';
        this.setError(message);
        this.setRetry(() => this.handleSubmit());
        this.$mixpanel.track('Error while submitting bs report', {
          platform: this.$store.state.eligibility.config.platform,
          request_id: this.$store.state.eligibility.request_id,
          error: message
        });
        return false;
      }
    },
    cancel() {
      this.view = 'local';
      this.fallback = true;
    }
  },
  computed: {
    supportsMbs() {
      return mbsBanks.some(b => b.sortCode === this.$store.state.eligibility.account.bank_code);
    },
    supportsMono() {
      const { account: { bank_code }, banks_mono } = this.$store.state.eligibility;
      return !!banks_mono.find(bank => `${ bank.bank_code }` === `${ bank_code }`)?.mono_bank_data;
    },
    hasOptions() {
      return this.supportsMono || this.supportsMbs;
    },
    response() {
      return this.$store.state.eligibility.bs.response;
    },
    isZenithOrGt() {
      return this.$store.state.eligibility.account.bank_code.match(/057|058/gi);
    }
  },
  watch: {
    view() {
      this.reset();
    }
  }
}
</script>
