var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full flex flex-col"},[_c('div',{staticClass:"px-6 lg:px-12 pb-8"},[_c('SectionHeader',{attrs:{"title":"Enter your home address"}}),_c('ValidationObserver',{ref:"observer"},[_c('form',{staticClass:"flex flex-col space-y-3"},[_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppTextArea',{attrs:{"label":"Home/Street address","errors":errors},model:{value:(_vm.values.address),callback:function ($$v) {_vm.$set(_vm.values, "address", $$v)},expression:"values.address"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{attrs:{"label":"State","errors":errors},on:{"change":function($event){return _vm.handleStateChange($event)}},model:{value:(_vm.values.state_id),callback:function ($$v) {_vm.$set(_vm.values, "state_id", $$v)},expression:"values.state_id"}},[_c('option',{attrs:{"value":""}},[_vm._v("State")]),(!_vm.states.length)?_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Loading")]):_vm._e(),_vm._l((_vm.states),function(state){return _c('option',{key:state.value,domProps:{"value":state.value}},[_vm._v(" "+_vm._s(state.text)+" ")])})],2)]}}])}),_c('ValidationProvider',{attrs:{"name":"lga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{attrs:{"label":"LGA","errors":errors},model:{value:(_vm.values.lga_id),callback:function ($$v) {_vm.$set(_vm.values, "lga_id", $$v)},expression:"values.lga_id"}},[_c('option',{attrs:{"value":""}},[_vm._v("LGA")]),(_vm.values.state_id && !_vm.lgas.length)?_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Loading")]):_vm._e(),_vm._l((_vm.lgas),function(lga){return _c('option',{key:lga.value,domProps:{"value":lga.value}},[_vm._v(_vm._s(lga.text))])})],2)]}}])}),_c('ValidationProvider',{attrs:{"name":"home_landmark","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{attrs:{"label":"Nearest bus-stop","errors":errors},model:{value:(_vm.values.home_landmark),callback:function ($$v) {_vm.$set(_vm.values, "home_landmark", $$v)},expression:"values.home_landmark"}})]}}])})],1),_c('div',{staticClass:"mt-10 flex items-center"},[_c('AppButton',{on:{"click":function($event){return _vm.save()}}},[_vm._v("Continue")])],1)])],1),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-end mt-auto md:hidden"},[_c('img',{staticClass:"w-52",attrs:{"src":require("@/assets/images/whatsapp-phone-half.png"),"alt":""}})])}]

export { render, staticRenderFns }