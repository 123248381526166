<template>
  <div class="h-full flex flex-col">
    <div class="flex flex-col overflow-hidden px-6 lg:px-12">
      <div>
        <template v-if="questions[current]">
          <div class="mb-10">
            <h2
              class="text-3xl lg:text-4xl font-semibold w-11/12 text-blue-900"
              v-html="questions[current].question"
            ></h2>
            <p :class="`text-gray-500 mt-2 max-w-lg`" v-html="questions[current].rider"/>
          </div>
          <div class="flex flex-col gap-3">
            <div
              @click="chooseAnswer(answer)"
              v-for="(answer, i) in questions[current].answers" :key="i"
              class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between"
            >
              {{ answer }}
              <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="flex justify-end mt-auto md:hidden">
      <img src="@/assets/images/whatsapp-phone-half.png" alt="" class="w-52"/>
    </div>
  </div>
</template>

<script>
import ajax from "@/mixins/ajax.js";

export default {
  name: "RandomQuestions",
  mixins: [ajax],
  props: {
    max: {
      type: Number
    }
  },
  data() {
    return {
      questions: this.$store.state.eligibility.questions.filter((q) => !q.user_answer),
      current: 0,
      answered: 0,
    };
  },
  created() {
    if (!this.$store.state.eligibility.config.show_questions || !this.questions.length) return this.$emit("done");
    if (!this.$store.state.eligibility.timer_start) return this.$emit('done');
  },
  methods: {
    chooseAnswer(answer) {
      this.questions[this.current].user_answer = answer[0];
      const questions = this.$store.state.eligibility.questions.map((q) => {
        const exist = this.questions.find((q2) => q2.id === q.id);
        return exist || q;
      });
      this.answered++;
      this.$store.commit("eligibility/patch", { questions })
      if (this.max && this.answered === this.max) {
        return this.$emit('done');
      }
      if (!this.max && !this.$parent.loading) return this.$emit("done");
      if (this.current < this.questions.length - 1) this.current++;
      else {
        this.saveQuestions();
        this.$emit("done");
      }
    },
    async saveQuestions() {
      try {
        const { token, questions } = this.$store.state.eligibility;
        await this.$http.post('/customer/save/randomquestions', { token, questions });
      } catch (e) {
        console.log({ e });
      }
    },
  },
};
</script>
