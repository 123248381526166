<template>
  <div class="my-auto px-6 lg:px-12 pb-8">
    <SectionHeader title="Upload attachments"/>
    <div class="space-y-4">
      <div
        v-for="type in attachments" :key="type.value"
        class="border bg-white rounded-lg px-5 py-4 flex justify-between items-center"
      >
        {{ type.display }}
        <input type="file" hidden :id="`file-${type.value}`" @change="handleFileChange($event, type)"/>
        <i v-if="type.uploaded" class="fa fa-check-circle text-green-600"></i>
        <template v-else>
          <Spinner v-if="type.uploading"/>
          <label
            v-else :for="`file-${type.value}`"
            class="px-3 py-1 rounded-lg border hover:bg-gray-100 cursor-pointer flex text-md"
          >
            <i class="fa fa-upload text-sm text-gray-600 mr-3"></i> Upload
          </label>
        </template>
      </div>
    </div>

    <AppButton @click="next()" :disabled="!valid" class="mt-12">Continue</AppButton>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import Spinner from "@/components/global/Spinner";
import AppButton from "@/components/global/Button";

export default {
  name: "Attachments",
  components: { AppButton, Spinner, SectionHeader },
  computed: {
    valid() {
      return this.attachments.every(t => t.uploaded);
    }
  },
  data() {
    return {
      attachments: [
        { display: "Document  1", value: "1001", },
        { display: "Document  2", value: "1002", },
      ],
    }
  },
  created() {
    const { attachments, config: { show_attachments, attachments_list = [] } } = this.$store.state.eligibility || {};
    if (!show_attachments) return this.$emit('skip');
    if (attachments) this.attachments = attachments;
    else if (attachments_list.length) {
      this.attachments = attachments_list.map((a, i) => ({ display: a, value: i }))
    } else {
      this.attachments = [
        { display: "Document  1", value: "1001", },
        { display: "Document  2", value: "1002", },
      ]
    }
  },
  methods: {
    handleFileChange(e, type) {
      const [file] = e.target.files;
      this.upload(file, type);
    },
    async upload(file, type) {
      try {
        this.setUploading(type, true);
        const fd = new FormData();
        fd.append('file', file);
        fd.append('doctype_', type.display);
        const res = await this.$http.post('https://mobile.creditclan.com/api/v3/file/upload', fd);
        const { attachment_id } = res.data?.data ?? {};
        const file_name = res.data.fullpath;
        this.setUploaded(type, { attachment_id, file_name });
      } catch (e) {
        console.log({ e });
      }
      this.setUploading(type, false);
    },
    setUploading(type, value) {
      this.attachments = this.attachments.map(t => {
        if (t.value === type.value) return { ...t, uploading: !!value };
        return t;
      });
    },
    setUploaded(type, data) {
      this.attachments = this.attachments.map(t => {
        if (t.value === type.value) return { ...t, uploaded: true, ...data, };
        return t;
      });
    },
    next() {
      if (!this.valid) return;
      this.$store.commit('eligibility/patch', { attachments: this.attachments });
      this.$emit('done');
    }
  }
}
</script>
