<template>
  <div class="h-full flex flex-col px-6 pt-6 lg:pt-10 lg:px-12">
    <Loader v-if="loading" :text="loading" class="my-auto"/>
    <div v-if="!loading" class="text-center mx-auto flex flex-col items-center my-auto">
      <div class="w-24 h-24 rounded-full bg-green-600 flex items-center justify-center">
        <i class="fa fa-check text-4xl text-white"></i>
      </div>
      <div class="px-5 mt-10 text-center">
        <p class="font-semibold text-lg">Bank statement sent</p>
        <p class="text-gray-500 mt-3">
          You have successfully sent your bank statement to {{ $store.state.statement.payload.name || 'our partners' }}
        </p>
        <div v-if="source === 'whatsapp'" class="flex justify-center">
          <AppButton @click="backToWhatsapp()" class="mt-8">
            <i class="fab fa-whatsapp mr-2"></i>
            Back to Whatsapp
          </AppButton>
        </div>
        <div v-if="inIframe" class="flex flex-col items-center mt-10">
          <button @click="close()" class="px-3 py-1 border border-red-500 text-red-500 rounded-lg">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import AppButton from "@/components/global/Button.vue";
import ajax from "@/mixins/ajax.js";
import Loader from "@/components/global/Loader.vue";

export default {
  name: 'Success',
  components: { Loader, AppButton },
  mixins: [ajax],
  data() {
    return {
      inIframe: false,
      source: this.$store.state.statement.payload.source,
      response: null
    };
  },
  created() {
    this.inIframe = window.self !== window.top;
    this.sendEvent();
  },
  methods: {
    sendEvent() {
      parent.postMessage({ id: 'cclan-completed' }, '*');
    },
    close() {
      parent.postMessage({ id: 'cclan-close' }, '*');
    },
    backToWhatsapp() {
      window.open('https://wa.me/+2349053535333');
    },
  }
}
</script>
