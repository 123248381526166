var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-6 lg:px-12 pb-8"},[_c('SectionHeader',{attrs:{"title":"Finally, provide some basic details","subtitle":"Please fill in all fields correctly"}}),_c('form',[_c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"flex flex-col space-y-4"},[_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppTextArea',{attrs:{"label":"Home/Street address","errors":errors},model:{value:(_vm.values.address),callback:function ($$v) {_vm.$set(_vm.values, "address", $$v)},expression:"values.address"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{attrs:{"label":"State of residence","errors":errors},model:{value:(_vm.values.state_id),callback:function ($$v) {_vm.$set(_vm.values, "state_id", $$v)},expression:"values.state_id"}},[_c('option',{attrs:{"value":""}},[_vm._v("State")]),(!_vm.states.length)?_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Loading")]):_vm._e(),_vm._l((_vm.states),function(state){return _c('option',{key:state.value,domProps:{"value":state.value}},[_vm._v(" "+_vm._s(state.text)+" ")])})],2)]}}])}),_c('ValidationProvider',{attrs:{"name":"occupation_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{staticClass:"w-full",attrs:{"label":"Employment status","errors":errors},model:{value:(_vm.values.occupation_id),callback:function ($$v) {_vm.$set(_vm.values, "occupation_id", $$v)},expression:"values.occupation_id"}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v("Select employment status")]),_vm._l((_vm.occupations),function(status){return _c('option',{key:status.value,domProps:{"value":status.value}},[_vm._v(" "+_vm._s(status.text)+" ")])})],2)]}}])}),(_vm.values.occupation_id === '1' || _vm.values.occupation_id === '2' || _vm.values.occupation_id === '4' || _vm.values.occupation_id === '5')?[_c('ValidationProvider',{attrs:{"name":"Place of work","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{staticClass:"w-full",attrs:{"label":"Place of work","type":"text","errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.company_name),callback:function ($$v) {_vm.$set(_vm.values, "company_name", $$v)},expression:"values.company_name"}})]}}],null,false,1797967505)}),_c('ValidationProvider',{attrs:{"name":"Address of work","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{staticClass:"w-full",attrs:{"label":"Address of work","type":"text","errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.work_address),callback:function ($$v) {_vm.$set(_vm.values, "work_address", $$v)},expression:"values.work_address"}})]}}],null,false,1725978539)})]:_vm._e()],2),_c('div',{staticClass:"flex mt-10"},[_c('AppButton',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Next")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }