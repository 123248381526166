<template>
  <div class="px-6 lg:px-12 pb-8">
    <div v-if="loading">
      <Loader :text="loading"/>
    </div>
    <div v-else>
      <template v-if="view === 'initial'">
        <SectionHeader
          title="Email not verified"
          subtitle="You need to verify your email address before proceeding, click the button below to receive an otp code"
        />
        <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
        <AppButton @click="sendOtpCode()">Get otp code</AppButton>
      </template>
      <template v-if="view === 'otp'">
        <SectionHeader
          title="Enter OTP code"
          :subtitle="`An OTP code has been sent to ${$store.state.eligibility.profile.email}, enter code below to confirm your email address`"
        />
        <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
        <OtpCodeInput @done="handleOtpCodeInputDone($event)"/>
        <div class="flex items-center mt-10 space-x-4">
          <AppButton @click="handleOtpCodeInputDone()">Verify email</AppButton>
          <Countdown ref="countdown" :from="60" v-slot="{ current }">
            <div v-if="current">
              Resend OTP code in {{ current }}
            </div>
            <AppButton v-else @click="sendOtpCode()" variant="outline" size="sm">
              <i class="fa fa-redo-alt mr-2"/>
              Resend
            </AppButton>
          </Countdown>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppButton from "@/components/global/Button.vue";
import ajax from "@/mixins/ajax.js";
import OtpCodeInput from "@/components/global/OtpCodeInput.vue";
import Loader from "@/components/global/Loader.vue";
import Message from "@/components/global/Message.vue";
import Countdown from "@/components/global/Countdown.vue";

export default {
  name: "VerifyEmail",
  components: { Countdown, Message, Loader, OtpCodeInput, AppButton, SectionHeader },
  mixins: [ajax],
  data() {
    return {
      view: 'initial'
    }
  },
  created() {
    const { email_verified, config } = this.$store.state.eligibility;
    if (email_verified || !config.verify_email) return this.$emit('skip');
  },
  methods: {
    async sendOtpCode() {
      this.setLoading('Sending otp code', true);
      try {
        const { token } = this.$store.state.eligibility;
        const res = await this.$http.post('https://mobile.creditclan.com/api/v3/customer/validate_customer_email', { token });
        if (!res.data.status) return this.setError(res.data?.message || 'An error occurred, please try again', true);
        this.view = 'otp';
      } catch (e) {
        console.log(e);
        this.setError(e?.response?.data?.message || 'An error occurred, please try again', true);
      }
      this.setLoading('');
      this.$refs?.countdown?.reset();
    },
    async handleOtpCodeInputDone(otp) {
      this.setLoading('Confirming email address');
      try {
        const { token } = this.$store.state.eligibility;
        const res = await this.$http.post('https://mobile.creditclan.com/api/v3/customer/validate_customer_email_otp', {
          token, otp
        });
        if (!res.data.status) return this.setError(res.data?.message || 'An error occurred, please try again', true);
        this.$store.commit('eligibility/patch', { email_verified: true });
        this.next();
      } catch (e) {
        console.log(e);
        this.setError(e?.response?.data?.message || 'An error occurred, please try again', true);
      }
      this.setLoading('');
    },
    next() {
      if (!this.$store.state.eligibility.email_verified) return;
      this.$emit('skip');
    }
  }
}
</script>
