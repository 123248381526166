<template>
  <div class="h-full flex flex-col">
    <div v-if="!new_customer" class="px-6 lg:px-12">
      <div class="mb-10">
        <Avatar @done="next()"/>
      </div>
      <SectionHeader
        title="Update your picture"
        :subtitle="`${ $store.state.eligibility.profile.full_name.split(' ')[0] || 'Hi' }, let's quickly get you a offer, kindly help take a quick picture`"
        spacing="2" mb="0"
      />
      <div v-if="!!$store.state.eligibility.profile.profile_image" class="flex mt-12">
        <AppButton @click="next()">Continue with picture</AppButton>
      </div>
    </div>
    <div v-else class="px-6 lg:px-12">
      <SectionHeader
        title="Upload your picture"
        subtitle="Kindly take a selfie (we'd love your smile too)"
        spacing="2" mb="0"
      />
      <div class="mt-12">
        <Avatar @done="next()"/>
      </div>
      <div v-if="!!$store.state.eligibility.profile.profile_image" class="flex mt-12">
        <AppButton @click="next()">Continue</AppButton>
      </div>
    </div>
    <div class="flex justify-end mt-auto md:hidden">
      <img src="@/assets/images/selfie.svg" alt="" class="w-40"/>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppButton from "@/components/global/Button.vue";
import Avatar from "@/components/core/home/sections/Avatar.vue";

export default {
  name: 'Picture',
  components: { Avatar, AppButton, SectionHeader },
  computed: {
    new_customer() {
      return this.$store.state.eligibility.new_customer;
    },
  },
  created() {
    const { config: { show_picture }, profile } = this.$store.state.eligibility;
    const { oneMonth } = this.$store.getters['eligibility/lastApplied'];
    if (!show_picture || (oneMonth && profile.profile_image)) return this.$emit('skip');
  },
  methods: {
    next() {
      if (!this.$store.state.eligibility.profile.profile_image) return;
      this.$emit('done');
    },
  }
};
</script>
