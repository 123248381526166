import { render, staticRenderFns } from "./ConfirmFrequentlyCalledNumbers.vue?vue&type=template&id=5d997f5a"
import script from "./ConfirmFrequentlyCalledNumbers.vue?vue&type=script&lang=js"
export * from "./ConfirmFrequentlyCalledNumbers.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports