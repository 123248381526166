<template>
  <div class="fill-height overflow-hidden md:p-4 lg:p-12 bg-gray-900 bg-opacity-70">
    <div class="grid grid-cols-12 overflow-hidden h-full max-w-7xl mx-auto md:rounded-lg lg:rounded-xl">
      <div
        class="hidden md:flex lg:flex md:col-span-3 lg:col-span-6 bg-blue-600 bg-center bg-white bg-cover"
        :style="{
          background: `linear-gradient(to right, rgb(58 70 168 / 60%), rgb(94 80 151 / 60%)), url(${banner}) center no-repeat`,
          backgroundSize: 'cover',
          backgroundColor: '#fff'
        }"
      />
      <div class="col-span-12 md:col-span-9 lg:col-span-6 flex flex-col bg-white relative overflow-hidden">
        <img
          src="@/assets/images/pattern-up.png" alt="background pattern"
          class="absolute top-0 left-0 hidden md:block" style="width: 300px;"
        />
        <img
          src="@/assets/images/pattern-down.png" alt="background pattern"
          class="absolute bottom-0 right-0 hidden md:block" style="width: 300px"
        />
        <div class="h-full overflow-y-auto flex flex-col z-50">
          <Loader
            v-if="cancelling"
            text="Cleaning up.."
            class="px-6 lg:px-12 pb-8 my-auto"
          />
          <template v-else>
            <slot></slot>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader.vue";

export default {
  name: "Layout",
  components: { Loader },
  props: {
    banner: {
      type: String,
      default: 'https://images.unsplash.com/photo-1572666341285-c8cb9790ca50?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
    }
  },
  data() {
    return {
      cancelling: false,
    }
  },
  beforeDestroy() {
    this.checkRequest();
  },
  created() {
    this.$bus.$on('cancel', () => this.cancel());
  },
  methods: {
    async cancel() {
      await this.checkRequest();
      parent.postMessage({ id: 'cclan-cancel' }, "*");
    },
    async checkRequest() {
      const { config: { platform, return_offer }, request_id, offer } = this.$store.state.eligibility;
      if ((return_offer || platform === 'instant') && request_id && !offer?.amount) {
        this.cancelling = true;
        try {
          await this.$store.dispatch('eligibility/cancelRequest');
        } catch (e) {
          console.log(e);
        }
        this.cancelling = false;
      }
    }
  },
}
</script>
