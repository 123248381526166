<template>
  <div class="px-6 lg:px-12 pb-8">
    <SectionHeader
      title="Let's know about you"
      subtitle="Please fill in all fields correctly"
    />
    <form>
      <ValidationObserver ref="observer">
        <div class="flex flex-col space-y-4">
          <AppInput
            v-if="$store.state.eligibility.profile.full_name" :value="values.full_name" type="text" label="Full name"
            class="w-full" disabled
          />
          <ValidationProvider v-else name="Full name" rules="required" v-slot="{ errors }">
            <AppInput
              v-model="values.full_name" type="text" label="Full name" :errors="errors" class="w-full"
              :disabled="loading"
            />
          </ValidationProvider>
          <AppInput :value="values.email" type="email" label="Email address" class="w-full" disabled/>
          <AppInput :value="values.phone" label="Phone number" type="text" class="w-full" disabled/>
          <ValidationProvider name="Date of birth" rules="required" v-slot="{ errors }">
            <AppInput
              label="Date of birth"
              v-model="values.date_of_birth" type="date" :errors="errors" class="w-full" :disabled="loading"
            />
          </ValidationProvider>
          <ValidationProvider name="Gender" rules="required" v-slot="{ errors }">
            <AppSelect v-model="values.gender" name="gender" label="Gender" :errors="errors">
              <option value="0">Male</option>
              <option value="1">Female</option>
            </AppSelect>
          </ValidationProvider>
          <ValidationProvider name="Marital status" rules="required" v-slot="{ errors }">
            <AppSelect v-model="values.marital_status" name="marital_status" label="Marital status" :errors="errors">
              <option v-for="option of maritalStatusOptions" :key="option.value" :value="option.value">
                {{ option.text }}
              </option>
            </AppSelect>
          </ValidationProvider>
        </div>
        <div class="flex mt-10">
          <AppButton @click="submit()" :loading="loading">Next</AppButton>
        </div>
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppSelect from "@/components/global/Select.vue";
import AppInput from "@/components/global/Input.vue";
import AppButton from "@/components/global/Button.vue";
import { maritalStatusOptions } from "@/lib/options.js";

export default {
  name: "Profile",
  components: { AppButton, AppInput, AppSelect, SectionHeader },
  data() {
    return {
      loading: false,
      values: {
        full_name: '',
        email: '',
        phone: '',
        date_of_birth: '',
        gender: '',
        marital_status: '',
      },
      maritalStatusOptions,
    }
  },
  created() {
    const { config: { show_profile }, profile } = this.$store.state.eligibility;
    const { oneMonth } = this.$store.getters['eligibility/lastApplied'];
    if (!show_profile || (oneMonth && profile.date_of_birth)) return this.$emit('skip');
    this.values = { ...profile };
    Object.keys(this.values).forEach(key => {
      if (key.toLowerCase() !== 'gender' && this.values[key] === '0') {
        this.values[key] = null;
      }
    })
  },
  methods: {
    async submit() {
      if (!(await this.$refs.observer.validate())) return;
      this.$store.commit("eligibility/patch", {
        profile: {
          ...this.$store.state.eligibility.profile,
          ...this.values
        }
      });
      this.$emit("done");
    }
  }
}
</script>
