<template>
  <div class="my-auto px-6 lg:px-12">
    <template v-if="offers.length">
      <SectionHeader
        title="Congratulations"
        subtitle="You are eligible for the following offers, select one below to continue"
      />
      <swiper ref="mySwiper" :options="swiperOptions">
        <template v-for="(offer, i) in offers">
          <swiper-slide :key="i">
            <div
              @click="selectOffer(offer)"
              class="rounded-lg p-6 cursor-pointer h-72 flex flex-col border shadow-md"
              :class="colors[i] || 'bg-gray-50 border-gray-100'"
            >
              <p class="text-gray-600">Get</p>
              <div class="text-xl font-semibold">
                {{ offer.loan_amount | currency }} <small class="text-gray-600">now</small>
              </div>
              <div v-if="offer.duration === 1" class="text-gray-700">
                and pay back {{ offer.monthly_repayment | currency }} next month
              </div>
              <div v-else class="text-gray-700">
                and pay back {{ offer.monthly_repayment | currency }}/mo for {{ offer.duration }} months
              </div>
              <div class="flex justify-end items-center mt-auto text-gray-600">
                <i class="fa fa-user mr-2"></i> {{ lender ? lender.name : offer.lender }}
              </div>
            </div>
          </swiper-slide>
        </template>
        <swiper-slide>
          <div
            @click="handleModifyOffer()"
            class="rounded-lg p-6 cursor-pointer h-72 flex flex-col justify-center items-center border bg-gray-100 border-gray-200 text-center"
          >
            <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
              <i class="fa fa-pen"></i>
            </div>
            <div class="text-xl font-semibold mt-6">Modify offer</div>
            <div class="text-gray-700 mt-4">
              Tap to change offer upfront amount and repayment duration
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <Modal v-if="modal === 'summary' && selectedOffer">
        <div class="my-auto">
          <SectionHeader
            title="Confirm offer"
            :subtitle="`I agree that I will get ${formatCurrency(selectedOffer.loan_amount)} now and pay back ${formatCurrency(selectedOffer.monthly_repayment)} per month for ${selectedOffer.duration} month(s)`"
          />
          <div class="flex mt-6">
            <AppButton @click="$emit('accept', selectedOffer)" :loading="accepting">Accept</AppButton>
            <button @click="selectedOffer = null" class="ml-2 text-red-500 py-1 px-3">Cancel</button>
          </div>
        </div>
        <div class="flex justify-end mt-auto md:hidden">
          <img src="@/assets/images/approve.svg" alt="" class="w-56"/>
        </div>
      </Modal>

      <Modal v-if="modal === 'modify'">
        <div class="my-auto">
          <SectionHeader
            title="Modify offer"
            subtitle="Adjust upfront payment and repayment duration"
          />
          <div class="border bg-orange-50 bg-opacity-50 px-5 py-4 rounded-lg">
            <label for="large-range" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Loan amount - <span class="font-semibold">{{ values.amount | currency }}</span>
            </label>
            <div class="flex justify-between items-center text-sm">
              <span>{{ values.min_amount | currency }}</span>
              <span>{{ values.max_amount | currency }}</span>
            </div>
            <input
              v-model="values.amount"
              :min="values.min_amount" :max="values.max_amount" step="1000"
              id="large-range" type="range" value="50"
              class="w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg dark:bg-gray-700"
            >
          </div>
          <div class="border bg-orange-50 bg-opacity-50 px-5 py-4 rounded-lg mt-4">
            <label for="large-range" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Duration - <span class="font-semibold">{{ values.duration }} month(s)</span>
            </label>
            <div class="flex justify-between items-center text-sm">
              <span>{{ values.min_duration }} month(s)</span>
              <span>{{ values.max_duration }} month(s)</span>
            </div>
            <input
              v-model="values.duration"
              :min="values.min_duration" :max="values.max_duration"
              id="large-range" type="range" value="50"
              class="w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg dark:bg-gray-700"
            >
          </div>
          <div class="rounded-lg p-6 cursor-pointer flex flex-col border bg-teal-50 bg-opacity-50 mt-4">
            <p class="text-gray-600">Get</p>
            <div class="text-xl font-semibold">
              {{ values.amount | currency }} <small class="text-gray-600">now</small>
            </div>
            <div v-if="values.duration === 1" class="text-gray-700">
              and pay back {{ values.monthly_repayment | currency }} next month
            </div>
            <div v-else class="text-gray-700">
              and pay back {{ values.monthly_repayment | currency }}/mo for {{ values.duration }} months
            </div>
          </div>
          <div class="flex mt-8">
            <AppButton @click="acceptModifiedOffer()" :loading="accepting">Accept</AppButton>
            <button @click="modal = ''" class="ml-2 text-red-500 py-1 px-3">Cancel</button>
          </div>
        </div>
      </Modal>
    </template>
    <template v-else>
      <div class="text-center max-w-xs mx-auto flex flex-col items-center justify-center my-auto">
        <i class="fa fa-hourglass-half fa-5x text-orange-300"></i>
        <p class="text-gray-600 font-semibold text-lg mt-12">Your request is under review</p>
        <p class="text-gray-500 mt-3">
          Your request has been sent for review, one of our agents will reach out to you soon.
        </p>
        <div class="mt-7 flex">
          <AppButton
            @click="handleClose()" :loading="cancelling" variant="outline-danger" size="sm"
            class="rounded-full"
          >
            Close
          </AppButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Modal from "@/components/global/Modal.vue";
import AppButton from "@/components/global/Button.vue";
import { formatCurrency, roundToNearestTen } from "@/lib/utils.js";
import SectionHeader from "@/components/global/SectionHeader.vue";

export default {
  name: "InstantOffers",
  components: { SectionHeader, AppButton, Modal },
  props: {
    offer: {
      type: Object,
      required: true
    },
    accepting: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      offers: [],
      selectedOffer: null,
      swiperOptions: {
        slidesPerView: 1.5,
        spaceBetween: 30,
      },
      payment: { amount: 0, upfront: false },
      formatCurrency,
      lender: this.$store.state.eligibility.lender,
      colors: ['bg-orange-50 border-orange-100', 'bg-teal-50 border-teal-100', 'bg-red-50 border-red-100', 'bg-blue-50 border-blue-100'],
      modal: '',
      values: {
        duration: 0,
        amount: 0,
        min_duration: 0,
        max_duration: 0,
        min_amount: 0,
        max_amount: 0,
        monthly_repayment: 0,
        loan_amount: 0,
        interest: 0,
        total_repayment: 0,
      },
      cancelling: false,
    }
  },
  async created() {
    this.offers = this.generateOffers(this.offer);
    this.$watch('values.amount', this.handleValuesChange);
    this.$watch('values.duration', this.handleValuesChange);
  },
  methods: {
    generateOffers(offer) {
      if (!offer.amount) return [];
      const { duration, total_repayment, amount, interest, monthly_repayment } = offer;
      return [{
        loan_amount: amount,
        repayment_amount: total_repayment,
        duration,
        monthly_repayment,
        upfront: 0,
        tenor_type: 2,
        interest
      }];
    },
    selectOffer(offer) {
      this.selectedOffer = { ...offer };
      this.modal = 'summary';
    },
    handleModifyOffer() {
      this.modal = 'modify';
      const { duration, amount, interest, total_repayment, monthly_repayment } = this.offer;
      this.values = {
        amount,
        loan_amount: amount,
        duration,
        upfront: 0,
        min_amount: 1000,
        max_amount: amount,
        min_duration: 1,
        max_duration: duration,
        monthly_repayment,
        interest,
        total_repayment,
      };
      this.handleValuesChange();
    },
    handleValuesChange() {
      const { amount, duration } = this.values;
      const { interest } = this.offer;
      const total_repayment = roundToNearestTen(+amount + (((interest / 100) * +amount) * +duration));
      const monthly_repayment = roundToNearestTen(total_repayment / +duration);
      this.values = {
        ...this.values,
        upfront: 0,
        duration: +duration,
        loan_amount: total_repayment,
        interest,
        monthly_repayment,
        total_repayment,
      };
    },
    acceptModifiedOffer() {
      const { duration, upfront, monthly_repayment, total_repayment: repayment_amount, interest } = this.values;
      const offer = {
        upfront,
        duration,
        loan_amount: repayment_amount,
        tenor_type: 2,
        repayment_amount,
        monthly_repayment,
        interest,
      }
      this.selectedOffer = { ...offer };
      this.modal = 'summary';
    },
    async handleClose() {
      this.cancelling = true;
      parent.postMessage({ id: 'cclan-close' }, '*');
      this.cancelling = false;
    },
  },
}
</script>
