<template>
  <Layout banner="https://images.pexels.com/photos/3727465/pexels-photo-3727465.jpeg?auto=compress&cs=tinysrgb&w=600">
    <div v-if="loading" class="px-6 pt-6 lg:pt-10 lg:px-12 my-auto">
      <Loader
        v-if="loading === 'init'" variant="skateboard"
        :texts="['Just a moment..', 'Setting things up..']" :timeout="2000"
      />
      <Loader v-else variant="skateboard" :text="loading"/>
    </div>
    <div v-else-if="error" class="px-6 pt-6 lg:pt-10 lg:px-12 my-auto">
      <Error :text="error" show-retry/>
    </div>
    <div class="h-full flex flex-col md:h-auto md:my-auto" v-else>
      <br>
      <Intro
        v-if="view === 'intro'"
        @done="pushView('bank-statement')"
      />
      <BankStatement
        v-if="view === 'bank-statement'"
        @done="pushView('success')"
      />
      <Success v-if="view === 'success'"/>
    </div>
  </Layout>
</template>

<script>
import { decodeToken } from "@/lib/utils.js";
import ajax from "@/mixins/ajax.js";
import view from "@/mixins/view.js";
import Loader from "@/components/global/Loader.vue";
import Error from "@/components/global/Error.vue";
import Layout from "@/components/global/Layout.vue";
import Intro from "@/components/core/statement/Intro.vue";
import BankStatement from "@/components/core/statement/BankStatement.vue";
import Success from "@/components/core/statement/Success.vue";

export default {
  name: "Statement",
  components: { Success, BankStatement, Intro, Layout, Loader, Error },
  mixins: [ajax, view],
  created() {
    this.setup();
  },
  methods: {
    setup() {
      const { token } = this.$route.query;
      if (!token) return this.setError('Something went wrong');
      this.decrypt(token);
      this.$http.interceptors.request.use(config => {
        config.headers['x-api-key'] = process.env.VUE_APP_API_KEY;
        return config;
      }, error => {
        return Promise.reject(error);
      });
    },
    async decrypt(bs_token) {
      this.setLoading('init');
      const company = await decodeToken(bs_token);
      try {
        const res = await this.$http.get('https://mobile.creditclan.com/api/v3/mbs/lender/settings');
        this.$store.commit("statement/patch", { payload: { ...company, ...res.data }, bs_token });
        await this.getBanks();
        this.pushView('intro');
      } catch (e) {
        this.setError(e?.response?.data?.message || 'An error occurred, please check your internet connection and try again')
      }
      this.setLoading('');
    },
    async getBanks() {
      if (this.$store.state.statement.banks_mono) return;
      const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
      const banks_mono = res.data.data;
      this.$store.commit('statement/patch', { banks_mono })
    }
  }
}
</script>
