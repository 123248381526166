import { addMinutes, differenceInDays } from "date-fns";
import http from "@/plugins/http.js";
import axios from "axios";
import banks from "@/lib/banks.js";
import { roundToNearestTen } from "@/lib/utils.js";

const state = {
  intro: '',
  intro_action: '',
  referral_code: null,
  request: {
    amount: 0,
    duration: 0,
    tenor_type: 2
  },
  profile: {
    user_id: null,
    full_name: '',
    email: '',
    phone: '',
    profile_image: '',
    date_of_birth: '',
    marital_status: '',
  },
  bvn: '',
  next_of_kin: {
    full_name: '',
    email: '',
    phone: '',
    address: '',
    relationship: '',
  },
  account: {
    bvn: null,
    account_name: '',
    account_number: '',
    bank_id: '',
    bank_code: '',
    okra_id: null,
  },
  accounts: [],
  home_address: {
    address: '',
    state_id: '',
    lga_id: '',
    home_landmark: '',
  },
  work: {
    monthly_income: '',
    work_sector: '',
    occupation_id: '',
    address: '',
    start_month: '',
    start_year: '',
    company_name: '',
    official_email: '',
    work_email_verified: '',
    work_state_id: '',
    work_lga_id: '',
  },
  identity: {
    nin: ''
  },
  frequently_called_numbers: [{ name: '', phone: '' }, { name: '', phone: '' }],
  social: {
    facebook: '',
    instagram: '',
  },
  has_data: false,
  questions: [],
  request_id: null,
  new_customer: false,
  token: null,
  offer: null,
  stage: '',
  bs: {
    has_online_banking: 0,
    response: null
  },
  config: {
    no_frequently_called_numbers: 2,
    analyze_bank_statement: true,
    tokenize_card: true,
    show_offers: true,
    show_questions: true,
    show_bank_account: true,
    match_account_with_bvn: false,
    show_work_information: false,
    verify_email: false,
    show_nin: true,
    scan_whatsapp: false,
    show_signature: false,
    platform: null,
    bs_name_match: false,
    account_name_match: false,
    bvn_name_match: false,
    recommended_upfront_cap: false,
    always_show_offers: false,
    show_address: false,
    show_profile: false,
    first_month_upfront: false,
    single_offer: false,
    can_change_repayment_frequency: false,
    show_income: false,
    show_nok: false,
    modify_offer_beyond_limits: false,
    can_modify_offer: false,
    verify_work_email: false,
    show_picture: false,
    return_offer: false,
    show_multiple_accounts: false,
    show_attachments: false,
    attachments_list: [],
    show_basic_details: false,
    validate_bvn: false,
    validate_rc_number: false,
    specify_account_type: false,
    remember_last_application_date: true,
    remember_last_offer: true,
  },
  extra: {},
  last_application_date: null,
  last_offer: null,
  timer_end: addMinutes(new Date(Date.now()), 5),
  timer_start: new Date(Date.now()),
  email_verified: false,
  lender: null,
  whatsapp_id: null,
  banks_mono: null,
  payment: null,
  documents: [],
  plans: null,
  bvn_data: null,
  attachments: null,
  api_key: null,
};

const getters = {
  bankName: state => {
    return state.banks_mono?.find(bank => bank.id === state.account.bank_id)?.name || ''
  },
  lastApplied: state => {
    if (!state.last_application_date || !state.config.remember_last_application_date) return {
      recently: false,
      twoWeeks: false,
      oneMonth: false,
      twoMonths: false,
      threeMonths: false,
    };
    const date = new Date(state.last_application_date);
    const today = new Date(Date.now());
    const difference = differenceInDays(today, date);
    return {
      recently: difference <= 2,
      twoWeeks: difference <= 14,
      oneMonth: difference <= 30,
      twoMonths: difference <= 60,
      threeMonths: difference <= 90,
    };
  }
};

const mutations = {
  patch: (state, data) => {
    Object.keys(data).forEach(key => {
      state[key] = data[key];
    });
  },
};

const actions = {
  async postData({ state }, data) {
    const payload = { token: state.token, amount: state.request.amount, };
    if (data.type === 'phone') payload.phone = state.profile.phone;
    if (data.type === 'nin') payload.nin = state.identity.nin;
    if (data.type === 'bvn') payload.bvn = state.account.bvn;
    const res = await http.post('/customer/getdata_market', { ...payload, ...data });
    return res.data;
  },
  async updateStage({ state, commit }, payload) {
    const { token, request_id, config: { platform }, extra } = state;
    await http.post('https://mobile.creditclan.com/api/v3/request/update/stage', {
      token, request_id, ...payload
    });
    await http.post('https://mobile.creditclan.com/api/v3/request/update/global', {
      request_id, ...payload
    });
    try {
      if (platform === 'shop-merchant' || platform === 'shop-power') {
        await axios.post(`https://sellbackend.creditclan.com/merchantclan/public/index.php/api/request/${ extra.power_merchant_request_id || extra.merchant_request_id }/stages`, {
          stage: payload.stage,
        });
      }
    } catch (e) {
      console.log(e);
    }
    commit('patch', { stage: payload.stage });
  },
  async getUserDetails({ state, commit }) {
    const res = await http.post('/user/detailsbyid', { token: state.token });
    const {
      profile: { phone, legal_name: full_name, email, date_of_birth, gender, marital_status, file_name = '' },
      home_address: { home_address, home_state, home_lga, home_landmark },
      bvn: { bvn },
      work: {
        net_monthly_income,
        company_name,
        work_sector,
        start_month,
        start_year,
        occupation_id,
        work_address,
        official_email,
        work_email_verified,
        work_state_id,
        work_lga_id,
      },
      next_of_kin: { nok_name, nok_email, nok_phone, nok_address, nok_relationship },
      accounts
    } = res.data.data.userData.data;
    let profile_image = state.profile.profile_image || file_name;
    if (profile_image === 'https://res.cloudinary.com/du1mqyww8/image/upload/v1617623098/wnwad3hebiais8ulr5yy.png') profile_image = null;
    if (profile_image === 'https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg') profile_image = null;
    const { has_data, last_application_date, frequently_called_numbers: fcn, data, whatsapp_id, last_offer } = res.data;
    const frequently_called_numbers = fcn || [{ phone: '' }, { phone: '' }];
    commit('patch', {
      profile: {
        ...state.profile,
        phone,
        full_name,
        email: email?.toLowerCase().trim(),
        date_of_birth,
        gender,
        marital_status,
        profile_image
      },
      bvn,
      work: {
        monthly_income: net_monthly_income,
        company_name,
        work_sector,
        start_month,
        start_year,
        occupation_id,
        address: work_address,
        official_email,
        work_email_verified,
        work_state_id,
        work_lga_id,
        ...state.work,
      },
      next_of_kin: {
        full_name: nok_name,
        email: nok_email,
        phone: nok_phone,
        address: nok_address,
        relationship: nok_relationship
      },
      has_data,
      last_application_date,
      last_offer: last_offer ? last_offer.map(i => ({
        ...i,
        upfront: +`${ i.upfront }`.replace(/,/gi, ''),
        amount: `${ i.amount }`.replace(/,/gi, ''),
        monthly_repayment: `${ i.monthly_repayment }`.replace(/,/gi, ''),
        total_repayment: `${ i.total_repayment }`.replace(/,/gi, ''),
        effective_income: `${ i.effective_income }`.replace(/,/gi, ''),
        real_income: `${ i.real_income }`.replace(/,/gi, ''),
      })) : null,
      frequently_called_numbers,
      email_verified: data.validations.email,
      home_address: {
        address: `${ state.home_address.address || home_address }`,
        state_id: `${ state.home_address.state_id || home_state }`,
        lga_id: `${ state.home_address.lga_id || home_lga }`,
        home_landmark,
      },
      whatsapp_id,
    });
    if ((accounts || []).length === 0) commit('patch', { new_customer: true });
    else {
      const _accounts = accounts
        ?.filter(a => (!!a.bank_id && !!a.last_four_digits && !!a.bank_name))
        .map(a => ({
          bank_id: a.bank_id,
          bank_code: banks.find(b => b.id === a.bank_id)?.bank_code,
          account_name: a.card_name,
          account_number: a.last_four_digits,
          account_card_id: a.account_card_id,
          okra_id: a.okra_id,
          bank_name: a.bank_name
        })).reduce((acc, a) => {
          if (acc.some(_a => _a.account_number === a.account_number)) return acc;
          else return [...acc, a];
        }, []) || [];
      commit('patch', {
        accounts: _accounts,
        account: { ...[..._accounts].pop(), bvn },
      });
    }
  },
  async getCreditclanRequestId({ state, commit }) {
    const { config: { platform }, extra, request_id } = state;
    let creditclan_request_id = request_id;
    if (platform === 'shop-power') {
      const res = await axios.get(`https://sellbackend.creditclan.com/merchantclanbackend/public/index.php/api/request_only/${ extra.power_merchant_request_id }`, {
        params: { active_only: true }
      });
      ({ creditclan_request_id } = res.data.data);
    }
    if (platform === 'shop-merchant') {
      const res = await axios.get(`https://sellbackend.creditclan.com/merchantclanbackend/public/index.php/api/request_only/${ extra.merchant_request_id }`, {
        params: { active_only: true }
      });
      ({ creditclan_request_id } = res.data.data);
    }
    if (platform === 'shop-global') {
      const res = await axios.get('https://sellbackend.creditclan.com/merchantclan/public/index.php/api/request/check', {
        params: { global_request_id: extra.global_request_id }
      });
      ({ creditclan_request_id } = res.data);
    }
    if (platform === 'rent-tenant') {
      const res = await axios.post('https://wema.creditclan.com/api/v3/rent/get_cc_request', {
        rent_id: extra.rent_id
      });
      ({ creditclan_request_id } = res.data?.data ?? {});
    }
    if (platform === 'school-parent') {
      const res = await axios.post('https://sellbackend.creditclan.com/parent/index.php/external/get_cc_request', {
        parent_request_id: extra.parent_request_id
      });
      ({ creditclan_request_id } = res.data?.data ?? {});
    }
    if (platform === 'school-teacher') {
      const res = await axios.post('https://sellbackend.creditclan.com/parent/index.php/external/get_cc_request', {
        teacher_request_id: extra.teacher_request_id
      });
      ({ creditclan_request_id } = res.data?.data ?? {});
    }
    if (platform === 'merchant') {
      const res = await axios.post('https://sellbackend.creditclan.com/parent/index.php/external/get_cc_request', {
        merchant_request_id: extra.merchant_request_id
      });
      ({ creditclan_request_id } = res.data?.data ?? {});
    }
    if (platform === 'meter') {
      const res = await axios.post('https://sellbackend.creditclan.com/parent/index.php/external/get_cc_request', {
        meter_id: extra.meter_id
      });
      ({ creditclan_request_id } = res.data?.data ?? {});
    }
    if (platform === 'ride') {
      const res = await axios.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getRideRequest', {
        id: extra.ride_id
      });
      ({ creditclan_request_id } = res.data?.data ?? {});
    }
    commit('patch', { request_id: creditclan_request_id });
  },
  async getRequestDetails({ state, commit }) {
    try {
      const { token, request_id, config: { always_show_offers, first_month_upfront } } = state;
      const res = await http.post('https://mobile.creditclan.com/api/v3/loan/details', { token, request_id });
      const { stage, offers, offer_selected, offer_reviewed, plans } = res.data.data.loan;
      let [offer] = offers ?? [];
      if (offer) {
        offer = {
          ...offer,
          upfront: roundToNearestTen(+(`${ offer.upfront }`.replace(/,/gi, ''))),
          amount: roundToNearestTen(+(`${ offer.amount }`.replace(/,/gi, ''))),
          monthly_repayment: roundToNearestTen(+(`${ offer.monthly_repayment }`.replace(/,/gi, ''))),
        };
        if (offer_selected) {
          const payment = { amount: offer_selected.upfront, upfront: true };
          return commit('patch', { stage, payment, offer, offer_reviewed, plans });
        }
        if (!offer.amount && always_show_offers) {
          const price = offer.amount + offer.upfront;
          const upfront = roundToNearestTen(price * .5);
          const total_repayment = price - upfront;
          const duration = 3;
          offer = {
            ...offer,
            upfront,
            amount: total_repayment,
            total_repayment,
            monthly_repayment: roundToNearestTen(total_repayment / duration),
            duration,
          }
        }
        if (first_month_upfront) {
          const total = offer.amount + offer.upfront;
          const duration = offer.duration;
          const monthly_repayment = roundToNearestTen(total / duration);
          const upfront = monthly_repayment;
          const amount = total - upfront;
          offer = {
            ...offer,
            upfront,
            monthly_repayment,
            total_repayment: amount,
            amount,
            duration: duration - 1,
          }
        }
      }
      commit('patch', { stage, offer, offer_reviewed, plans });
    } catch (e) {
      commit('patch', { request_id: null });
      console.log(e);
    }
  },
  async saveUserData({ state }) {
    const {
      request, profile, frequently_called_numbers: fcn, account, work,
      home_address, social, token, next_of_kin, documents, bvn, bvn_data, request_id, attachments
    } = state;
    await http.post('/customer/save_market_data', {
      frequently_called_numbers: [...fcn].filter(n => n.phone), bvn_data, request_id, attachments,
      profile, account: { ...account, bvn }, work, request, home_address, social, token, next_of_kin,
      documents: documents.map(doc => doc.url)
    });
  },
  async createRequest({ state, commit, dispatch }) {
    const {
      request, profile, frequently_called_numbers: fcn, account, work, home_address, social, extra, next_of_kin,
      config: { platform }, plans, bvn_data, bvn, token, documents, attachments, referral_code
    } = state;
    const eligibility_link = location.href;
    let url = '/loan/apply_for_bnpl';
    if (platform === 'rent-tenant') url = '/loan/apply_for_rent';
    const payload = {
      frequently_called_numbers: [...fcn].filter(n => n.phone), profile, work, request, next_of_kin,
      home_address, social, eligibility_link, bvn_data, account: { ...account, bvn }, ...extra,
      documents: documents.map(doc => doc.url), token, attachments, ref: referral_code,
    };
    if (plans) payload.plans = plans;
    const { data } = await http.post(url, payload);
    if (!data.status) {
      throw Error(data.message || 'An error occurred while creating your request, please try again');
    }
    commit('patch', { request_id: data.dd });
    parent.postMessage({ id: 'cclan-request', data: { request_id: data.dd } }, '*');
    await dispatch('updateStage', { stage: 'created' });
    await dispatch('saveCreditclanRequestId');
  },
  async saveCreditclanRequestId({ state }) {
    const { config: { platform }, extra, request_id, profile: { profile_image } } = state;
    try {
      if (platform === 'shop-global') {
        if (!extra.global_request_id) return;
        await axios.patch('https://sellbackend.creditclan.com/merchantclan/public/index.php/api/global/request', {
          creditclan_request_id: request_id, global_request_id: extra.global_request_id
        });
      }
      if (platform === 'shop-power') {
        await axios.post(`https://sellbackend.creditclan.com/merchantclan/public/index.php/api/request/${ extra.power_merchant_request_id }`, {
          creditclan_request_id: request_id
        });
      }
      if (platform === 'shop-merchant') {
        await axios.post(`https://sellbackend.creditclan.com/merchantclan/public/index.php/api/request/${ extra.merchant_request_id }`, {
          creditclan_request_id: request_id
        });
      }
      if (platform === 'rent-tenant') {
        await axios.post(`https://wema.creditclan.com/api/v3/rent/update_cc_request`, {
          rent_id: extra.rent_id, creditclan_request_id: request_id
        });
      }
      if (platform === 'school-parent') {
        await axios.post(`https://sellbackend.creditclan.com/parent/index.php/external/update_cc_request`, {
          creditclan_request_id: request_id, parent_request_id: extra.parent_request_id, profile_image
        });
      }
      if (platform === 'meter') {
        await axios.post(`https://sellbackend.creditclan.com/parent/index.php/external/update_cc_request`, {
          creditclan_request_id: request_id, meter_id: extra.meter_id
        });
      }
      if (platform === 'school-teacher') {
        await axios.post(`https://sellbackend.creditclan.com/parent/index.php/external/update_cc_request`, {
          creditclan_request_id: request_id, teacher_request_id: extra.teacher_request_id
        });
      }
      if (platform === 'merchant') {
        await axios.post(`https://sellbackend.creditclan.com/parent/index.php/external/update_cc_request`, {
          creditclan_request_id: request_id, merchant_request_id: extra.merchant_request_id
        });
      }
      if (platform === 'ride') {
        await axios.post(`https://sellbackend.creditclan.com/parent/index.php/globalrequest/updateRideRequest`, {
          creditclan_request_id: request_id, id: extra.ride_id
        });
      }
    } catch (e) {
      console.log({ e });
    }
  },
  cancelRequest: async ({ state }) => {
    const { request_id } = state;
    await http.post(`/cancel/loan_request`, { request_id });
  }
};

const eligibility = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default eligibility;
