<template>
  <div class="my-auto">
    <Loader v-if="loading" :text="loading"/>
    <div v-else>
      <template v-if="view === 'home'">
        <div class="text-center mx-auto flex flex-col items-center justify-center">
          <i class="fa fa-exclamation-circle fa-4x text-gray-400"></i>
          <p class="mt-10 text-gray-600 font-semibold text-lg max-w-sm">Confirmation of all frequently called numbers is required</p>
          <div class="mt-8 divide-y bg-gray-50 px-4 py-3 rounded-lg w-full border max-w-xs">
            <p class="flex justify-between items-center text-sm py-2" v-for="(v, i) in data" :key="`${v.phone}${i}`">
              <span class="text-gray-500">{{ v.phone }}</span>
              <span class="text-green-500" v-if="v.status">Confirmed</span>
              <span class="text-red-500" v-else>Pending</span>
            </p>
          </div>
          <Message class="text-sm max-w-sm mt-4">
            Notify the owners of these numbers to check their whatsapp for confirmation, or click
            <button @click="view = 'change'" class="text-blue-600">here</button>
            to change numbers
          </Message>
          <div class="mt-7">
            <AppButton @click="confirm()">
              <i class="fa fa-redo-alt mr-2"></i> Confirm
            </AppButton>
          </div>
          <p @click="next()" class="mt-2 text-gray-400 cursor-pointer">Skip</p>
        </div>
      </template>
      <template v-if="view === 'change'">
        <button @click="view = 'home'" class="w-8 h-8 rounded-full text-gray-500 bg-gray-50 border text-sm flex items-center justify-center">
          <i class="fa fa-chevron-left"></i>
        </button>
        <FrequentlyCalledNumbers @done="changeDone()"/>
      </template>
    </div>
  </div>
</template>

<script>
import ajax from "@/mixins/ajax.js";
import Loader from "@/components/global/Loader.vue";
import Message from "@/components/global/Message.vue";
import FrequentlyCalledNumbers from "@/components/core/home/sections/FrequentlyCalledNumbers.vue";
import AppButton from "@/components/global/Button.vue";

export default {
  name: "ConfirmFrequentlyCalledNumbers",
  components: { AppButton, FrequentlyCalledNumbers, Message, Loader },
  mixins: [ajax],
  data() {
    return {
      data: [],
      view: 'home'
    }
  },
  created() {
    this.confirm();
  },
  methods: {
    async confirm() {
      this.setLoading('Checking..');
      const { data } = await this.$http.post('/customer/confirm_frequent_numbers', {
        token: this.$store.state.eligibility.token,
        request_id: this.$store.state.eligibility.request_id,
      });
      if (data.status) {
        this.data = data.data;
        const valid = this.data.every(d => d.status);
        if (valid) this.next();
      }
      this.setLoading('');
    },
    async send() {
      this.setLoading('Sending confirmation..');
      try {
        const { token, request_id, frequently_called_numbers: fcn } = this.$store.state.eligibility;
        const filtered = fcn.filter(n => !this.data.some(d => d.phone === n.phone)).filter(n => n.phone);
        if (filtered.length) {
          await this.$http.post('/customer/initiate_frequently_asked_question', {
            request_id, token, frequently_called_numbers: [...filtered]
          });
          await this.confirm();
        }
      } catch (e) {
        this.setError('Error sending whatsapp confirmation', true);
        this.setRetry(() => this.send());
      }
      this.setLoading('');
    },
    async changeDone() {
      await this.send();
      this.view = 'home';
    },
    next() {
      this.$emit('done')
    }
  }
}
</script>
