<template>
  <div class="h-full flex flex-col">
    <div v-if="loading">
      <Loader :text="loading" class="py-auto px-6 lg:px-12 pb-8"/>
    </div>
    <div v-else class="h-full flex flex-col">
      <div class="px-6 lg:px-12 pb-8">
        <div>
          <template v-if="view === 'initial'">
            <SectionHeader
              title="Verify work email"
              :subtitle="`You need to verify your official work email address ${ $store.state.eligibility.work.official_email } before proceeding, click the button below to receive an otp code`"
            />
            <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
            <AppButton @click="sendOtpCode()">Get otp code</AppButton>
          </template>
          <template v-if="view === 'otp'">
            <SectionHeader
              title="Enter OTP code"
              :subtitle="`An OTP code has been sent to ${$store.state.eligibility.work.official_email}, enter code below to confirm your email address`"
            />
            <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
            <OtpCodeInput @done="handleOtpCodeInputDone($event)"/>
            <div class="flex items-center mt-10 space-x-4">
              <AppButton @click="handleOtpCodeInputDone()">Verify email</AppButton>
              <Countdown ref="countdown" :from="60" v-slot="{ current }">
                <div v-if="current">
                  Resend OTP code in {{ current }}
                </div>
                <AppButton v-else @click="sendOtpCode()" variant="outline" size="sm">
                  <i class="fa fa-redo-alt mr-2"/>
                  Resend
                </AppButton>
              </Countdown>
            </div>
          </template>
        </div>
      </div>
      <div class="flex justify-end mt-auto md:hidden">
        <img src="@/assets/images/whatsapp-phone-half.png" alt="" class="w-52"/>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppButton from "@/components/global/Button.vue";
import ajax from "@/mixins/ajax.js";
import OtpCodeInput from "@/components/global/OtpCodeInput.vue";
import Loader from "@/components/global/Loader.vue";
import Message from "@/components/global/Message.vue";
import Countdown from "@/components/global/Countdown.vue";

export default {
  name: "VerifyWorkEmail",
  components: { Countdown, Message, Loader, OtpCodeInput, AppButton, SectionHeader },
  mixins: [ajax],
  data() {
    return {
      view: 'initial',
      official_email: this.$store.state.eligibility.work.official_email,
    }
  },
  created() {
    const { config: { verify_work_email, show_work_information }, work } = this.$store.state.eligibility;
    const { oneMonth } = this.$store.getters['eligibility/lastApplied'];
    if (!verify_work_email || oneMonth) return this.$emit('skip');
    if (!show_work_information || work.work_email_verified === '1' || !work.official_email || work.occupation_id !== '1') {
      return this.$emit('skip');
    }
  },
  methods: {
    async sendOtpCode() {
      this.setLoading('Sending otp code', true);
      try {
        const res = await this.$http.post('https://mobile.creditclan.com/api/v3/customer/request_work_email_otp', {
          work_email: this.official_email
        });
        if (!res.data.status) return this.setError(res.data?.message || 'An error occurred, please try again', true);
        this.view = 'otp';
      } catch (e) {
        console.log(e);
        this.setError(e?.response?.data?.message || 'An error occurred, please try again', true);
      }
      this.setLoading('');
      this.$refs?.countdown?.reset();
    },
    async handleOtpCodeInputDone(otp) {
      this.setLoading('Confirming email address');
      const { token } = this.$store.state.eligibility;
      try {
        const res = await this.$http.post('https://mobile.creditclan.com/api/v3/customer/validate_work_email_otp', {
          work_email: this.official_email, otp, token
        });
        if (!res.data.status) return this.setError(res.data?.message || 'An error occurred, please try again', true);
        this.$store.commit('eligibility/patch', {
          work: {
            ...this.$store.state.eligibility.work,
            work_email_verified: '1'
          }
        });
        this.next();
      } catch (e) {
        this.setError(e?.response?.data?.message || 'An error occurred, please try again', true);
      }
      this.setLoading('');
    },
    next() {
      if (this.$store.state.eligibility.work.work_email_verified !== '1') return;
      this.$emit('skip');
    }
  }
}
</script>
