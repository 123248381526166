<template>
  <div class="px-6 lg:px-12 pb-8">
    <SectionHeader
      title="Provide Next of Kin"
      subtitle="Please fill in all fields correctly"
    />
    <Message>Next of Kin must be over 18 years old</Message>
    <form>
      <ValidationObserver ref="observer">
        <div class="flex flex-col space-y-4">
          <ValidationProvider name="Full name" rules="required" v-slot="{ errors }">
            <AppInput label="Full name" v-model="values.full_name" type="text" class="w-full" :errors="errors"/>
          </ValidationProvider>
          <ValidationProvider name="Email address" rules="email" v-slot="{ errors }">
            <AppInput label="Email address (Optional)" v-model="values.email" type="email" class="w-full" :errors="errors"/>
          </ValidationProvider>
          <ValidationProvider name="Phone number" rules="required|phone" v-slot="{ errors }">
            <AppInput label="Phone number" v-model="values.phone" type="text" class="w-full" :errors="errors"/>
          </ValidationProvider>
          <ValidationProvider name="address" rules="required" v-slot="{ errors }">
            <AppTextArea placeholder="Home/Street address" v-model="values.address" :errors="errors"/>
          </ValidationProvider>
          <ValidationProvider name="Relationship" rules="required" v-slot="{ errors }">
            <AppSelect label="Relationship" v-model="values.relationship" name="relationship" :errors="errors">
              <option value="spouse">Spouse</option>
              <option value="sibling">Sibling</option>
              <option value="child">Child</option>
              <option value="relative">Relative</option>
              <option value="friend">Friend</option>
            </AppSelect>
          </ValidationProvider>
        </div>
        <div class="flex mt-10">
          <AppButton @click="submit()" :loading="loading">Next</AppButton>
        </div>
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppSelect from "@/components/global/Select.vue";
import AppInput from "@/components/global/Input.vue";
import AppButton from "@/components/global/Button.vue";
import AppTextArea from "@/components/global/TextArea.vue";
import Message from "@/components/global/Message.vue";

export default {
  name: "NextOfKin",
  components: { Message, AppTextArea, AppButton, AppInput, AppSelect, SectionHeader },
  data() {
    return {
      loading: false,
      values: {
        full_name: '',
        email: '',
        phone: '',
        address: '',
        relationship: '',
      }
    }
  },
  created() {
    const { next_of_kin, config: { show_nok } } = this.$store.state.eligibility;
    const { oneMonth } = this.$store.getters['eligibility/lastApplied'];
    if (!show_nok || (oneMonth && next_of_kin.full_name)) return this.$emit('skip');
    this.values = { ...next_of_kin };
    Object.keys(this.values).forEach(key => {
      if (this.values[key] === '0') {
        this.values[key] = null;
      }
    })
  },
  methods: {
    async submit() {
      if (!(await this.$refs.observer.validate())) return;
      this.$store.commit("eligibility/patch", { next_of_kin: { ...this.values } });
      this.$emit("done");
    }
  }
}
</script>
