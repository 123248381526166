<template>
  <div v-if="overlay" class="my-auto w-full">
    <RandomQuestions @done="overlay = ''"/>
  </div>
  <div v-else class="h-full flex flex-col">
    <div class="pt-6 lg:py-10 h-full flex flex-col">
      <div class="flex justify-between mb-8 px-6 lg:px-12">
        <button
          @click="popView()"
          class="w-8 h-8 rounded-full text-gray-500 bg-gray-50 border text-sm flex items-center justify-center"
          :class="{ 'pointer-events-none opacity-0': history.length <= 1 || loading }"
        >
          <i class="fa fa-chevron-left"></i>
        </button>
        <button
          @click="cancel()" v-if="inIframe"
          class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 hover:bg-red-50"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="h-full flex flex-col md:h-auto md:my-auto">
        <div v-if="loading" class="px-6 lg:px-12 my-auto">
          <Loader :text="loading"/>
        </div>
        <Error v-else-if="error" :text="error" :show-retry="!!retryCb" @retry="retry()"/>
        <template v-else>
          <BankAccount
            v-if="view === 'account'"
            @done="pushView('bvn')"
            @skip="replaceView('bvn')"
          />
          <BVN
            v-if="view === 'bvn'"
            @done="createRequest(); pushView('bank-statement')"
            @skip="createRequest(); replaceView('bank-statement')"
          />
          <BankStatement
            v-if="view === 'bank-statement'"
            @back="popView()"
            @done="finish()"
            @skip="finish()"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader.vue";
import view from "@/mixins/view.js";
import ajax from "@/mixins/ajax.js";
import Error from "@/components/global/Error.vue";
import RandomQuestions from "@/components/core/home/sections/RandomQuestions.vue";
import BankAccount from "@/components/core/home/sections/BankAccount.vue";
import BankStatement from "@/components/core/home/sections/BankStatement.vue";
import BVN from "@/components/core/home/sections/BVN.vue";

export default {
  name: "PreAnalysis",
  components: {
    BVN,
    BankStatement,
    BankAccount,
    RandomQuestions,
    Error,
    Loader,
  },
  mixins: [view, ajax],
  data: () => ({
    inIframe: false
  }),
  created() {
    this.getBanks();
    this.inIframe = window.self !== window.top;
    this.pushView('account');
  },
  methods: {
    async finish() {
      this.modal = '';
      if (!this.$store.state.eligibility.request_id) {
        this.setLoading('Submitting request..');
        const res = await this.$store.dispatch('eligibility/createRequest');
        this.setLoading('');
        if (res instanceof Error) {
          this.setError(res.message || 'Unspecified error');
          this.$mixpanel.track('Error while creating request', {
            platform: this.$store.state.eligibility.config.platform,
            request_id: this.$store.state.eligibility.request_id,
            error: res?.message || 'Unspecified error'
          });
          return;
        }
      }
      if (this.$store.state.eligibility.request_id) this.$emit('done');
    },
    async saveUserData() {
      this.setLoading('Saving your information..', true);
      try {
        await this.$store.dispatch('eligibility/saveUserData');
      } catch (e) {
        this.setError(e.response ? e.response.data.message : e.message || 'An error while saving your data, please try again');
        this.setRetry(() => this.saveUserData());
      }
      this.setLoading('');
    },
    async createRequest() {
      try {
        this.setLoading('Saving..');
        if (!this.$store.state.eligibility.request_id) {
          await this.$store.dispatch('eligibility/createRequest');
        }
        await this.$store.dispatch('eligibility/saveUserData');
        this.setLoading('');
      } catch (e) {
        const message = e.response ? e.response.data.message : e.message || 'An error occurred, please try again';
        this.setError(message, true);
        this.setRetry(() => this.createRequest());
        this.$mixpanel.track('Error while creating request', {
          platform: this.$store.state.eligibility.config.platform,
          request_id: this.$store.state.eligibility.request_id,
          error: message,
        });
      }
    },
    cancel() {
      this.$bus.$emit('cancel');
    },
    async getBanks() {
      if (this.$store.state.eligibility.banks_mono) return;
      const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
      const banks_mono = res.data.data;
      this.$store.commit('eligibility/patch', { banks_mono })
    }
  }
};
</script>
