import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from "@/views/Home.vue";
import Statement from "@/views/Statement.vue";
import Instant from "@/views/Instant.vue";

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/statement', name: 'statement', component: Statement },
  { path: '/instant', name: 'instant', component: Instant },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
