<template>
  <label class="flex flex-col items-start">
    <span v-if="label" class="flex opacity-60">{{ label }}</span>
    <select
      class="py-1 px-0 text-md border-b border-gray-300 bg-transparent w-full focus:outline-none focus:border-b-2 focus:border-blue-600"
      :disabled="disabled"
      :class="{ 'py-1': size === 'sm', 'text-md': size === 'md', 'opacity-50' : disabled }"
      v-model="model" @change="$emit('change', $event)"
    >
      <slot></slot>
    </select>
    <small class="text-red-500 mt-2" v-for="error in errors" :key="error">{{ error }}</small>
  </label>
</template>

<script>
export default {
  name: "AppSelect",
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      options: ['sm', 'lg'],
      default: 'md'
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
