<template>
  <div class="flex flex-col items-center justify-center my-auto text-center px-6 lg:px-12">
    <img src="@/assets/images/hourglass.png" alt="questions" class="w-36"/>
    <h2 class="text-2xl mt-16 max-w-sm">
      Your request is under review, you will be notified once review is done
    </h2>
  </div>
</template>

<script>
export default {
  name: "PendingReview",
  created() {
    return this.$emit('skip');
  }
}
</script>
