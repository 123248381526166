<template>
  <div v-if="overlay" class="h-full flex flex-col">
    <RandomQuestions @done="overlay = ''"/>
  </div>
  <div v-else class="h-full flex flex-col">
    <Loader v-if="loading" :text="loading" class="my-auto"/>
    <template v-else>
      <Error v-if="error" :text="error" show-retry @retry="init()" class="my-auto"/>
      <template v-else>
        <div class="px-6 lg:px-12 pb-8">
          <div v-if="view === 'institutions'" class="card">
            <div class="flex justify-between align-center mb-10">
              <div class="flex">
                <h3 class="text-gray-800 font-semibold ml-3">Institutions</h3>
              </div>
              <div class="flex gap-4">
                <div class="text-gray-600 text-sm cursor-pointer"
                     :class="{'text-blue-600': type === 'PERSONAL_BANKING'}"
                     @click="type = 'PERSONAL_BANKING'">
                  Personal
                </div>
                <div class="text-gray-600 text-sm cursor-pointer"
                     :class="{'text-blue-600': type === 'BUSINESS_BANKING'}"
                     @click="type = 'BUSINESS_BANKING'">
                  Business
                </div>
              </div>
            </div>
            <div
              @click="selectInstitution(item)"
              class="border bg-gray-50 rounded-lg flex items-center mb-3 cursor-pointer p-3"
              v-for="item in [...institutions].filter(i => i.type === this.type)" :key="item._id"
            >
              <img :src="item.icon" style="width:50px" class="rounded" alt="icon"/>
              <div class="ml-3 flex flex-col">
                <h5 class="text-gray-800">{{ item.name }}</h5>
                <small class="text-xs text-gray-600">{{ item.auth_methods.map(v => v.name).join(' • ') }}</small>
              </div>
            </div>
          </div>
          <div v-if="view === 'auth_method'" class="card">
            <div class="flex mb-10">
              <h3 class="text-gray-800 font-semibold ml-3">Choose login method</h3>
            </div>
            <div
              class="border bg-gray-50 rounded-lg flex justify-between items-center mb-3 cursor-pointer py-3 px-5"
              @click="selectAuthMethod(item)"
              v-for="item in selected.auth_methods" :key="item.type"
            >
              <h5 class="text-gray-800">{{ item.name }}</h5>
              <i class="fa fa-long-arrow-alt-right"></i>
            </div>
          </div>
          <div v-if="view === 'form'" class="card">
            <div class="flex justify-between items-center mb-6">
              <div class="flex flex-col">
                <h3 class="text-gray-800 font-semibold">{{ title }}</h3>
                <p class="text-sm opacity-60">Via my internet or mobile banking account</p>
              </div>
              <img :src="selected.icon" class="w-16 rounded" alt="">
            </div>
            <Message v-if="loginError" variant="error" class="mb-6">{{ loginError }}</Message>
            <Message v-else class="text-sm mb-6">
              Disclaimer: We do not have access to transfer from your account, the purpose is just for us to evaluate
              your bank transactions
            </Message>
            <form>
              <div class="flex flex-col gap-4">
                <AppInput v-model="values.username" :placeholder="selected.ui.form[0].hint"/>
                <AppInput type="password" v-model="values.password" :placeholder="selected.ui.form[1].hint "/>
              </div>
              <div class="mt-10">
                <AppButton @click="submit()">Analyze my transactions</AppButton>
              </div>
              <button type="button" @click="$emit('options')" class="text-gray-600 mt-5 text-base">
                <span class="text-blue-600">I don't have </span>internet banking or mobile app
              </button>
            </form>
          </div>
          <div v-if="view === 'otp'" class="card">
            <div class="flex flex-col">
              <h3 class="text-2xl text-gray-800 font-semibold flex mb-6">{{ response.title }}</h3>
              <div class="flex">
                <AppInput
                  v-model="otp" class="mt-6"
                  :placeholder="response.form[0].hint"
                />
              </div>
              <div class="mt-6">
                <AppButton @click="handleCommitSessionOtp()">Submit</AppButton>
              </div>
            </div>
          </div>
          <div v-if="view === 'select-account'" class="card">
            <div class="flex flex-col">
              <h3 class="text-2xl text-gray-800 font-semibold flex mb-6">
                Select preferred account below to continue
              </h3>
              <div class="space-y-2">
                <div
                  v-for="a in response" :key="a.index" @click="handleCommitSessionAccount(a)"
                  class="border border-gray-300 rounded px-5 py-4 rounded-lg hover:bg-gray-100 cursor-pointer"
                >
                  <div class="text-gray-800 font-semibold text-sm mb-1">{{ a.accountNumber }}</div>
                  <div class="text-gray-600">{{ a.name }}</div>
                  <div class="text-gray-600">{{ a.type }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-auto md:hidden">
          <img src="@/assets/images/savings.svg" alt="" class="w-60"/>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader.vue";
import AppInput from "@/components/global/Input.vue";
import AppButton from "@/components/global/Button.vue";
import ajax from "@/mixins/ajax.js";
import Message from "@/components/global/Message.vue";
import Error from "@/components/global/Error.vue";
import view from '@/mixins/view.js';
import RandomQuestions from "@/components/core/home/sections/RandomQuestions.vue";

export default {
  name: "BSMono",
  components: { RandomQuestions, Error, Message, AppButton, AppInput, Loader },
  mixins: [ajax, view],
  props: {
    title: {
      type: String,
      default: 'Analyze my bank statement'
    }
  },
  data() {
    return {
      mono_sec_key: 'live_sk_7TBXWKDurAWCGIIPkrxd',
      institutions: [],
      type: 'PERSONAL_BANKING',
      auth_method: '',
      selected: null,
      session: null,
      values: {
        username: '',
        password: ''
      },
      auto: false,
      loginError: '',
      banks: this.$store.state.eligibility.banks_mono,
      otp: '',
      response: null,
      attempts: 1,
    };
  },
  created() {
    this.track('Bs switched to mono');
    this.init();
  },
  methods: {
    async init() {
      this.setLoading('Please wait..');
      try {
        if (!this.banks) {
          const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
          this.banks = res.data.data;
        }
        const { bank_code } = this.$store.state.eligibility.account;
        const institution = this.banks.find(bank => `${ bank.bank_code }` === `${ bank_code }`)?.mono_bank_data;
        if (institution) {
          this.selectInstitution(institution);
          this.auto = true;
        } else {
          this.track('Bs mono institute not found');
          this.$emit('failed');
        }
      } catch (e) {
        this.track('Bs mono auto select institute failed');
        this.setError(e?.response?.data?.message || 'An error occurred, please try again');
      }
      this.setLoading('');
    },
    selectInstitution(institution) {
      this.selected = institution;
      const mobile_auth_method = this.selected.auth_methods.find(m => m.type === 'mobile_banking');
      const internet_auth_method = this.selected.auth_methods.find(m => m.type === 'internet_banking');
      if (!mobile_auth_method && !internet_auth_method) this.selectAuthMethod(institution.auth_methods[0]);
      else if (mobile_auth_method) this.selectAuthMethod(mobile_auth_method);
      else if (internet_auth_method) this.selectAuthMethod(internet_auth_method);
    },
    selectAuthMethod(type) {
      this.auth_method = type;
      this.pushView('form');
    },
    unselect() {
      if (this.auto) return this.$emit('back');
      this.pushView('institutions');
      this.selected = null;
    },
    async submit() {
      this.track('Bs mono login attempted', { attempts: this.attempts });
      this.overlay = 'random-questions';
      this.setLoading('Verifying your credentials..');
      try {
        await this.createSession();
        const { data } = await this.$http.post('https://api.withmono.com/v1/connect/login', { ...this.values }, {
          headers: { 'mono-sec-key': this.mono_sec_key, 'x-session-id': this.session.id }
        });
        if (data.responseCode === 102) {
          this.response = data.data;
          this.pushView('otp');
          this.track('Bs mono login requested otp');
        } else if (data.responseCode === 101) {
          const account = data.data.find(a => a.accountNumber === this.$store.state.eligibility.account.account_number);
          if (!account) {
            this.response = data.data;
            this.pushView('select-account');
            this.track('Bs mono login prompt select account')
          } else {
            this.track('Bs mono login auto selected account');
            this.handleCommitSessionAccount(account);
          }
        } else if (data.responseCode === 99) {
          this.track('Bs mono login successful');
          this.$emit('done', data.data);
        } else {
          this.track('Bs mono unhandled response code', { data });
        }
      } catch (err) {
        if (this.selected.auth_methods.length > 1 && this.auth_method.type === 'mobile_banking') {
          const internet_auth_method = this.selected.auth_methods.find(m => m.type === 'internet_banking');
          if (internet_auth_method) {
            this.selectAuthMethod(internet_auth_method);
            return this.submit();
          }
        } else {
          const mobile_auth_method = this.selected.auth_methods.find(m => m.type === 'mobile_banking');
          if (mobile_auth_method) this.selectAuthMethod(mobile_auth_method);
        }
        this.loginError = err.response ? err.response.data.message : 'An error occurred, please try again';
        this.track('Bs mono login error', { error: this.loginError });
        this.$emit('failed');
      }
      this.attempts++;
      this.setLoading('');
    },
    async createSession() {
      const res = await this.$http.post('https://api.withmono.com/v1/connect/session', {
        "app": "6013d2c815222620a13a6956",
        "institution": this.selected._id,
        "auth_method": this.auth_method.type,
      }, { headers: { 'mono-sec-key': this.mono_sec_key } });
      this.session = res.data;
    },
    handleCommitSessionOtp() {
      if (!this.otp) return;
      const data = { otp: this.otp };
      this.commitSession(data);
    },
    handleCommitSessionAccount(account) {
      if (!account) return;
      let data = { account: account.index };
      this.commitSession(data);
    },
    async commitSession(data) {
      this.setLoading('Submitting..');
      try {
        const res = await this.$http.post('https://api.withmono.com/v1/connect/commit', { ...data }, {
          headers: { 'mono-sec-key': this.mono_sec_key, "x-session-id": this.session.id }
        });
        if (res.data.status === 200) {
          return this.$emit('done', res.data.data);
        }
        this.loginError = res.data?.message || 'An error occurred, please try again';
      } catch (err) {
        this.loginError = err.response ? err.response.data.message : 'An error occurred, please try again';
      }
      this.setLoading('');
    },
    track(event, payload = {}) {
      this.$mixpanel.track(event, {
        platform: this.$store.state.eligibility.config.platform,
        bank_code: this.$store.state.eligibility.account.bank_code,
        account_number: this.$store.state.eligibility.account.account_number,
        bank_name: this.$store.getters['eligibility/bankName'],
        ...payload
      });
    }
  }
}
</script>
