<template>
  <div class="h-full flex flex-col">
    <div class="flex justify-between px-6 pt-6 lg:pt-10 lg:px-12">
      <span></span>
      <button
        @click="cancel()" v-if="inIframe"
        class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 hover:bg-red-50"
      >
        <i class="fa fa-times"></i>
      </button>
    </div>
    <div class="h-full flex flex-col md:h-auto md:my-auto">
      <Loader
        v-if="loading"
        class="my-auto"
        text="Please wait.."
      />
      <template v-else>
        <Offers
          v-if="view === 'offers'"
          @done="pushView('picture')"
          @skip="replaceView('picture')"
        />
        <Picture
          v-if="view === 'picture'"
          @done="pushView('profile')"
          @skip="replaceView('profile')"
        />
        <Profile
          v-if="view === 'profile'"
          @done="pushView('confirm-address')"
          @skip="replaceView('confirm-address')"
        />
        <ConfirmAddress
          v-if="view === 'confirm-address'"
          @done="pushView('work')"
          @skip="replaceView('work')"
        />
        <WorkInformation
          v-if="view === 'work'"
          @done="pushView('income')"
          @skip="replaceView('income')"
        />
        <Income
          v-if="view === 'income'"
          @done="pushView('next-of-kin')"
          @skip="replaceView('next-of-kin')"
        />
        <NextOfKin
          v-if="view === 'next-of-kin'"
          @done="pushView('attachments')"
          @skip="replaceView('attachments')"
        />
        <Attachments
          v-if="view === 'attachments'"
          @done="pushView('accounts')"
          @skip="replaceView('accounts')"
        />
        <MultipleAccounts
          v-if="view === 'accounts'"
          @done="pushView('signature')"
          @skip="replaceView('signature')"
        />
        <Signature
          v-if="view === 'signature'"
          @done="pushView('card')"
          @skip="replaceView('card')"
        />
        <RepaymentCard
          v-if="view === 'card'"
          @done="pushView('payment')"
          @skip="replaceView('payment')"
        />
        <VirtualAccountPayment
          v-if="view === 'payment'"
          @done="next()"
          @skip="next()"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Offers from "@/components/core/home/sections/Offers.vue";
import Loader from "@/components/global/Loader.vue";
import view from '@/mixins/view.js';
import Signature from "@/components/core/home/sections/Signature.vue";
import RepaymentCard from "@/components/core/home/sections/RepaymentCard.vue";
import VirtualAccountPayment from "@/components/core/home/sections/VirtualAccountPayment.vue";
import MultipleAccounts from "@/components/core/home/sections/MultipleAccounts.vue";
import Profile from "@/components/core/home/sections/Profile.vue";
import ConfirmAddress from "@/components/core/home/sections/ConfirmAddress.vue";
import WorkInformation from "@/components/core/home/sections/WorkInformation.vue";
import Income from "@/components/core/home/sections/Income.vue";
import NextOfKin from "@/components/core/home/sections/NextOfKin.vue";
import Picture from "@/components/core/home/sections/Picture.vue";
import Attachments from "@/components/core/home/sections/Attachments.vue";

export default {
  name: "PostAnalysis",
  components: {
    Attachments,
    NextOfKin,
    Income,
    WorkInformation,
    ConfirmAddress,
    Profile,
    MultipleAccounts,
    VirtualAccountPayment,
    RepaymentCard,
    Signature,
    Loader,
    Offers,
    Picture,
  },
  mixins: [view],
  data() {
    return {
      loading: false,
      inIframe: false
    };
  },
  created() {
    this.inIframe = window.self !== window.top;
    const { stage } = this.$store.state.eligibility;
    if (stage === 'offer-selected') this.pushView('picture');
    else this.pushView('offers');
  },
  methods: {
    async next() {
      this.loading = true;
      await this.$store.dispatch('eligibility/saveUserData');
      this.loading = false;
      this.$emit('done');
    },
    cancel() {
      this.$bus.$emit('cancel');
    },
  }
};
</script>
