<template>
  <div class="px-6 lg:px-12 pb-8">
    <SectionHeader
      title="Finally, provide some basic details"
      subtitle="Please fill in all fields correctly"
    />
    <form>
      <ValidationObserver ref="observer">
        <div class="flex flex-col space-y-4">
          <ValidationProvider name="address" rules="required" v-slot="{ errors }">
            <AppTextArea label="Home/Street address" v-model="values.address" :errors="errors"/>
          </ValidationProvider>
          <ValidationProvider name="state" rules="required" v-slot="{ errors }">
            <AppSelect label="State of residence" v-model="values.state_id" :errors="errors">
              <option value="">State</option>
              <option v-if="!states.length" value="" disabled>Loading</option>
              <option v-for="state in states" :key="state.value" :value="state.value">
                {{ state.text }}
              </option>
            </AppSelect>
          </ValidationProvider>
          <ValidationProvider name="occupation_id" rules="required" v-slot="{ errors }">
            <AppSelect label="Employment status" v-model="values.occupation_id" :errors="errors" class="w-full">
              <option disabled selected>Select employment status</option>
              <option :value="status.value" v-for="status in occupations" :key="status.value">
                {{ status.text }}
              </option>
            </AppSelect>
          </ValidationProvider>
          <template
            v-if="values.occupation_id === '1' || values.occupation_id === '2' || values.occupation_id === '4' || values.occupation_id === '5'"
          >
            <ValidationProvider name="Place of work" rules="required" v-slot="{ errors }">
              <AppInput
                label="Place of work" v-model="values.company_name" type="text" :errors="errors" class="w-full"
                :disabled="loading"
              />
            </ValidationProvider>
            <ValidationProvider name="Address of work" rules="required" v-slot="{ errors }">
              <AppInput
                label="Address of work" v-model="values.work_address" type="text" :errors="errors" class="w-full"
                :disabled="loading"
              />
            </ValidationProvider>
          </template>
        </div>
        <div class="flex mt-10">
          <AppButton @click="submit()" :loading="loading">Next</AppButton>
        </div>
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
import AppButton from "@/components/global/Button.vue";
import AppInput from "@/components/global/Input.vue";
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppTextArea from "@/components/global/TextArea.vue";
import AppSelect from "@/components/global/Select.vue";
import states from "@/lib/states.js";
import { occupations } from "@/lib/options.js";

export default {
  name: "BasicDetails",
  components: { AppSelect, AppTextArea, AppButton, AppInput, SectionHeader },
  data() {
    return {
      loading: false,
      values: {
        address: '',
        state_id: '',
        occupation_id: '',
        company_name: '',
        work_address: '',
      },
      states,
      occupations,
    }
  },
  created() {
    const {
      config,
      home_address: { address, state_id },
      work: { occupation_id, address: work_address, company_name },
    } = this.$store.state.eligibility;
    if (!config.show_basic_details) return this.$emit('skip');
    this.values = {
      occupation_id,
      company_name,
      work_address,
      address,
      state_id,
    };
  },
  methods: {
    async submit() {
      if (!(await this.$refs.observer.validate())) return;
      this.$store.commit("eligibility/patch", {
        work: {
          ...this.$store.state.eligibility.work,
          company_name: this.values.company_name,
          address: this.values.work_address,
          occupation_id: this.values.occupation_id,
        },
        home_address: {
          ...this.$store.state.eligibility.home_address,
          address: this.values.address,
          state_id: this.values.state_id,
        }
      });
      this.$emit("done");
    }
  }
}
</script>
