<template>
  <div class="my-auto">
    <template v-if="offers.length">
      <SectionHeader
        title="Congratulations"
        subtitle="You are eligible for the following offers, select one below to continue"
      />
      <swiper ref="mySwiper" :options="swiperOptions">
        <template v-for="(offer, i) in offers">
          <swiper-slide :key="i">
            <div
              @click="selectOffer(offer)"
              class="rounded-lg p-6 cursor-pointer h-72 flex flex-col border shadow-md"
              :class="colors[i] || 'bg-gray-50 border-gray-100'"
            >
              <p class="text-gray-600">Pay</p>
              <div class="text-xl font-semibold">{{ offer.upfront | currency }} <small
                class="text-gray-600">now</small></div>
              <div v-if="offer.duration === 1" class="text-gray-700">
                and {{ offer.monthly_repayment | currency }} next month
              </div>
              <div v-else class="text-gray-700">
                and {{ offer.monthly_repayment | currency }}/mo for {{ offer.duration }} months
              </div>
              <div class="flex justify-end items-center mt-auto text-gray-600">
                <i class="fa fa-user mr-2"></i> {{ lender ? lender.name : offer.lender }}
              </div>
            </div>
          </swiper-slide>
        </template>
        <swiper-slide v-if="$store.state.eligibility.config.can_modify_offer">
          <div
            @click="handleModifyOffer()"
            class="rounded-lg p-6 cursor-pointer h-72 flex flex-col justify-center items-center border bg-gray-100 border-gray-200 text-center"
          >
            <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
              <i class="fa fa-pen"></i>
            </div>
            <div class="text-xl font-semibold mt-6">Modify offer</div>
            <div class="text-gray-700 mt-4">
              Tap to change offer upfront amount and repayment duration
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <Modal v-if="modal === 'summary' && selectedOffer">
        <div class="my-auto">
          <SectionHeader
            title="Confirm offer"
            :subtitle="`I agree that I will pay ${formatCurrency(selectedOffer.upfront)} now and pay ${formatCurrency(selectedOffer.monthly_repayment)} in ${selectedOffer.duration} month(s)`"
          />
          <div class="flex mt-6">
            <AppButton @click="$emit('accept', selectedOffer)" :loading="accepting">Accept</AppButton>
            <button @click="selectedOffer = null" class="ml-2 text-red-500 py-1 px-3">Cancel</button>
          </div>
        </div>
        <div class="flex justify-end mt-auto md:hidden">
          <img src="@/assets/images/approve.svg" alt="" class="w-56"/>
        </div>
      </Modal>

      <Modal v-if="modal === 'modify'">
        <div class="my-auto">
          <SectionHeader
            title="Modify offer"
            subtitle="Adjust upfront payment and repayment duration"
          />
          <div class="border bg-orange-50 bg-opacity-50 px-5 py-4 rounded-lg">
            <label for="large-range" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Upfront amount - <span class="font-semibold">{{ values.upfront | currency }}</span>
            </label>
            <div class="flex justify-between items-center text-sm">
              <span>{{ values.min_upfront | currency }}</span>
              <span>{{ values.max_upfront | currency }}</span>
            </div>
            <input
              v-model="values.upfront"
              :min="values.min_upfront" :max="values.max_upfront" step="1000"
              id="large-range" type="range" value="50"
              class="w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg dark:bg-gray-700"
            >
          </div>
          <div class="border bg-orange-50 bg-opacity-50 px-5 py-4 rounded-lg mt-4">
            <label for="large-range" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Duration - <span class="font-semibold">{{ values.duration }} month(s)</span>
            </label>
            <div class="flex justify-between items-center text-sm">
              <span>{{ values.min_duration }} month(s)</span>
              <span>{{ values.max_duration }} month(s)</span>
            </div>
            <input
              v-model="values.duration"
              :min="values.min_duration" :max="values.max_duration"
              id="large-range" type="range" value="50"
              class="w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg dark:bg-gray-700"
            >
          </div>
          <div class="rounded-lg p-6 cursor-pointer flex flex-col border bg-teal-50 bg-opacity-50 mt-4">
            <p class="text-gray-600">Pay</p>
            <div class="text-xl font-semibold">
              {{ values.upfront | currency }} <small class="text-gray-600">now</small>
            </div>
            <div v-if="values.duration === 1" class="text-gray-700">
              and {{ values.monthly_repayment | currency }} next month
            </div>
            <div v-else class="text-gray-700">
              and {{ values.monthly_repayment | currency }}/mo for {{ values.duration }} months
            </div>
          </div>
          <div class="flex mt-8">
            <AppButton @click="acceptModifiedOffer()" :loading="accepting">Accept</AppButton>
            <button @click="modal = ''" class="ml-2 text-red-500 py-1 px-3">Cancel</button>
          </div>
        </div>
      </Modal>
    </template>
  </div>
</template>

<script>
import Modal from "@/components/global/Modal.vue";
import AppButton from "@/components/global/Button.vue";
import { formatCurrency, roundToNearestTen } from "@/lib/utils.js";
import SectionHeader from "@/components/global/SectionHeader.vue";

export default {
  name: "GenericOffers",
  components: { SectionHeader, AppButton, Modal },
  props: {
    offer: {
      type: Object,
      required: true
    },
    accepting: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      offers: [],
      selectedOffer: null,
      swiperOptions: {
        slidesPerView: 1.5,
        spaceBetween: 30,
      },
      payment: { amount: 0, upfront: false },
      formatCurrency,
      lender: this.$store.state.eligibility.lender,
      colors: ['bg-orange-50 border-orange-100', 'bg-teal-50 border-teal-100', 'bg-red-50 border-red-100', 'bg-blue-50 border-blue-100'],
      modal: '',
      values: {
        upfront: 0,
        duration: 0,
        amount: 0,
        min_duration: 0,
        max_duration: 0,
        min_upfront: 0,
        max_upfront: 0,
        monthly_repayment: 0,
        loan_amount: 0,
        interest: 0,
        total_repayment: 0,
      }
    }
  },
  async created() {
    this.handleGenerateOffer(this.offer);
    this.$watch('values.upfront', this.handleValuesChange);
    this.$watch('values.duration', this.handleValuesChange);
  },
  methods: {
    handleGenerateOffer(offer) {
      this.offers = this.generateOffers(offer);
    },
    generateOffers(offer) {
      let plans = [];
      const { duration, upfront, total_repayment, amount, interest, monthly_repayment } = offer;
      if (!total_repayment) return [];
      const price = amount + upfront;
      {
        plans.push({
          loan_amount: offer.amount,
          repayment_amount: total_repayment,
          duration,
          monthly_repayment,
          upfront: roundToNearestTen(upfront),
          tenor_type: 2,
          interest
        });
      }
      if (duration > 1) {
        const _duration = duration - 1;
        const monthly_repayment = roundToNearestTen((price - upfront) / _duration);
        plans.push({
          loan_amount: offer.amount,
          repayment_amount: monthly_repayment * _duration,
          monthly_repayment,
          duration: _duration,
          upfront: roundToNearestTen(upfront),
          tenor_type: 2,
          interest
        });
      }
      return plans.map((plan, i) => ({ id: i, ...plan }));
    },
    selectOffer(offer) {
      const { can_change_repayment_frequency } = this.$store.state.eligibility.config;
      if (!can_change_repayment_frequency) return this.$emit('accept', offer);
      this.selectedOffer = { ...offer };
      this.modal = 'summary';
    },
    handleModifyOffer() {
      this.modal = 'modify';
      const { offer, request: { amount }, config: { modify_offer_beyond_limits } } = this.$store.state.eligibility;
      const { duration, upfront, interest, total_repayment, monthly_repayment } = offer;
      const loan_amount = amount - upfront;
      this.values = {
        amount,
        loan_amount,
        duration,
        upfront,
        min_upfront: modify_offer_beyond_limits ? (90 / 100) * +upfront : +upfront,
        max_upfront: (80 / 100) * amount,
        min_duration: 1,
        max_duration: modify_offer_beyond_limits ? duration + 2 : duration,
        monthly_repayment,
        interest,
        total_repayment,
      };
      this.handleValuesChange();
    },
    handleValuesChange() {
      const { amount } = this.values;
      const { duration, upfront, interest } = this.$store.state.eligibility.offer;
      const modifiedUpfront = +this.values.upfront;
      const modifiedDuration = +this.values.duration;
      const loan_amount = amount - modifiedUpfront;
      const _interest = (modifiedUpfront < upfront || modifiedDuration > duration) ? 10 : interest;
      const total_repayment = roundToNearestTen(loan_amount + (((_interest / 100) * loan_amount) * modifiedDuration));
      const monthly_repayment = roundToNearestTen(total_repayment / modifiedDuration);
      this.values = {
        ...this.values,
        upfront: modifiedUpfront,
        duration: modifiedDuration,
        loan_amount: total_repayment,
        interest: _interest,
        monthly_repayment,
        total_repayment
      };
    },
    acceptModifiedOffer() {
      const { duration, upfront, monthly_repayment, total_repayment: repayment_amount, interest } = this.values;
      const offer = {
        upfront,
        duration,
        loan_amount: repayment_amount,
        tenor_type: 2,
        repayment_amount,
        monthly_repayment,
        interest,
      }
      this.selectedOffer = { ...offer };
      this.modal = 'summary';
    }
  },
}
</script>
