var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-6 lg:px-12 pb-8"},[_c('SectionHeader',{attrs:{"title":"Let's know about you","subtitle":"Please fill in all fields correctly"}}),_c('form',[_c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"flex flex-col space-y-4"},[(_vm.$store.state.eligibility.profile.full_name)?_c('AppInput',{staticClass:"w-full",attrs:{"value":_vm.values.full_name,"type":"text","label":"Full name","disabled":""}}):_c('ValidationProvider',{attrs:{"name":"Full name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{staticClass:"w-full",attrs:{"type":"text","label":"Full name","errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.full_name),callback:function ($$v) {_vm.$set(_vm.values, "full_name", $$v)},expression:"values.full_name"}})]}}])}),_c('AppInput',{staticClass:"w-full",attrs:{"value":_vm.values.email,"type":"email","label":"Email address","disabled":""}}),_c('AppInput',{staticClass:"w-full",attrs:{"value":_vm.values.phone,"label":"Phone number","type":"text","disabled":""}}),_c('ValidationProvider',{attrs:{"name":"Date of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{staticClass:"w-full",attrs:{"label":"Date of birth","type":"date","errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.date_of_birth),callback:function ($$v) {_vm.$set(_vm.values, "date_of_birth", $$v)},expression:"values.date_of_birth"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{attrs:{"name":"gender","label":"Gender","errors":errors},model:{value:(_vm.values.gender),callback:function ($$v) {_vm.$set(_vm.values, "gender", $$v)},expression:"values.gender"}},[_c('option',{attrs:{"value":"0"}},[_vm._v("Male")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Female")])])]}}])}),_c('ValidationProvider',{attrs:{"name":"Marital status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{attrs:{"name":"marital_status","label":"Marital status","errors":errors},model:{value:(_vm.values.marital_status),callback:function ($$v) {_vm.$set(_vm.values, "marital_status", $$v)},expression:"values.marital_status"}},_vm._l((_vm.maritalStatusOptions),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)]}}])})],1),_c('div',{staticClass:"flex mt-10"},[_c('AppButton',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Next")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }