<template>
  <div class="h-full flex flex-col">
    <div class="px-6 lg:px-12 pb-8">
      <template v-if="banks && banks.length">
        <template v-if="view === 'accounts'">
          <SectionHeader title="Is this still your most used bank account?"/>
          <div class="border border-gray-300 px-5 py-4 rounded-lg">
            <div class="text-gray-800 font-semibold text-sm mb-1">{{ bank.name }}</div>
            <div class="text-gray-600">
              Account number:
              <span class="font-semibold text-gray-700">{{ values.account_number }}</span>
            </div>
            <div class="text-gray-600">
              Account name:
              <span class="font-semibold text-gray-700">{{ values.account_name }}</span>
            </div>
          </div>
          <AppButton @click="next()" class="mt-8">Yes it is, continue</AppButton>
          <p class="text-gray-600 mt-4">No, change to other account
            <button class="text-blue-500" @click="change()">here</button>
          </p>
        </template>
        <template v-if="view === 'add'">
          <SectionHeader title="Which account do you bank with the most"/>
          <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
          <form>
            <ValidationObserver ref="observer">
              <div class="flex flex-col space-y-4">
                <ValidationProvider name="bank_code" rules="required" v-slot="{ errors }">
                  <AppSelect
                    v-model="values.bank_code" :errors="errors" class="w-full" :disabled="loading || validated()"
                  >
                    <option disabled value="">Select bank</option>
                    <option :value="bank.bank_code" v-for="bank in banks" :key="bank.bank_id">{{ bank.name }}</option>
                  </AppSelect>
                </ValidationProvider>
                <ValidationProvider name="account_number" rules="required" v-slot="{ errors }">
                  <AppInput
                    v-model="values.account_number" type="number" placeholder="Account number" :errors="errors"
                    class="w-full" :disabled="loading || validated()"
                  />
                </ValidationProvider>
              </div>

              <Message v-if="validated()" variant="success" class="mt-6">
                Account name: {{ values.account_name }}
              </Message>

              <div class="flex mt-10">
                <AppButton v-if="!validated()" @click="submit()" :loading="loading">Validate account</AppButton>
                <template v-else>
                  <AppButton @click="next()">Continue</AppButton>
                  <button @click="change()" class="py-1 px-4 ml-2">Change</button>
                </template>
              </div>
            </ValidationObserver>
          </form>
        </template>
      </template>
      <Loader v-else text="Please wait.."/>
    </div>
    <div class="flex justify-end mt-auto md:hidden">
      <img src="@/assets/images/savings.svg" alt="" class="w-64"/>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppSelect from "@/components/global/Select.vue";
import AppInput from "@/components/global/Input.vue";
import AppButton from "@/components/global/Button.vue";
import Message from "@/components/global/Message.vue";
import Loader from "@/components/global/Loader.vue";
import { namesMatch } from "@/lib/utils.js";
import banks from "@/lib/banks.js";

export default {
  name: "BankAccount",
  components: { Loader, Message, AppButton, AppInput, AppSelect, SectionHeader },
  data() {
    return {
      values: {
        account_number: '',
        account_name: '',
        bank_id: '',
        bank_code: '',
      },
      loading: false,
      error: '',
      view: '',
      banks: banks,
    }
  },
  created() {
    const {
      last_offer,
      config: { analyze_bank_statement, show_bank_account, remember_last_offer },
    } = this.$store.state.eligibility;
    if (!analyze_bank_statement && !show_bank_account) return this.$emit('skip');
    const { oneMonth } = this.$store.getters['eligibility/lastApplied'];
    if ((oneMonth && +last_offer?.[0]?.amount) && remember_last_offer) return this.$emit('skip');
    this.init();
  },
  methods: {
    async init() {
      await this.getBanks();
      const { account: { account_number, bank_id, account_name, bank_code, okra_id } } = this.$store.state.eligibility;
      if (account_number && bank_id) {
        this.view = 'accounts';
        this.values = { account_number, bank_id, account_name, bank_code, okra_id };
      } else {
        this.view = 'add';
      }
    },
    async submit() {
      this.error = '';
      this.values.account_name = null;
      const valid = await this.$refs.observer.validate()
      if (!valid) return;
      this.loading = true;
      await this.validateAccount();
      if (!this.values.account_name) {
        this.loading = false;
        return;
      }
      this.$store.commit('eligibility/patch', { account: this.values });
      this.loading = false;
    },
    setError(error) {
      this.loading = false;
      this.error = error;
    },
    async validateAccount() {
      try {
        const payload = {
          bank_code: this.values.bank_code,
          account_number: this.values.account_number,
          account_bank: this.values.bank_code,
        };
        let url = 'https://mobile.creditclan.com/webapi/v1/account/resolve';
        if (['090267', '100004'].includes(payload.bank_code)) url = 'https://mobile.creditclan.com/api/v3/account/flutterwave';
        const { data } = await this.$http.post(url, payload);
        if (data.status !== "success") return this.setError('Could not validate account, Check your details and try again');
        const { account_name } = data.data;
        const { profile: { full_name }, config: { account_name_match } } = this.$store.state.eligibility;
        if (account_name_match && !namesMatch(full_name, account_name)) {
          return this.setError('Sorry, we cannot proceed with this account. The name found in this account is different from the name provided for this application');
        }
        this.values.bank_id = this.banks.find(bank => bank.bank_code === this.values.bank_code).id;
        this.values.account_name = account_name;
      } catch (error) {
        console.log({ error });
      }
    },
    change() {
      this.view = 'add'
      this.values = {
        account_number: '',
        account_name: '',
        bank_id: '',
        bank_code: '',
      };
    },
    next() {
      if (!this.values.account_name) return;
      this.$emit('done');
    },
    async getBanks() {
      if (this.banks) return;
      const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
      this.banks = res.data.data;
    },
    validated() {
      return !!this.values.account_name;
    },
  },
  computed: {
    bank() {
      return this.banks.find(bank => bank.id === this.values.bank_id) || {};
    },
  }
}
</script>

