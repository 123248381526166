<template>
  <Modal>
    <div class="flex justify-between items-center mb-8">
      <h5 class="font-semibold text-lg">How to get ticket no. and password</h5>
      <button
        @click="$emit('close')"
        class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 hover:bg-red-50"
      >
        <i class="fa fa-times"></i>
      </button>
    </div>
    <ZenithInstructions v-if="bank_code === '057'"/>
    <GtInstructions v-if="bank_code === '058'"/>
  </Modal>
</template>

<script>
import Modal from "@/components/global/Modal.vue";
import ZenithInstructions from "@/components/core/home/modals/ZenithInstructions.vue";
import GtInstructions from "@/components/core/home/modals/GtInstructions.vue";

export default {
  name: "Mbs2HelpModal",
  components: { GtInstructions, ZenithInstructions, Modal },
  data() {
    return {
      bank_code: this.$store.state.eligibility.account.bank_code
    }
  }
}
</script>
