<template>
  <div class="px-6 lg:px-12 pb-8">
    <SectionHeader
      title="Where do you work?"
      subtitle="Please fill in all fields correctly"
    />
    <form>
      <ValidationObserver ref="observer">
        <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
        <div class="flex flex-col space-y-4">
          <ValidationProvider name="occupation_id" rules="required" v-slot="{ errors }">
            <AppSelect
              :disabled="validating" label="Employment status" v-model="values.occupation_id" :errors="errors"
              class="w-full"
            >
              <option disabled selected>Select employment status</option>
              <option :value="status.value" v-for="status in occupations" :key="status.value">
                {{ status.text }}
              </option>
            </AppSelect>
          </ValidationProvider>
          <template v-if="values.occupation_id === '1'">
            <ValidationProvider name="Place of work" rules="required" v-slot="{ errors }">
              <AppInput
                :disabled="validating" label="Place of work" v-model="values.company_name" type="text" :errors="errors"
                class="w-full"
              />
            </ValidationProvider>
            <ValidationProvider name="work_sector" rules="required" v-slot="{ errors }">
              <AppSelect
                :disabled="validating" label="Work sector" v-model="values.work_sector" :errors="errors" class="w-full"
              >
                <option disabled selected>Select work sector</option>
                <option :value="sector.value" v-for="sector in sectors" :key="sector.value">
                  {{ sector.text }}
                </option>
              </AppSelect>
            </ValidationProvider>
            <ValidationProvider name="address" rules="required" v-slot="{ errors }">
              <AppTextArea :disabled="validating" label="Company address" v-model="values.address" :errors="errors"/>
            </ValidationProvider>
            <ValidationProvider name="work_state_id" rules="required" v-slot="{ errors }">
              <AppSelect :disabled="validating" label="Work state" v-model="values.work_state_id" :errors="errors">
                <option disabled value="">Select work state</option>
                <option v-if="!states.length" value="" disabled>Loading</option>
                <option v-for="state in states" :key="state.value" :value="state.value">
                  {{ state.text }}
                </option>
              </AppSelect>
            </ValidationProvider>
            <ValidationProvider v-if="!no_work_email" name="official_email" rules="required|email" v-slot="{ errors }">
              <AppInput
                :disabled="validating" label="Official email" type="email" v-model="values.official_email"
                :errors="errors"
              />
            </ValidationProvider>
            <AppCheckbox v-model="no_work_email" class="mt-4">I don't have access to my work email</AppCheckbox>
          </template>
          <template
            v-if="values.occupation_id === '2' || values.occupation_id === '4' || values.occupation_id === '5'"
          >
            <ValidationProvider name="Is registered" rules="required" v-slot="{ errors }">
              <AppSelect
                :disabled="validating" label="Is your business registered?" v-model="values.is_registered"
                :errors="errors" class="w-full"
              >
                <option disabled value="">Select one</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </AppSelect>
            </ValidationProvider>
            <ValidationProvider name="Business name" rules="required" v-slot="{ errors }">
              <AppInput
                :label="values.is_registered === '1' ? 'Registered business name' : 'Business name'"
                v-model="values.company_name" type="text" :errors="errors" class="w-full" :disabled="validating"
              />
            </ValidationProvider>
            <ValidationProvider
              v-if="values.is_registered === '1'" name="RC number" rules="required" v-slot="{ errors }"
            >
              <AppInput
                :disabled="validating" label="RC number" v-model="values.rc_number" type="number" :errors="errors"
                class="w-full"
              />
            </ValidationProvider>
            <ValidationProvider name="Business sector" rules="required" v-slot="{ errors }">
              <AppSelect
                :disabled="validating" label="Business sector" v-model="values.work_sector" :errors="errors"
                class="w-full"
              >
                <option disabled value="">Select business sector</option>
                <option :value="sector.value" v-for="sector in sectors" :key="sector.value">
                  {{ sector.text }}
                </option>
              </AppSelect>
            </ValidationProvider>
            <ValidationProvider name="address" rules="required" v-slot="{ errors }">
              <AppTextArea :disabled="validating" label="Business address" v-model="values.address" :errors="errors"/>
            </ValidationProvider>
            <ValidationProvider name="directors" rules="required" v-slot="{ errors }">
              <AppInput
                :disabled="validating" label="No. of directors" type="number" v-model="values.directors"
                :errors="errors"
              />
            </ValidationProvider>
          </template>
        </div>
        <div class="flex mt-10">
          <AppButton @click="submit()" :loading="validating">Next</AppButton>
        </div>
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppSelect from "@/components/global/Select.vue";
import AppInput from "@/components/global/Input.vue";
import AppTextArea from "@/components/global/TextArea.vue";
import AppButton from "@/components/global/Button.vue";
import states from "@/lib/states.js";
import domains from "@/lib/domains.js";
import { months, occupations, sectors, years } from "@/lib/options.js";
import Message from "@/components/global/Message.vue";
import AppCheckbox from "@/components/global/AppCheckbox.vue";
import axios from "axios";

export default {
  name: "WorkInformation",
  components: { AppCheckbox, Message, AppButton, AppTextArea, AppInput, AppSelect, SectionHeader },
  data() {
    return {
      values: {
        work_sector: '',
        occupation_id: '',
        address: '',
        start_month: '',
        start_year: '',
        company_name: '',
        official_email: '',
        directors: '',
        work_state_id: '',
        work_lga_id: '',
        rc_number: '',
        is_registered: '',
      },
      sectors,
      months,
      years,
      occupations,
      states,
      error: '',
      validating: false,
      no_work_email: false,
      modal: '',
    }
  },
  created() {
    const { config: { show_work_information }, work } = this.$store.state.eligibility;
    const { oneMonth } = this.$store.getters['eligibility/lastApplied'];
    if (!show_work_information || (oneMonth && work.occupation_id)) return this.$emit('skip');
    this.values = work || {};
    Object.keys(this.values).forEach(key => {
      if (this.values[key] === '0') {
        this.values[key] = null;
      }
    })
  },
  watch: {
    no_work_email(v) {
      if (v) this.values.official_email = '';
    }
  },
  methods: {
    async submit() {
      if (!(await this.$refs.observer.validate())) return;
      this.error = '';
      const { occupation_id, official_email } = this.values;
      if (occupation_id === '1') {
        if (official_email && domains.includes(official_email.split('@')[1])) {
          this.error = 'Please enter a valid work email';
          return;
        }
      }
      if (occupation_id.match(/[245]/gi) && this.values.is_registered === '1') {
        if (this.$store.state.eligibility.config.validate_rc_number) {
          this.validating = true;
          const valid = await this.validateRcNumber();
          this.validating = false;
          if (!valid) return;
        }
      }
      this.next();
    },
    async validateRcNumber() {
      try {
        const res = await axios.post('https://sellbackend.creditclan.com/merchantclan/public/index.php/api/rc/check', {
          business_name: this.values.company_name,
          merchant_name: this.$store.state.eligibility.profile.full_name,
          reg_no: this.values.rc_number
        });
        const { give_loan } = res.data.data;
        if (!give_loan) this.error = 'We found some discrepancies in the information provided, please check your details and try again';
        return give_loan;
      } catch (e) {
        this.error = e?.response?.data?.message || 'Please enter a valid business name & rc number and try again';
        console.log(e);
      }
    },
    next() {
      this.$store.commit('eligibility/patch', {
        work: {
          ...this.$store.state.eligibility.work, ...this.values
        }
      });
      this.$emit("done");
    }
  }
}
</script>
