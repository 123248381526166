import Vue from 'vue';
import Vuex from 'vuex';
import eligibility from "@/store/modules/eligibility.js";
import statement from "@/store/modules/statement.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    eligibility,
    statement
  },
});
