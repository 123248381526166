<template>
  <div v-if="overlay" class="my-auto w-full">
    <RandomQuestions @done="overlay = ''"/>
  </div>
  <div v-else class="h-full flex flex-col">
    <div v-if="loading" class="my-auto px-6 lg:px-12 pb-8">
      <Loader
        v-if="loading === 'checking'"
        :texts="['Please wait..', 'Analyzing your cashflow..', 'Almost done..']"
        :timeout="5000"
      />
      <Loader v-else :text="loading" class="my-auto"/>
    </div>
    <div v-else-if="error" class="my-auto px-6 lg:px-12 pb-8">
      <Error :text="error" :show-retry="!!retryCb" @retry="retry()"/>
      <div class="mt-7 text-center">
        <button type="button" @click="$emit('options')" class="text-gray-600 text-base">
          Try other methods
        </button>
      </div>
    </div>
    <template v-else>
      <div v-if="currentView === 'phone-mismatch'" class="px-6 lg:px-12 pb-8">
        <SectionHeader title="Couldn't fetch PIN from your bank"/>
        <div class="text-gray-700 max-w-lg mb-8">
          <i class="fa fa-times text-red-500 mr-3"></i>
          <span v-html="phoneMismatch.message "></span>. Enter correct phone number below to continue
        </div>
        <AppInput v-model="phoneMismatch.new" placeholder="Enter phone number"/>
        <div class="mt-10 flex flex-col">
          <AppButton @click="handlePhoneChange()" :disabled="phoneMismatch.new.length < 11">Continue</AppButton>
          <div class="mt-7">
            <button type="button" @click="$emit('options')" class="text-gray-600 text-base">
              <span class="text-blue-600">Try other</span> bank statement methods
            </button>
          </div>
        </div>
      </div>
      <form v-if="currentView === 'ticket'" class="px-6 lg:px-12 pb-8">
        <SectionHeader
          title="Enter bank ticket"
          subtitle="Kindly provide the ticket number sent to you from your bank"
        />
        <Message v-if="ticketError" variant="error" class="mb-6">{{ ticketError }}</Message>
        <ValidationObserver ref="observer">
          <div class="flex flex-col space-y-6">
            <div>
              <label class="flex mb-2">Ticket number</label>
              <div class="flex items-center w-full">
                <ValidationProvider name="ticketNoLeft" rules="required|max:7" v-slot="{ errors }" class="flex-1">
                  <AppInput
                    type="number" @input="handleTicketLeftChange()"
                    placeholder="XXXXXXX" v-model="values.ticketNoLeft" :errors="errors"
                  />
                </ValidationProvider>
                <div class="mx-4">-</div>
                <ValidationProvider name="ticketNoRight" rules="required|max:2" v-slot="{ errors }" class="flex-1">
                  <AppInput
                    type="number" @input="handleTicketRightChange()"
                    placeholder="XX" v-model="values.ticketNoRight" :errors="errors"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="grid grid-cols-2">
              <div>
                <label class="flex mb-2">Ticket password</label>
                <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                  <AppInput
                    type="number" @input="handleTicketPasswordChange()"
                    placeholder="XXXX" v-model="values.password" :errors="errors"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="mt-2" v-if="isZenithOrGt">
              Can't get ticket?
              <button type="button" @click="modal = 'help'" class="text-blue-600 text-base">
                show me how
              </button>
            </div>
          </div>
        </ValidationObserver>
        <div class="flex flex-col items-start mt-12">
          <AppButton @click="verifyTicket()">Submit</AppButton>
          <div class="mt-7">
            <button type="button" @click="$emit('options')" class="text-gray-600 text-base">
              <span class="text-blue-600">Try other</span> bank statement methods
            </button>
          </div>
        </div>
        <p class="mt-10 relative text-sm text-yellow-400"><i class="fa fa-bolt mr-2"></i>
          Powered by MBS
        </p>
      </form>
      <div class="flex justify-end mt-auto md:hidden">
        <img src="@/assets/images/savings.svg" alt="" class="w-60"/>
      </div>
    </template>
    <Mbs2HelpModal v-if="modal === 'help'" @close="modal = ''"/>
  </div>
</template>

<script>
import banks from '@/lib/mbs-banks.js';
import AppInput from "@/components/global/Input.vue";
import AppButton from "@/components/global/Button.vue";
import SectionHeader from "@/components/global/SectionHeader.vue";
import Loader from "@/components/global/Loader.vue";
import ajax from "@/mixins/ajax.js";
import Error from "@/components/global/Error.vue";
import view from '@/mixins/view.js';
import { delay, formatCurrency } from "@/lib/utils.js";
import RandomQuestions from "@/components/core/home/sections/RandomQuestions.vue";
import Message from "@/components/global/Message.vue";
import Mbs2HelpModal from "@/components/core/home/modals/Mbs2HelpModal.vue";
import { subDays, subMonths } from "date-fns";

export default {
  name: "BSMbs",
  components: { Mbs2HelpModal, Message, RandomQuestions, Error, Loader, SectionHeader, AppButton, AppInput },
  mixins: [ajax, view],
  data() {
    return {
      data: null,
      setting: null,
      transaction_id: null,
      values: {
        ticketNoLeft: '',
        ticketNoRight: '',
        password: ''
      },
      phoneMismatch: {
        current: false,
        message: '',
        new: ''
      },
      currentView: '',
      formatCurrency,
      timeout: null,
      ticketError: '',
      modal: '',
      response: null
    }
  },
  created() {
    this.overlay = 'random-questions';
    this.start();
    this.$mixpanel.track('Bs switched to mbs', {
      platform: this.$store.state.eligibility.config.platform,
      bank_code: this.$store.state.eligibility.account.bank_code,
      account_number: this.$store.state.eligibility.account.account_number,
      bank_name: this.$store.getters['eligibility/bankName'],
    });
  },
  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },
  methods: {
    handlePhoneChange: function () {
      this.phoneMismatch.current = false;
      this.phoneMismatch.message = '';
      this.start();
    },
    async start() {
      if (!this.settings) await this.getSettings();
      const {
        account: { bank_code, account_number, account_name },
        profile: { phone }, config: { platform }
      } = this.$store.state.eligibility;
      const bankId = banks.find(bank => bank.sortCode === bank_code).id;
      const data = {
        destinationId: this.settings.clientid,
        accountNo: account_number,
        bankId,
        country: 'NG',
        startDate: this.formatDate(subMonths(new Date(), platform === 'ride' ? 2 : 6)),
        endDate: this.formatDate(subDays(new Date(), 1)),
        phone: this.phoneMismatch.new || phone,
        role: 'Applicant',
        username: 'soji.okunuga@creditclan.com',
        applicant: { name: account_name }
      };
      await this.initializeMBS(data);
    },
    async initializeMBS(payload) {
      try {
        this.setLoading('checking');
        const { data } = await this.$http.post('https://app.statementanalysis.co/mbs/initialize', payload);
        this.transaction_id = data.transaction_id;
        this.$mixpanel.track('Bs mbs initialized', {
          platform: this.$store.state.eligibility.config.platform,
          bank_code: this.$store.state.eligibility.account.bank_code,
          account_number: this.$store.state.eligibility.account.account_number,
          bank_name: this.$store.getters['eligibility/bankName'],
          transaction_id: this.transaction_id
        });
        await this.checkStatus();
      } catch (e) {
        const message = e?.response?.data?.message || e.message || 'An error occurred, please try again!';
        this.$mixpanel.track('Bs mbs initialization failed', {
          platform: this.$store.state.eligibility.config.platform,
          bank_code: this.$store.state.eligibility.account.bank_code,
          account_number: this.$store.state.eligibility.account.account_number,
          bank_name: this.$store.getters['eligibility/bankName'],
          error: message,
        });
        this.setLoading('');
        this.$emit('failed');
      }
    },
    async checkStatus(n = 1) {
      this.setError('');
      if (n === 20) {
        this.setError('Request timed out, please try again', true);
        this.setRetry(() => this.start());
        return;
      }
      try {
        this.setLoading(n <= 5 ? 'Getting pin from bank..' : 'Hmm, taking longer than usual..');
        const { data } = await this.$http.get(`https://app.statementanalysis.co/mbs/status/${ this.transaction_id }`);
        if (data.status.match(/invalid/gi)) {
          if (data.feedback.includes('mismatched')) {
            this.phoneMismatch = { current: true, message: data.feedback, new: '' };
            this.currentView = 'phone-mismatch';
          } else {
            this.setError(data.feedback, true);
          }
        } else if (data.status.match(/error/gi)) {
          this.setError(data.feedback, true);
        } else if (data.status === 'Pending' || data.status === 'Confirm') {
          await delay(5000);
          return this.checkStatus(++n);
        } else if (data.status === 'Ticket') {
          this.setLoading('', true);
          this.currentView = 'ticket';
        } else if (data.status === 'Insfund') {
          this.setError(data.feedback, true);
        } else if (data.status === 'Sent') {
          this.$mixpanel.track('Bs mbs successful', {
            platform: this.$store.state.eligibility.config.platform,
            bank_code: this.$store.state.eligibility.account.bank_code,
            account_number: this.$store.state.eligibility.account.account_number,
            bank_name: this.$store.getters['eligibility/bankName'],
          });
          this.$emit('done', this.response);
        } else {
          this.setError(data.feedback, true);
          this.$mixpanel.track('Bs mbs status check failed', {
            platform: this.$store.state.eligibility.config.platform,
            bank_code: this.$store.state.eligibility.account.bank_code,
            account_number: this.$store.state.eligibility.account.account_number,
            bank_name: this.$store.getters['eligibility/bankName'],
            error: data.feedback,
          });
        }
      } catch (e) {
        const message = e?.response?.data?.message || e.message || 'An error occurred, please try again!';
        this.$mixpanel.track('Bs mbs status check failed', {
          platform: this.$store.state.eligibility.config.platform,
          bank_code: this.$store.state.eligibility.account.bank_code,
          account_number: this.$store.state.eligibility.account.account_number,
          bank_name: this.$store.getters['eligibility/bankName'],
          error: message,
        });
        this.setError(message);
        this.$emit('failed');
      }
      if (this.error) this.setRetry(() => this.checkStatus());
      this.setLoading('');
    },
    async verifyTicket() {
      if (!await this.$refs.observer.validate()) return;
      this.loading = 'Verifying..';
      try {
        const { ticketNoLeft, ticketNoRight, password } = this.values;
        const payload = {
          ticketNo: `${ ticketNoLeft }-${ ticketNoRight }`,
          password,
        };
        let url = `https://app.statementanalysis.co/mbs/confirm/${ this.transaction_id }`;
        const { data } = await this.$http.post(url, payload);
        if (Object.hasOwn(data, 'completed') && !data.completed) {
          return this.setError(data?.message?.message || 'An error occurred, please try again', true);
        }
        if (Object.hasOwn(data, 'status') && !data.status) {
          return this.setError(data?.message?.message || 'An error occurred, please try again', true);
        }
        this.currentView = 'success';
        let response = {
          transaction_id: data.transaction_id,
          statement_source: 5,
          ticketNo: `${ ticketNoLeft }-${ ticketNoRight }`,
          password,
        }
        if (!response.transaction_id) response = {
          transaction_id: this.transaction_id,
          statement_source: 3,
          ticketNo: `${ ticketNoLeft }-${ ticketNoRight }`,
          password,
        }
        this.response = response;
        await this.checkStatus();
      } catch (e) {
        const message = e?.response?.data?.message?.message || e?.response?.data?.message || e?.response?.data?.error || e.message || 'An error occurred, please try again!';
        this.ticketError = message;
        this.$mixpanel.track('Bs mbs verify ticket failed', {
          platform: this.$store.state.eligibility.config.platform,
          bank_code: this.$store.state.eligibility.account.bank_code,
          account_number: this.$store.state.eligibility.account.account_number,
          bank_name: this.$store.getters['eligibility/bankName'],
          error: message,
        });
        this.$emit('failed');
      }
      this.setLoading('')
    },
    async getSettings() {
      this.setLoading('Please wait..', true);
      try {
        const { data } = await this.$http.get(`https://mobile.creditclan.com/api/v3/mbs/lender/settings`,);
        this.settings = data;
      } catch (e) {
        this.setError(e.message || 'An error occurred, please try again');
        this.setRetry(() => this.getSettings());
      }
      this.setLoading('')
    },
    formatDate(date, minus = 0) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const month = months[date.getMonth() - minus < 0 ? 12 + (date.getMonth() - minus) : date.getMonth() - minus];
      const day = date.getDate();
      const year = date.getMonth() - minus < 0 ? date.getFullYear() - 1 : date.getFullYear();
      return `${ day }-${ month }-${ year }`;
    },
    handleTicketLeftChange() {
      if (this.values.ticketNoLeft.length > 7) {
        this.values.ticketNoLeft = this.values.ticketNoLeft.substring(0, 7);
      }
    },
    handleTicketRightChange() {
      if (this.values.ticketNoRight.length > 2) {
        this.values.ticketNoRight = this.values.ticketNoRight.substring(0, 2);
      }
    },
    handleTicketPasswordChange() {
      if (this.values.password.length > 4) {
        this.values.password = this.values.password.substring(0, 4);
      }
    },
  },
  computed: {
    isZenithOrGt() {
      return this.$store.state.eligibility.account.bank_code.match(/057|058/gi);
    }
  }
}
</script>
