<template>
  <Layout :banner="banner">
    <Preload v-if="intro" @done="intro = false"/>
    <template v-else>
      <div v-if="loading" class="px-6 pt-6 lg:pt-10 lg:px-12 my-auto">
        <Loader
          v-if="loading === 'init'" variant="skateboard"
          :texts="['Just a moment..', 'Setting things up..']" :timeout="2000"
        />
        <Loader v-else variant="skateboard" :text="loading"/>
      </div>
      <div v-else-if="error" class="px-6 pt-6 lg:pt-10 lg:px-12 my-auto">
        <Error :text="error"/>
      </div>
      <Analysis v-else-if="$store.state.eligibility.profile.user_id"/>
    </template>
  </Layout>
</template>

<script>
import Analysis from "@/components/core/home/Analysis.vue";
import ajax from "@/mixins/ajax";
import Error from "@/components/global/Error";
import Preload from "@/components/core/home/sections/Preload";
import { decodeToken } from "@/lib/utils";
import Loader from "@/components/global/Loader.vue";
import Layout from "@/components/global/Layout.vue";

export default {
  components: { Layout, Loader, Preload, Error, Analysis },
  mixins: [ajax],
  data() {
    return {
      loading: '',
      banner: '/assets/images/banner.jpg',
      data: null,
      intro: false,
    }
  },
  created() {
    this.setup();
  },
  mounted() {
    parent.postMessage({ id: 'cclan-frame-loaded' }, '*');
  },
  methods: {
    setup() {
      const { token } = this.$route.query;
      if (!token) return this.setError('Something went wrong');
      const decoded = decodeToken(token);
      const {
        request,
        profile,
        request_id,
        home_address = { address: '', state: '', lga: '' },
        identity = { nin: '' },
        referral_code = null,
        config: {
          tokenize_card = false,
          analyze_bank_statement = false,
          no_frequently_called_numbers = 0,
          show_offers = false,
          show_questions = false,
          show_bank_account = false,
          match_account_with_bvn = false,
          show_work_information = false,
          show_signature = false,
          scan_whatsapp = false,
          platform = 'shop',
          show_nin = false,
          bs_name_match = false,
          account_name_match = false,
          bvn_name_match = false,
          recommended_upfront_cap = false,
          always_show_offers = false,
          show_address = false,
          show_profile = false,
          first_month_upfront = false,
          single_offer = false,
          can_change_repayment_frequency = false,
          show_income = false,
          show_nok = false,
          modify_offer_beyond_limits = false,
          can_modify_offer = false,
          verify_email = false,
          verify_work_email = false,
          show_picture = true,
          return_offer = false,
          show_multiple_accounts = false,
          show_attachments = false,
          show_basic_details = false,
          validate_rc_number = false,
          specify_account_type = false,
          validate_bvn = false,
          show_intro = true,
          remember_last_application_date = true,
          remember_last_offer = true,
          attachments_list = null
        } = {},
        intro = '',
        intro_action = '',
        extra = {},
        banner,
        lender,
        email_verified = false,
        work = {},
        source = '',
        stage = null,
        plans,
        api_key = null,
      } = decoded;
      if (banner) this.banner = banner;
      this.$store.commit('eligibility/patch', {
        api_key,
        intro,
        intro_action,
        referral_code,
        identity,
        home_address,
        request_id,
        extra,
        lender,
        email_verified,
        work,
        source,
        stage,
        plans,
        request: { ...request, tenor_type: request?.tenor_type || 2 },
        profile: { ...profile, email: profile.email?.replace(/ /gi, '') },
        config: {
          tokenize_card,
          analyze_bank_statement,
          no_frequently_called_numbers,
          show_offers,
          show_questions,
          show_bank_account,
          match_account_with_bvn,
          show_work_information,
          show_signature,
          verify_email,
          platform,
          scan_whatsapp,
          show_nin,
          bs_name_match,
          account_name_match,
          bvn_name_match,
          recommended_upfront_cap,
          always_show_offers,
          show_address,
          show_profile,
          first_month_upfront,
          single_offer,
          can_change_repayment_frequency,
          show_income,
          show_nok,
          modify_offer_beyond_limits,
          can_modify_offer,
          verify_work_email,
          show_picture,
          return_offer,
          show_multiple_accounts,
          show_attachments,
          show_basic_details,
          validate_bvn,
          validate_rc_number,
          specify_account_type,
          show_intro,
          remember_last_application_date,
          remember_last_offer,
          attachments_list
        }
      });
      this.data = {
        profile: {
          ...profile, email: profile.email?.replace(/ /gi, ''),
          ...home_address, email_verified
        },
        request: {
          ...request, tenor_type: request?.tenor_type || 2
        }
      };
      this.$http.interceptors.request.use(config => {
        config.headers['x-api-key'] = api_key || process.env.VUE_APP_API_KEY;
        return config;
      }, error => {
        return Promise.reject(error);
      });
      this.init();
      this.intro = show_intro;
    },
    async init() {
      try {
        this.setLoading('init', true);
        let data = {};
        data = await this.checkUser(this.data.profile.email, this.data.profile.phone);
        if (!data.user_id && !this.error) data = await this.createUser(this.data.profile);
        if (data.user_id && data.token) {
          if (this.$mixpanel.get_distinct_id() !== data.user_id) {
            this.$mixpanel.identify(data.user_id);
            this.$mixpanel.people.set({
              name: this.data.profile.full_name, email: this.data.profile.email, $email: this.data.profile.email,
            });
          }
          this.$store.commit('eligibility/patch', {
            token: data.token, profile: { user_id: data.user_id, ...this.data.profile }
          });
          await this.$store.dispatch('eligibility/getUserDetails');
          this.$store.commit('eligibility/patch', { request: this.data.request });
          if (!this.$store.state.eligibility.has_data) this.$store.dispatch('eligibility/postData', { type: 'phone' });
          if (this.$store.state.eligibility.config.show_questions) await this.getRandomQuestions();
          await this.$store.dispatch('eligibility/getCreditclanRequestId');
          if (this.$store.state.eligibility.request_id) {
            await this.$store.dispatch('eligibility/getRequestDetails');
            this.$mixpanel.track('Continuing eligibility', {
              platform: this.$store.state.eligibility.config.platform,
              request_id: this.$store.state.eligibility.request_id
            });
          } else {
            this.$mixpanel.track('Started eligibility', {
              platform: this.$store.state.eligibility.config.platform
            });
          }
        }
        this.setLoading('');
      } catch (e) {
        if (e.response.status === 404) return this.setError('Sorry this request does not exist or has been cancelled', true);
        this.setError('Failed to get details, please refresh the page to try again!', true);
      }
    },
    async getRandomQuestions() {
      const { token, request_id } = this.$store.state.eligibility;
      const res = await this.$http.post('/customer/getrandomquestions', {
        token, request_id
      });
      this.$store.commit('eligibility/patch', { questions: res.data.data.questions });
    },
    async checkUser(email, phone) {
      try {
        const { data } = await this.$http.post(`/customer/check/details`, { email, phone });
        const { token, user_id } = data;
        return { token, user_id };
      } catch (e) {
        this.setError('An error occurred, please refresh to try again!');
        return false;
      }
    },
    async createUser(data) {
      const { email_verified = false, ...rest } = data;
      try {
        const payload = { profile: { ...rest, password: 'temp', is_individual: 1 } };
        if (email_verified) payload.email_verified = true;
        const res = await this.$http.post('/customer/register/market_user', payload);
        this.$store.commit('eligibility/patch', { new_customer: true });
        const { token, user_id } = res.data;
        return { token, user_id };
      } catch (e) {
        this.setError('An error occurred, please refresh to try again!');
        return false;
      }
    },
  }
}
</script>
