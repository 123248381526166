<template>
  <div class="px-6 lg:px-12 pb-8 my-auto">
    <Loader v-if="loading !== ''" :text="loading" class="my-auto"/>
    <Error v-else-if="error" text="Whatsapp number not correct" show-retry @retry="getCode"/>
    <div v-else>
      <template>
        <SectionHeader
          title="Confirm your whatsapp"
          :subtitle="`Scan the QR Code below with the whatsapp connected to ${$store.state.eligibility.profile.phone} to confirm your ownership of the device`"
        />
        <div>
          <div class="inline-block border rounded-lg overflow-hidden">
            <img :src="image" alt="qr code"/>
          </div>
        </div>
        <button
          @click="modals.how = true"
          class="mt-6 border-b-2 border-blue-400 text-blue-400 hover:text-blue-600 hover:border-blue-600"
        >
          Show me how
        </button>
      </template>

      <Modal v-if="modals.how">
        <div class="flex items-center justify-between mb-10">
          <h5 class="text-2xl">How to scan with your Whatsapp</h5>
          <div>
            <button
              @click="modals.how = false"
              class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 hover:bg-red-50"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        <img src="@/assets/images/scan-qr-code.gif" class="w-48 rounded-md" alt=""/>
        <div class="mt-10">
          <AppButton @click="modals.how = false" size="lg">Got it</AppButton>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/global/Button.vue";
import ajax from "@/mixins/ajax.js";
import Loader from "@/components/global/Loader.vue";
import SectionHeader from "@/components/global/SectionHeader.vue";
import Error from "@/components/global/Error.vue";
import Modal from "@/components/global/Modal.vue";
import axios from "axios";
import { delay } from "@/lib/utils.js";

export default {
  name: "ConnectWhatsapp",
  components: { Modal, Error, SectionHeader, Loader, AppButton },
  data() {
    return {
      image: '',
      sessionId: null,
      modals: {
        how: false
      }
    }
  },
  mixins: [ajax],
  created() {
    const { whatsapp_id, config: { scan_whatsapp } } = this.$store.state.eligibility;
    if (!scan_whatsapp) return this.$emit('skip');
    if (whatsapp_id) this.verifyConnection(whatsapp_id);
    else this.getCode();
  },
  methods: {
    async verifyConnection(whatsapp_id) {
      this.setLoading('Checking Whatsapp connection');
      try {
        const res = await this.$http.get(`https://lendnode.creditclan.com/whatsapp/sessions/${ whatsapp_id }/status`);
        if (res.data.status !== 'AUTHENTICATED') await this.getCode();
        else this.$emit('done');
      } catch (e) {
        console.log(e);
        await this.getCode();
      }
      this.setLoading('');
    },
    async getCode() {
      this.setLoading('Generating qr code');
      try {
        let date = new Date();
        const sessionId = date.getTime().toString() + Math.random().toString(36).slice(0, 8);
        const res = await axios.post('https://lendnode.creditclan.com/whatsapp/sessions/add', {
          sessionId,
          phone: this.$store.state.eligibility.profile.phone,
          syncFullHistory: true,
        });
        this.image = res.data.qr;
        this.sessionId = sessionId;
        await delay(5000);
        this.checkStatus();
      } catch (e) {
        const message = e.response ? e.response.data.message : e.message || 'An error occurred, please try again';
        this.setError(message);
      }
      this.setLoading('');
    },
    async checkStatus(retries) {
      try {
        if (retries === 30) return;
        const res = await this.$http.get(`https://lendnode.creditclan.com/whatsapp/sessions/${ this.sessionId }/status`);
        if (res.data.status !== 'AUTHENTICATED') {
          await delay(5000);
          return await this.checkStatus(--retries);
        }
        await this.save();
      } catch (e) {
        await this.getCode();
      }
    },
    async save() {
      try {
        this.setLoading('Saving..');
        const { token } = this.$store.state.eligibility;
        await this.$http.post('https://mobile.creditclan.com/api/v3/whatsapp/scanner', {
          token, whatsapp_id: this.sessionId,
        });
        this.$emit('done');
      } catch (e) {
        const message = e.response ? e.response.data.message : e.message || 'An error occurred, please try again';
        this.setError(message);
      }
      this.setLoading('');
    }
  },
}
</script>
