var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full flex flex-col"},[(_vm.loading)?_c('Loader',{staticClass:"my-auto px-6 lg:px-12 pb-8",attrs:{"text":_vm.loading}}):_c('div',{staticClass:"px-6 lg:px-12 pb-8"},[_c('SectionHeader',{attrs:{"title":"Provide your Bank Verification Number (BVN)"}}),(_vm.error)?_c('Message',{staticClass:"mb-6",attrs:{"variant":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"flex flex-col space-y-4"},[_c('ValidationProvider',{attrs:{"name":"bvn","rules":"required|length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{staticClass:"w-full",attrs:{"label":"BVN number","type":"number","placeholder":"BVN","errors":errors,"disabled":!!_vm.loading},model:{value:(_vm.values.bvn),callback:function ($$v) {_vm.$set(_vm.values, "bvn", $$v)},expression:"values.bvn"}}),_c('small',{staticClass:"text-gray-500 mt-2"},[_vm._v("Dial *565*0# to get your BVN number")])]}}])}),_c('div',[_c('label',{staticClass:"flex opacity-80 mb-3"},[_vm._v("Date of birth")]),_c('div',{staticClass:"grid grid-cols-3 gap-4"},[_c('ValidationProvider',{attrs:{"name":"Day","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{staticClass:"w-full",attrs:{"type":"number","errors":errors,"disabled":!!_vm.loading,"label":"Day"},model:{value:(_vm.values.date),callback:function ($$v) {_vm.$set(_vm.values, "date", $$v)},expression:"values.date"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{staticClass:"w-full",attrs:{"type":"text","errors":errors,"disabled":!!_vm.loading,"label":"Month"},model:{value:(_vm.values.month),callback:function ($$v) {_vm.$set(_vm.values, "month", $$v)},expression:"values.month"}},_vm._l((['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dev']),function(m,i){return _c('option',{key:m,domProps:{"value":("" + i)}},[_vm._v(" "+_vm._s(m)+" ")])}),0)]}}])}),_c('ValidationProvider',{attrs:{"name":"Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{staticClass:"w-full",attrs:{"type":"number","errors":errors,"disabled":!!_vm.loading,"label":"Year"},model:{value:(_vm.values.year),callback:function ($$v) {_vm.$set(_vm.values, "year", $$v)},expression:"values.year"}})]}}])})],1)])],1)]),_c('div',{staticClass:"text-sm text-gray-500 mt-3"},[_vm._v("Must match date of birth registered with BVN")]),_c('AppButton',{staticClass:"mt-10",attrs:{"loading":!!_vm.loading},on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v("Continue")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }