var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-6 lg:px-12 pb-8"},[_c('SectionHeader',{attrs:{"title":"Where do you work?","subtitle":"Please fill in all fields correctly"}}),_c('form',[_c('ValidationObserver',{ref:"observer"},[(_vm.error)?_c('Message',{staticClass:"mb-6",attrs:{"variant":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('div',{staticClass:"flex flex-col space-y-4"},[_c('ValidationProvider',{attrs:{"name":"occupation_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{staticClass:"w-full",attrs:{"disabled":_vm.validating,"label":"Employment status","errors":errors},model:{value:(_vm.values.occupation_id),callback:function ($$v) {_vm.$set(_vm.values, "occupation_id", $$v)},expression:"values.occupation_id"}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v("Select employment status")]),_vm._l((_vm.occupations),function(status){return _c('option',{key:status.value,domProps:{"value":status.value}},[_vm._v(" "+_vm._s(status.text)+" ")])})],2)]}}])}),(_vm.values.occupation_id === '1')?[_c('ValidationProvider',{attrs:{"name":"Place of work","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{staticClass:"w-full",attrs:{"disabled":_vm.validating,"label":"Place of work","type":"text","errors":errors},model:{value:(_vm.values.company_name),callback:function ($$v) {_vm.$set(_vm.values, "company_name", $$v)},expression:"values.company_name"}})]}}],null,false,159435540)}),_c('ValidationProvider',{attrs:{"name":"work_sector","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{staticClass:"w-full",attrs:{"disabled":_vm.validating,"label":"Work sector","errors":errors},model:{value:(_vm.values.work_sector),callback:function ($$v) {_vm.$set(_vm.values, "work_sector", $$v)},expression:"values.work_sector"}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v("Select work sector")]),_vm._l((_vm.sectors),function(sector){return _c('option',{key:sector.value,domProps:{"value":sector.value}},[_vm._v(" "+_vm._s(sector.text)+" ")])})],2)]}}],null,false,1055245931)}),_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppTextArea',{attrs:{"disabled":_vm.validating,"label":"Company address","errors":errors},model:{value:(_vm.values.address),callback:function ($$v) {_vm.$set(_vm.values, "address", $$v)},expression:"values.address"}})]}}],null,false,186949241)}),_c('ValidationProvider',{attrs:{"name":"work_state_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{attrs:{"disabled":_vm.validating,"label":"Work state","errors":errors},model:{value:(_vm.values.work_state_id),callback:function ($$v) {_vm.$set(_vm.values, "work_state_id", $$v)},expression:"values.work_state_id"}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Select work state")]),(!_vm.states.length)?_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Loading")]):_vm._e(),_vm._l((_vm.states),function(state){return _c('option',{key:state.value,domProps:{"value":state.value}},[_vm._v(" "+_vm._s(state.text)+" ")])})],2)]}}],null,false,3440560813)}),(!_vm.no_work_email)?_c('ValidationProvider',{attrs:{"name":"official_email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{attrs:{"disabled":_vm.validating,"label":"Official email","type":"email","errors":errors},model:{value:(_vm.values.official_email),callback:function ($$v) {_vm.$set(_vm.values, "official_email", $$v)},expression:"values.official_email"}})]}}],null,false,3469746207)}):_vm._e(),_c('AppCheckbox',{staticClass:"mt-4",model:{value:(_vm.no_work_email),callback:function ($$v) {_vm.no_work_email=$$v},expression:"no_work_email"}},[_vm._v("I don't have access to my work email")])]:_vm._e(),(_vm.values.occupation_id === '2' || _vm.values.occupation_id === '4' || _vm.values.occupation_id === '5')?[_c('ValidationProvider',{attrs:{"name":"Is registered","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{staticClass:"w-full",attrs:{"disabled":_vm.validating,"label":"Is your business registered?","errors":errors},model:{value:(_vm.values.is_registered),callback:function ($$v) {_vm.$set(_vm.values, "is_registered", $$v)},expression:"values.is_registered"}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Select one")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Yes")]),_c('option',{attrs:{"value":"0"}},[_vm._v("No")])])]}}],null,false,2895330102)}),_c('ValidationProvider',{attrs:{"name":"Business name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{staticClass:"w-full",attrs:{"label":_vm.values.is_registered === '1' ? 'Registered business name' : 'Business name',"type":"text","errors":errors,"disabled":_vm.validating},model:{value:(_vm.values.company_name),callback:function ($$v) {_vm.$set(_vm.values, "company_name", $$v)},expression:"values.company_name"}})]}}],null,false,2592967261)}),(_vm.values.is_registered === '1')?_c('ValidationProvider',{attrs:{"name":"RC number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{staticClass:"w-full",attrs:{"disabled":_vm.validating,"label":"RC number","type":"number","errors":errors},model:{value:(_vm.values.rc_number),callback:function ($$v) {_vm.$set(_vm.values, "rc_number", $$v)},expression:"values.rc_number"}})]}}],null,false,3014381369)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Business sector","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{staticClass:"w-full",attrs:{"disabled":_vm.validating,"label":"Business sector","errors":errors},model:{value:(_vm.values.work_sector),callback:function ($$v) {_vm.$set(_vm.values, "work_sector", $$v)},expression:"values.work_sector"}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Select business sector")]),_vm._l((_vm.sectors),function(sector){return _c('option',{key:sector.value,domProps:{"value":sector.value}},[_vm._v(" "+_vm._s(sector.text)+" ")])})],2)]}}],null,false,2015364681)}),_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppTextArea',{attrs:{"disabled":_vm.validating,"label":"Business address","errors":errors},model:{value:(_vm.values.address),callback:function ($$v) {_vm.$set(_vm.values, "address", $$v)},expression:"values.address"}})]}}],null,false,2386978296)}),_c('ValidationProvider',{attrs:{"name":"directors","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{attrs:{"disabled":_vm.validating,"label":"No. of directors","type":"number","errors":errors},model:{value:(_vm.values.directors),callback:function ($$v) {_vm.$set(_vm.values, "directors", $$v)},expression:"values.directors"}})]}}],null,false,4268191273)})]:_vm._e()],2),_c('div',{staticClass:"flex mt-10"},[_c('AppButton',{attrs:{"loading":_vm.validating},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Next")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }